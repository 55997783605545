import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
} from '@material-ui/core';
import classes from './Invoice.module.scss';

const EditInvoiceConfirmationModal = ({
  DualFeeEnabled,
  open,
  handleClose,
  handleSubmit,
  handleSubmitTextInvoice,
  handleSubmitEmailInvoice,
  isMobile,
  isPaymentButtonDisabled,
}) => {
  return (
    <Dialog
      open={open}
      maxWidth={'md'}
      keepMounted
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{'Update Invoice!'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Your invoice has been updated do you want to perform any other action on this invoice?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className={classes.updatemodal}>
          <Grid
            item
            xs={12}
            s={12}
            m={12}
            style={{
              display: isMobile ? 'none' : '',
            }}
          >
            <Button
              className={classes.updatemodal}
              style={{ backgroundColor: '#e27329' }}
              variant="contained"
              startIcon={<Icon>send</Icon>}
              onClick={(e) => handleSubmitEmailInvoice(e)}
            >
              Email Invoice
            </Button>
            <Button
              className={classes.updatemodal}
              variant="contained"
              style={{ backgroundColor: '#03af38' }}
              startIcon={<Icon>message</Icon>}
              onClick={(e) => handleSubmitTextInvoice(e)}
            >
              Text Invoice
            </Button>
            <Button
              className={classes.updatemodal}
              variant="contained"
              style={{ backgroundColor: '#FF0000' }}
              startIcon={<Icon>payment</Icon>}
              onClick={(e) => handleSubmit(e)}
              disabled={isPaymentButtonDisabled}
            >
              Pay Invoice
            </Button>
            {DualFeeEnabled ? (
              <Button
                variant="contained"
                style={{ backgroundColor: '#d5d5d5' }}
                className={classes.updatemodal}
                onClick={(e) => handleSubmit(e, true)}
                text="Cash Payment"
              >
                Cash Payment
              </Button>
            ) : (
              ''
            )}

            <Button
              className={classes.updatemodal}
              variant="contained"
              style={{ backgroundColor: '#0f7bc1' }}
              startIcon={<Icon>check</Icon>}
              onClick={() => handleClose()}
            >
              Continue
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            s={12}
            m={12}
            style={{
              display: isMobile ? '' : 'none',
            }}
          >
            <Button
              className={classes.payment_btn}
              variant="contained"
              startIcon={<Icon>payment</Icon>}
              onClick={(e) => handleSubmit(e)}
              disabled={isPaymentButtonDisabled}
            >
              Pay Invoice
            </Button>
            {DualFeeEnabled ? (
              <Button
                variant="contained"
                className={classes.payment_btn}
                onClick={(e) => handleSubmit(e, true)}
                text="Cash Payment"
              >
                Cash Payment
              </Button>
            ) : (
              ''
            )}
            <Button
              className={classes.email_btn}
              variant="contained"
              startIcon={<Icon>send</Icon>}
              onClick={(e) => handleSubmitEmailInvoice(e)}
            >
              Email Invoice
            </Button>
            <Button
              className={classes.email_btn}
              variant="contained"
              startIcon={<Icon>message</Icon>}
              onClick={(e) => handleSubmitTextInvoice(e)}
            >
              Text Invoice
            </Button>
            <Button
              className={classes.continue}
              variant="contained"
              startIcon={<Icon>check</Icon>}
              onClick={() => handleClose()}
            >
              Continue
            </Button>
          </Grid>
        </div>
      </DialogActions>
    </Dialog>
  );
};

EditInvoiceConfirmationModal.propTypes = {
  DualFeeEnabled: PropTypes.bool,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleSubmitEmailInvoice: PropTypes.func,
  handleSubmitTextInvoice: PropTypes.func,
  isMobile: PropTypes.bool,
  isPaymentButtonDisabled: PropTypes.bool,
};

export default EditInvoiceConfirmationModal;
