import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const Filter = ({ onChange, placeholder }) => (
  <div className="data-table-extensions-filter">
    <label htmlFor="filterDataTable" className="icon" />
    <input
      type="text"
      name="filterDataTable"
      className="filter-text"
      placeholder={placeholder}
      onChange={(e) => onChange(e)}
    />
  </div>
);

Filter.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
};

Filter.defaultProps = {
  onChange: null,
};

export const Export = (props) => {
  const { className, onDropdown, onClick } = props;
  return (
    <>
      <button type="button" name="export-data" className={`download ${className}`} onClick={() => onDropdown()} />
      <div className={`dropdown ${className}`}>
        <button type="button" onClick={(e) => onClick(e, 'csv')}>
          Csv File
        </button>
        <button type="button" onClick={(e) => onClick(e, 'excel')}>
          Excel File
        </button>
      </div>
    </>
  );
};
const onCheck = (e, onSelect) => {
  console.log('e change', e.target.checked);
  onSelect(e.target.name, e.target.checked);
};
export const View = (props) => {
  const { className, onDropdown, columns, onSelect, isDropdownOpen } = props;
  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target) && event.target.name !== 'view-col') {
      isDropdownOpen();
    }
  };
  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <>
      <button
        data-cy="view-columns-button"
        type="button"
        name="view-col"
        className={`view ${className}`}
        onClick={() => onDropdown()}
      />
      <div data-cy="view-columns-dropdown" ref={wrapperRef} className={`dropdown ${className}`}>
        {columns.map((col, index) => {
          return (
            <div name="col-view-dd-wrapper" className={'column-item'} key={index}>
              <input
                data-cy={'columns-option'}
                type="checkbox"
                id={col.name}
                name={col.name}
                onChange={(e) => onCheck(e, onSelect)}
                checked={!col.omit}
              />
              <label htmlFor={col.name}>{col.name}</label>
              <hr />
            </div>
          );
        })}
      </div>
    </>
  );
};
View.propTypes = {
  className: PropTypes.string,
  onDropdown: PropTypes.func,
  columns: PropTypes.any,
  onSelect: PropTypes.func,
  isDropdownOpen: PropTypes.func,
};
Export.propTypes = {
  className: PropTypes.string,
  onDropdown: PropTypes.func,
  onClick: PropTypes.func,
};

Export.defaultProps = {
  className: '',
  onDropdown: null,
  onClick: null,
};

export const Print = (props) => <button type="button" className="print" onClick={() => props.onClick()} />;

Print.propTypes = {
  onClick: PropTypes.func,
};

Print.defaultProps = {
  onClick: null,
};
