import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import { Buttons } from './../../components/Buttons';

const useStyles = makeStyles({
  root: {
    height: 200,
    overflowX: 'hidden',
    textAlign: 'center',
  },
  table: {
    minWidth: 200,
  },
  terminal: {
    paddingTop: 50,
    textAlign: 'center',
  },
  selectTerminal: {
    alignItems: 'center',
    borderColor: '#7f7f7f',
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
  },
});

const CustomInvoices = (props) => {
  const classes = useStyles();
  const { customInvoiceFiles, open, handleClose, handleInvoicePDF, parentClasses, text } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Invoices</DialogTitle>
      <DialogContent className={classes.root}>
        <Divider />
        <Grid container spacing={3} className={classes.terminal}>
          {customInvoiceFiles &&
            customInvoiceFiles.map((filename, index) => {
              return (
                <Grid item xs={4} sm={12} md={6} key={index}>
                  <Buttons
                    className={parentClasses.viewInvoice}
                    variant="outlined"
                    type="button"
                    text={text(filename)}
                    key={index}
                    onClick={() => {
                      handleInvoicePDF(filename);
                    }}
                  />
                </Grid>
              );
            })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

CustomInvoices.propTypes = {
  customInvoiceFiles: PropTypes.array,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleInvoicePDF: PropTypes.func.isRequired,
  text: PropTypes.func,
  parentClasses: PropTypes.object,
};

export default CustomInvoices;
