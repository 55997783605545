import React, { Component } from 'react';
import { changeFocus } from '../../../helpers/changeFocusCard';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classes from './Payment.module.scss';
import Container from '@material-ui/core/Container';
import { ErrorMessage } from '../../../components/Message/ErrorMessage';
import Grid from '@material-ui/core/Grid';
import { checkCreditOrDebit } from '../../../helpers/PostDataApi';
import autoFillMultipassFields from '../../../helpers/functions/autoFillCardFields';
import { UserDetailById } from '../../../actions/AdminUserAction';
import {
  getInvoiceNumber,
  InvoiceDetailByIdForPay,
  DownloadInvoice,
  getCsrfToken,
} from '../../../actions/PaymentAction';
import { countryDataList } from '../../../actions/CompanyAction';
import { payByLink } from '../../../actions/RequestFormAction';
import Alert from '@mui/material/Alert';
import { Buttons } from '../../../components/Buttons';
import { Textfield } from '../../../components/Textfield';
import { SuccessMessage } from '../../../components/Message/SuccessMessage';
import { handleAddressSettingError } from '../../../helpers/functions/validateAddressfields';
import { initialAddressSettings, initialAddressState } from '../../../helpers/addressSettingsState';
import { CloseableAlerts } from '../../../components/Message/CloseableAlerts';
import config from '../../../constants/config';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MaskedInput from 'react-maskedinput';
import classNames from 'classnames';
import { loadTsepManifestScript } from '../../../helpers/tsepUtils';
import Typography from '@material-ui/core/Typography';
import validator from '../../../helpers/checkValidations';
import LoadingOverlay from 'react-loading-overlay';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Table from '@material-ui/core/Table';
import { getFeeCalculations, getCustomFeeOne, getSurchargeFee } from '../../../helpers/functions/feeCalculations';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { formatCurrency } from '../../../helpers/commonFunction';
import { setupPaymentGatewayProperties } from '../../../helpers/functions/paymentGateway.properties.js';
import Stack from '@mui/material/Stack';
import { rules } from '../../../constants/IntakeFormConstants';
import AddressFieldsDropDown from '../../../components/AddressFieldsDropDown/AddressFieldsDropDown';
import { checkAddressEnabled } from '../../../helpers/functions/addressFieldsEnabled';
import { isDueDatePassed } from '../../../helpers/InvoiceHelper';
import { getPolicyPDF, getLocationTermsAndConditions } from '../../../actions/TermConditionsAction';
import './tsepFields.css';
import Topheader from '../../../components/Topheader/Topheader.js';
import { CustomerDetailsId } from '../../../actions/CustomerAction';
import { CardActions } from '@mui/material';
import { getLocationSettingsForSelfPayByLocId } from '../../../actions/LocationAction.js';
function TabedPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabedPanel.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.any,
};

const IsDeletedComponents = (props) => {
  return (
    <>
      <h3 className="errorMsg mb-3" style={{ textAlign: 'center' }}>
        {' '}
        This invoice ({props.invoiceId} ) has been deleted!{' '}
      </h3>
    </>
  );
};

IsDeletedComponents.propTypes = {
  invoiceId: PropTypes.any,
  t: PropTypes.any,
};
class MultiInvoicePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      jobIdInvoices: [],
      invoiceNumbers: null,
      amountSum: 0,
      FRONT_END: 'Customer Portal',
      activeTab: 0,
      cardPanelShow: true,
      americanExpress: false,
      isLoading: false,
      isOpen: false,
      amount: '',
      payerName: '',
      total: 0,
      cardSubTotal: 0,
      achDiscount: 0,
      svcFee: 0,
      tax: 0,
      lateFee: 0,
      taxType: '%',
      svcType: '%',
      creditOrDebit: null,
      invoice: '',
      notes: '',
      createdBy: '',
      companyId: '',
      discountType: 1,
      discount: 0,
      isSubmit: false,
      addNewCard: false,
      isClose: false,
      cardWalletID: null,
      tsepFieldError: null,
      tsepFieldSuccess: false,
      tsepSuccessResponse: null,
      tsepAuth: false,
      dueDate: new Date(),
      invoiceDate: new Date(),
      misMatchError: [],
      CoustmerData: [],
      CardData: [],
      InvoicePendingData: [],
      successMessages: false,
      discountErr: false,
      updateCard: false,
      tabValue: 0,
      indexValue: 0,
      salesTaxTotal: 0,
      paytraceCredentials: {},
      locationId: {},
      isStoredCard: false,
      customerData: null,
      isMultiInvoicePay: true,
      oneTimeCustomerInvoice: false,
      binCodesResponse: {},
      cardType: null,
      userDetails: JSON.parse(localStorage.getItem('user')),
      customerId: { _id: '', firstName: '', lastName: '' },
      csrf: { 'csrf-token': '', 'csrf-secret': '' },
      virtualTerminalSettings: {},
      ...initialAddressSettings,
      ...initialAddressState,
      invoiceSettings: {},
      ach: {
        isOpen: false,
        accountName: '',
        accountNumber: '',
        routingNumber: '',
      },
      errorAch: {
        accountNameError: false,
        accountNumberError: false,
        routingNumberError: false,
      },
      card: {
        isOpen: false,
        cardHolderName: '',
        cardNumber: '',
        cardExpiryDate: '__/____',
        cardCsc: '___',
        customerToken: false,
        customerTokenUpdate: false,
      },
      error: {
        amountError: false,
        payerNameError: false,
        zipError: false,
      },
      errorCard: {
        cardNumber: false,
        cardHolderName: false,
        cardExpiryDate: false,
        cardCsc: false,
      },
      invoiceLable: 'Invoice',
      isCustomFieldEnabled: false,
      jobId: '',
      isPaymentTermConditionEnabled: false,
      isInvoiceTermConditionEnabled: false,
      isReceiptTermConditionEnabled: false,
      isLateFeeApplicable: false,
      dualFeeEnabled: false,
    };
    this.paymentRef = React.createRef();
    this.cardRef = React.createRef();
    this.msgRef = React.createRef();
    this.expiryDateRef = React.createRef();
    this.cscCardRef = React.createRef();
  }

  componentDidMount() {
    this.getCustomerDetails();
    this.getSelectedInvoiceData();
    this.getCsrfToken();
    document.addEventListener('tsepEventUpdate', this.handleTsepEventUpdated);
  }

  getCustomerDetails() {
    const userDetails = JSON.parse(localStorage.getItem('user'));
    this.props.CustomerDetailsId({ id: userDetails.customerId ?? process.env.CUSTOMER_TEMP_ID }).then((result) => {
      if (result.payload.customerData.data.statusCode === 200) {
        const { masked_card_number, firstName, lastName } = result.payload.customerData.data.response;
        this.setState({
          customerData: result.payload.customerData.data.response,
          creditOrDebit: result.payload.customerData.data.response.creditOrDebit,
          cardType: result.payload.customerData.data.response?.cardType,
          shippingAddress: result.payload.customerData.data.response.shippingAddress,
          billingAddress: result.payload.customerData.data.response.billingAddress,
          createdBy: userDetails,
          payerName: `${firstName ?? ''} ${lastName ?? ''}`,
        });
        if (masked_card_number && masked_card_number !== '') {
          this.setState({ isStoredCard: true });
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if specific props or state values have changed before taking action

    if (this.state.virtualTerminalSettings !== prevState.virtualTerminalSettings) {
      this.getTotalFee();
    }

    if (this.state.virtualTerminalSettings?.InvoiceSurcharge && this.state.creditOrDebit !== prevState.creditOrDebit) {
      this.getTotalFee();
    }

    // You can also perform actions unconditionally after each update
    // ...
  }

  setTsepCardPaymentType = () => {
    const cardNumberElement = document.getElementById('tsep-cardNum');
    if (cardNumberElement) {
      const cardNumberValue = cardNumberElement?.value.replace(/\s/g, '');
      this.checkCardPaymentType(cardNumberValue);
    }
  };

  handleTsepEventUpdated = (evnt) => {
    const tsepEventDetails = evnt.detail;
    if (tsepEventDetails.eventType === 'FieldValidationErrorEvent') {
      this.setState({ tsepFieldError: tsepEventDetails.event.message });
    } else if (tsepEventDetails.eventType === 'TokenEvent' && tsepEventDetails.event.status === 'PASS') {
      this.setState({
        tsepSuccessResponse: { ...tsepEventDetails.event },
        tsepFieldSuccess: true,
        tsepFieldError: null,
      });
      // Handle other cases if needed
    } else if (tsepEventDetails.eventType === 'TokenEvent' && tsepEventDetails.event.status === 'FAILURE') {
      this.setState({ tsepFieldError: tsepEventDetails.event.message });
    } else if (tsepEventDetails.eventType === 'FocusOutEvent' && tsepEventDetails.event.fieldName === 'tsep-cardNum') {
      this.setTsepCardPaymentType();
    } else if (
      tsepEventDetails.eventType === 'ErrorEvent' &&
      tsepEventDetails.event.status === 'FAIL' &&
      tsepEventDetails.event.responseCode === 'TSEPERR911'
    ) {
      this.setState({ tsepAuth: false });
    }
  };

  getTotalFee() {
    if (this.state.locationId?.customTowingBuild) {
      this.setState({
        total: parseFloat(this.state.total) + parseFloat(this.state.tax),
      });
    } else {
      const feeComponents = getFeeCalculations(
        this.state,
        this.state.amount,
        this.state.creditOrDebit,
        this.state.billingAddress.state
      );
      // Set calculations based on fee settings here
      this.setState(
        {
          total: parseFloat(feeComponents.totalAmount),
          tax: feeComponents.salesTaxFee,
          svcFee: (Math.round(parseFloat(feeComponents.svcFee) * 100) / 100).toFixed(2),
          salesTaxTotal: feeComponents.salesTaxTotal,
        },
        () => {
          // Handle lateFee calculation based on CustomFeeOneEnabled here
          if (this.state?.DualFeeEnabled) {
            const lateFee = getCustomFeeOne(this.state, this.state.amount);
            const cashTotal = parseFloat(this.state.amount) + (this.state.salesTaxTotal || 0) + this.getLateFee();
            this.setState({ lateFee, cashTotal });
          }

          if (this.state?.virtualTerminalSettings?.InvoiceSurcharge) {
            this.setState({
              surcharge: getSurchargeFee(
                this.state,
                parseFloat(this.state.amount),
                this.state.creditOrDebit,
                this.state.billingAddress.state
              ),
            });
          }
        }
      );
    }
  }

  getLateFee() {
    if (this.state.isLateFeeApplicable) {
      return this.state.lateFee;
    } else {
      return 0;
    }
  }

  getCsrfToken() {
    this.props.getCsrfToken().then((res) => {
      this.setState({ csrf: { ...res.payload.paymentData.data } });
    });
  }

  getInvoiceData() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    this.props.InvoiceDetailByIdForPay(params).then((res) => {
      if (res.payload.paymentData.data.success) {
        const data = res.payload.paymentData.data.response;
        if (data.achDetails == '' && this.state.activeTab == 0) {
          delete data.achDetails;
        }
        this.setState(
          {
            ...res.payload.paymentData.data.response,
            isLoading: false,
            SurchargeFeeName: 'SVC Fee',
            locationId: data.locationId,
            companyId: data.companyId,
            card: {
              ...res.payload.paymentData.data.response.card,
              cardNumber: '',
            },
          },
          () => {
            if (!this.state.isZip) {
              delete this.state.zip;
              delete this.state.error.zipError;
            }
          }
        );
        if (res.payload.paymentData.data.response.locationId?.customFieldsEnabled) {
          const jobIdCustomField = res.payload.paymentData.data.response?.locationId?.customFields?.find(
            (field) => field?.enabled
          );
          if (jobIdCustomField) {
            this.setState({
              invoiceLable: jobIdCustomField.value,
              jobId: res.payload.paymentData.data.response.jobId,
              isCustomFieldEnabled: true,
            });
          }
        }

        if (
          data.locationId?.multipassCredentials &&
          data.locationId?.gateway &&
          data.locationId?.gateway === 'multipass'
        ) {
          this.handleTsepManifestUpdate(data.locationId);
        }
      }
      if (res.payload.paymentData.data.response?.invoiceSettings) {
        this.setState(
          {
            addressSettings: {
              ...this.state.addressSettings,
              shippingAddress: res.payload.paymentData.data.response?.invoiceSettings?.shippingAddress,
              billingAddress: res.payload.paymentData.data.response?.invoiceSettings?.billingAddress,
            },
            invoiceSettings: res.payload.paymentData.data.response?.invoiceSettings,
            dualFeeEnabled: res.payload.paymentData.data.response.DualFeeEnabled,
          },
          () => {
            const isLateFeeApplicable = isDueDatePassed(
              res.payload.paymentData.data.response,
              this.state.invoiceSettings?.CustomFeeOneDueDays
            );
            this.setState({ isLateFeeApplicable: isLateFeeApplicable });
            if (isLateFeeApplicable) {
              this.setLateFeeState(this.state.amount);
            }
            this.setCardSubTotalTotalState();
          }
        );
      }
      if (res.payload.paymentData.data.statusCode === 401) {
        this.setState({ invalidMsg: res.payload.paymentData.data.message, isLoading: false });
      }
      if (this.state.locationId) {
        this.setPaymentGatewayProperties();
        this.fetchTermConditions();
      }
      if (this.state.isPaid) {
        this.props.history.push('/Customer/Payment/History');
      }
    });
  }

  getLocationSettings() {
    this.props.getLocationSettingsForSelfPayByLocId(this.state.invoices[0].locationId._id).then((res) => {
      this.setState(
        {
          ...res.payload.locationData.data.response.settings,
          dualFeeEnabled: res.payload.locationData.data.response.settings.DualFeeEnabled,
          isLoading: false,
          SurchargeFeeName: 'SVC Fee',
          locationId: res.payload.locationData.data.response.location,
          companyId: res.payload.locationData.data.response.location.company,
          amount: this.state.amountSum,
          invoice: this.state.invoiceNumbers,
        },
        () => {
          if (res.payload.locationData.data.response.location?.customFieldsEnabled) {
            const jobIdCustomField = res.payload.locationData.data.response?.location?.customFields?.find(
              (field) => field?.enabled
            );
            if (jobIdCustomField) {
              this.setState({
                invoiceLable: jobIdCustomField.value,
                jobId: this.state.invoice,
                isCustomFieldEnabled: true,
              });
            }
          }
          if (res.payload.locationData.data.response.settings.invoiceSettings) {
            this.setState({
              addressSettings: {
                shippingAddress: res.payload.locationData.data.response.settings.invoiceSettings.shippingAddress,
                billingAddress: res.payload.locationData.data.response.settings.invoiceSettings.billingAddress,
              },
              invoiceSettings: res.payload.locationData.data.response?.settings.invoiceSettings,
            });
          }
        }
      );
      this.setCardSubTotalTotalState();
      if (this.state.locationId) {
        this.setPaymentGatewayProperties();
        this.fetchTermConditions();
      }
      if (this.state.isPaid) {
        this.props.history.push('/Customer/Payment/History');
      }
    });
  }

  getSelectedInvoiceData = () => {
    const { selectedAmountSum, selectedInvoiceNumbers, selectedInvoices, invoices } = this.props.location.state || {};
    this.setState(
      {
        invoices: selectedInvoices,
        invoiceNumbers: selectedInvoiceNumbers,
        amountSum: selectedAmountSum,
        jobIdInvoices: invoices,
      },
      () => {
        this.getLocationSettings();
      }
    );
  };

  fetchTermConditions() {
    this.props.getLocationTermsAndConditions(this.state.companyId, this.state.locationId?._id).then((res) => {
      if (res.payload) {
        this.setState(
          {
            isPTC: res.payload?.isPaymentTermConditionEnabled,
            isRTC: res.payload?.isReceiptTermConditionEnabled,
            isITC: res.payload?.isInvoiceTermConditionEnabled,
            isSPB: res.payload?.isSelfPaymentPageBannerEnabled,
            isPPB: res.payload?.isPaymentPageBannerEnabled,
            paymentTermCondition: res.payload?.paymentTermCondition ?? '',
            receiptTermCondition: res.payload?.receiptTermCondition ?? '',
            invoiceTermCondition: res.payload?.invoiceTermCondition ?? '',
            selfPaymentBanner: res.payload?.selfPaymentBanner ?? '',
            paymentPageBanner: res.payload?.paymentPageBanner ?? '',
            privacy_filename: res.payload?.privacy_policy,
            refund_filename: res.payload?.refund_policy,
          },
          () => {
            this.forceUpdate();
          }
        );
      }
    });
  }

  setPaymentGatewayProperties() {
    const paymentGateway = this.state.locationId?.gateway || 'paytrace';
    const { misMatchErrors, paytraceCredentials, multipassCredentials } = setupPaymentGatewayProperties(
      paymentGateway,
      this.state.locationId
    );
    if (misMatchErrors && misMatchErrors.length > 0) {
      this.setState({ misMatchErrors });
    } else {
      paymentGateway === 'paytrace'
        ? this.setState({ paytraceCredentials }, () => {
            const localUrl = config.BASEURL + 'getPemFile?fileName=';
            window.paytrace.setKeyAjax(localUrl + this.state.paytraceCredentials.paytraceKey);
          })
        : this.setState({ multipassCredentials });
    }
  }

  handleTsepManifestUpdate = (locationDetails) => {
    // Update the manifest with new TSEP details from server
    loadTsepManifestScript(locationDetails, 'paylink').then((auth) => {
      this.setState({ tsepAuth: auth });
    });
  };

  setTaxState(value) {
    if (this.state.virtualTerminalSettings?.InvoiceSalesTaxFeeType === '$') {
      this.setState(
        {
          tax: this.state.virtualTerminalSettings?.InvoiceSalesTaxFee,
          taxType: this.state.virtualTerminalSettings?.InvoiceSalesTaxFeeType,
        },
        () => {
          this.setState({
            total: parseFloat(this.state.amount) + parseFloat(this.state.tax),
            cardSubTotal: parseFloat(this.state.amount),
          });
        }
      );
    } else {
      this.setState(
        {
          tax: (this.state.virtualTerminalSettings?.InvoiceSalesTaxFee / 100) * value,
          taxType: this.state.virtualTerminalSettings?.InvoiceSalesTaxFeeType,
        },
        () => {
          this.setState({
            total: parseFloat(this.state.amount) + parseFloat(this.state.tax),
            cardSubTotal: parseFloat(this.state.amount),
          });
        }
      );
    }
  }
  setLateFeeState(value) {
    if (this.state.invoiceSettings?.CustomFeeOneType === '$') {
      this.setState(
        {
          lateFee: this.state.invoiceSettings?.CustomFeeOne,
        },
        () => {
          this.setState({
            total:
              parseFloat(this.state.amount) +
              parseFloat(this.state.tax) +
              parseFloat(this.state.lateFee) +
              parseFloat(this.state.svcFee),
            cardSubTotal: parseFloat(this.state.amount) + parseFloat(this.state.svcFee),
          });
        }
      );
    } else {
      this.setState(
        {
          lateFee: (this.state.invoiceSettings?.CustomFeeOne / 100) * parseFloat(value),
        },
        () => {
          this.setState({
            total:
              parseFloat(this.state.amount) +
              parseFloat(this.state.tax) +
              parseFloat(this.state.lateFee) +
              parseFloat(this.state.svcFee),
            cardSubTotal: parseFloat(this.state.amount) + parseFloat(this.state.svcFee),
          });
        }
      );
    }
  }
  setSvcTotalState(value) {
    if (this.state.virtualTerminalSettings?.InvoiceDiscount) {
      if (this.state.virtualTerminalSettings?.InvoiceSVCFeeType === '$') {
        this.setState({ svcType: this.state.virtualTerminalSettings?.InvoiceSVCFeeType }, () => {
          this.setState({
            total:
              parseFloat(this.state.amount) +
              parseFloat(this.state.tax) +
              parseFloat(this.state.virtualTerminalSettings?.InvoiceSVCFee) +
              parseFloat(this.state.lateFee),
            cardSubTotal: parseFloat(this.state.amount) + parseFloat(this.state.virtualTerminalSettings?.InvoiceSVCFee),
          });
        });
      } else {
        this.setState({ svcFee: (this.state.virtualTerminalSettings?.InvoiceSVCFee / 100) * value }, () => {
          this.setState({
            total:
              parseFloat(this.state.amount) +
              parseFloat(this.state.tax) +
              parseFloat(this.state.svcFee) +
              parseFloat(this.state.lateFee),
            cardSubTotal: parseFloat(this.state.amount) + parseFloat(this.state.svcFee),
          });
        });
      }
    }
  }
  setCardSubTotalTotalState() {
    this.setState({
      cardSubTotal: parseFloat(this.state.amount) + parseFloat(this.state.svcFee),
    });
  }
  handleChange(event, _t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;

    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ [id]: value, error, misMatchError, isSubmit: false });
    if (!this.state.virtualTerminalSettings?.InvoiceTax && id === 'amount') {
      this.setState({ total: parseFloat(value) });
    } else if (id === 'amount' && this.state.virtualTerminalSettings?.InvoiceTax && value !== '0') {
      this.setTaxState(value);
      this.setSvcTotalState(value);
    }
    if (id === 'amount' && this.state.invoiceSettings?.CustomFeeOneEnabled && this.state.isLateFeeApplicable) {
      this.setLateFeeState(value);
    }
    if (id === 'payerName' && value !== '' && typeof value === 'string') {
      this.setState({ payerName: value.charAt(0).toUpperCase() + value.slice(1) });
    }
  }

  fetchSettingData() {
    this.props.UserDetailById({ id: this.state.userDetails._id }).then((response) => {
      if (response.type === 'USER_SUCCESS' && response.payload.userData.status === 200) {
        this.setState({ ...response.payload.userData.data.settings, isLoading: false });
      }
    });
  }

  checkCardPaymentType = (firstNumber) => {
    checkCreditOrDebit(config.BIN_CHECKER_URL, config.BIN_API_KEY, firstNumber.substring(0, 6))
      .then((data) => {
        this.setState({
          cardType: data.data.card ? data.data.card : null,
          creditOrDebit: data.data.type ? data.data.type : null,
        });
      })
      .catch((err) => {
        console.log('err1', err);
      });
  };

  handleChangeCard(event, _t) {
    let error = {};
    let misMatchError = Array.from(this.state.misMatchError);
    let { id, value } = event.target;

    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    if (id === 'cardNumber' && value[0] === '3' && value.length === 16 && value[value.length - 1] === '_') {
      value = value.slice(0, -1);
    }

    if (id === 'cardExpiryDate') {
      const month = value.split('/')[0];
      if (month && month.length == 2 && (parseInt(month) <= 0 || parseInt(month) > 12)) {
        error[id + 'Error'] = true;
        if (!misMatchError.includes('invalid card expiry date')) misMatchError.push('invalid card expiry date');
      }
    }
    this.setState(
      {
        card: { ...this.state.card, [id]: value },
        errorCard: { ...this.state.errorCard, [id]: error[id + 'Error'] },
        error,
        misMatchError,
        isSubmit: false,
      },
      () => {
        let firstNumber = this.state.card.cardNumber !== '' ? this.state.card.cardNumber : null;
        if (this.state.card.cardNumber[this.state.card.cardNumber.length - 1] !== '_') {
          this.setState({ card: { ...this.state.card, isOpen: true } });
        } else {
          this.setState({ card: { ...this.state.card, isOpen: false } });
        }
        if (firstNumber && id === 'cardNumber') {
          this.checkCardPaymentType(firstNumber);
        }
        if (firstNumber && firstNumber[0] === '3') {
          this.setState({ americanExpress: true });
        } else {
          this.setState({ americanExpress: false });
        }
      }
    );
    changeFocus(id, value, this.expiryDateRef.current, this.cscCardRef.current);
  }

  updateCardState(id, value, error) {
    let misMatchError = [];
    this.setState(
      {
        card: { ...this.state.card, [id]: value },
        errorCard: { ...this.state.errorCard, [id]: false },
        error,
        misMatchError,
        isSubmit: false,
      },
      () => {
        let firstNumber = this.state.card.cardNumber.substring(0, 14);
        if (firstNumber && id === 'cardNumber') {
          this.checkCardPaymentType(firstNumber);
        }
        if (firstNumber[0] === '3') {
          this.setState({ americanExpress: true });
        } else {
          this.setState({ americanExpress: false });
        }
      }
    );
  }
  handleChangeAch(event) {
    let error = {};
    let misMatchErrors = [];
    let { id, value } = event.target;
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState(
      {
        ach: { ...this.state.ach, [id]: value },
        errorCard: { ...this.state.errorCard, [id]: false },
        errorAch: { ...this.state.errorAch, [id + 'Error']: false },
        error,
        misMatchError: misMatchErrors,
        isSubmit: false,
      },
      () => {}
    );
  }

  deleteUnusedKeys() {
    if (this.state.tabValue === 1) {
      delete this.state.payerName;
      delete this.state.zip;
      if (this.state.address === '') {
        delete this.state.address;
      }
      if (!this.state.createdBy) {
        delete this.state.createdBy;
      }
    }
    if (this.state.lastName === '') {
      delete this.state.lastName;
    }
    delete this.state.endAddress;
    delete this.state.problemType;
    const keysTodeleteFromState = [
      'DiscountFeeName',
      'InvoiceSVCFee',
      'svcFee',
      'discountFeeName',
      'publicNotes',
      'privateNotes',
      'termNotes',
      'jobId',
    ];
    for (const [key, value] of Object.entries(this.state)) {
      if (
        (this.state[key] === '' && keysTodeleteFromState.includes(key)) ||
        (typeof this.state[key] === 'undefined' && keysTodeleteFromState.includes(key)) ||
        (this.state[key] === undefined && keysTodeleteFromState.includes(key)) ||
        (this.state[key] === null && keysTodeleteFromState.includes(key)) ||
        (value === 0 && keysTodeleteFromState.includes(key))
      ) {
        delete this.state[key];
      }
    }
  }
  deletePreSubmitUnecessaryKeys() {
    if (this.state.customerId === null) {
      return false;
    }
    if (this.state.notes === '') {
      delete this.state.notes;
    }
    if (this.state.timestamp_PAID === '') {
      delete this.state.timestamp_PAID;
    }
    if (this.state.InvoiceSalesTaxFee === '' || this.state.InvoiceSalesTaxFee === 0) {
      delete this.state.InvoiceSalesTaxFee;
    }
    if (!this.state.isCustomFieldEnabled) {
      delete this.state.jobId;
    }
  }
  validatePreSubmitStateKeys(error) {
    let errorFlag = false;
    Object.keys(this.state).forEach((key) => {
      if (
        validator(key, this.state[key]) ||
        this.state[key] === '' ||
        (typeof this.state[key] === 'string' && this.state[key].trim() === '')
      ) {
        if (key === 'receiptTermCondition') {
          return false;
        }
        if (key === 'paymentTermCondition') {
          return false;
        }
        if (key === 'invoiceTermCondition') {
          return false;
        }
        error[key + 'Error'] = true;
        errorFlag = true;
      } else {
        error[key + 'Error'] = false;
      }
    });
    return errorFlag;
  }

  handleSubmit(e, _t) {
    e.preventDefault();
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let error = {};
    const paymentGateway = this.state?.locationId?.gateway || 'paytrace';
    this.setState({ isSubmit: true });
    this.deletePreSubmitUnecessaryKeys();
    const locationDetails = this.state?.locationId;
    const addressError = handleAddressSettingError(this.state);
    if (addressError.length > 0) {
      misMatchError = addressError;
      this.setState({ misMatchError: this.state.misMatchError.concat(misMatchError) });
      return false;
    }

    if (this.state.errorCard.cardExpiryDate) {
      if (!this.state.misMatchError.includes('invalid card expiry date')) {
        misMatchError.push('invalid card expiry date');
        this.setState({ misMatchError: this.state.misMatchError.concat(misMatchError) });
      }
      return false;
    }
    this.deleteUnusedKeys();
    const errorFlag = this.validatePreSubmitStateKeys(error);
    const cardValidation =
      paymentGateway === 'paytrace' ? (this.state.isStoredCard ? true : this.checkCardValues(this.state)) : false;
    if (!cardValidation && errorFlag && locationDetails?.customDefaultBuild) {
      this.setState({ misMatchError, logoError, error, successMessages });
      return false;
    } else {
      this.initiatePaytraceRequest(misMatchError, successMessages);
    }
  }

  handleCard(_e) {
    this.setState({ isStoredCard: false });
  }

  initiatePaytraceRequest(misMatchError, successMessages) {
    window.paytrace.submitEncrypted('#DemoForm');
    this.setState(
      {
        card: { ...this.state.card },
        isLoading: true,
        encrypted_number: localStorage.getItem('0'),
        encrypted_csc: localStorage.getItem('1'),
        locationId: this.state.locationId,
      },
      () => {
        localStorage.removeItem('0');
        localStorage.removeItem('1');
        this.sendPaylinkRequest(successMessages, misMatchError);
      }
    );
  }

  sendPaylinkRequest(successMessages, misMatchError) {
    this.props.payByLink(this.state, this.state.csrf, this.state.paytraceCredentials, true).then((result) => {
      window.scrollTo(0, 0);
      this.handlePayByLinkResponse(result, successMessages, misMatchError);
    });
  }
  collectAllMisMatchError(result, misMatchError) {
    if (result.payload.error.data.response.length > 0) {
      let responseErrors = JSON.parse(result.payload.error.data.response);
      if (responseErrors.response_code === 1) {
        for (let key in responseErrors.errors) {
          let i = 0;
          misMatchError.push(responseErrors.errors[key][i] + '\n');
        }
      }
      if (responseErrors.response_code === 1001) {
        misMatchError.push(responseErrors.status_message);
      }
    }
  }
  handlePaymentError(result, successMessages) {
    const misMatchError = [];
    if (result !== undefined && result.type === 'INVOICE_ERROR') {
      if (result.payload.error.data) {
        let message = result.payload.error.data.message;
        misMatchError.push(message);
        this.collectAllMisMatchError(result, misMatchError);
        this.setState({ isLoading: false, misMatchError, successMessages });
      }

      this.setState({ isLoading: false, misMatchError, successMessages });
    }
  }
  handlePayByLinkResponse(result, successMessages, misMatchError) {
    if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
      window.scrollTo(0, 0);
      let emptyData = { payerName: '', zip: '' };

      this.setState({ isLoading: false, successMessages: true, misMatchError, ...emptyData }, () => {
        this.setState({ ach: { ...this.state.ach, accountName: '', accountNumber: '', routingNumber: '' } });
        setTimeout(function () {}, 2500);
      });

      this.props.history.push('/Customer/Payment/History');
    }
    this.handlePaymentError(result, successMessages);
  }
  validateAchCredentials(error) {
    let errorFlag = false;
    Object.keys(this.state.ach).forEach((key) => {
      if (
        validator(key, this.state.ach[key]) ||
        this.state.ach[key] === '' ||
        (typeof this.state.ach[key] === 'string' && this.state.ach[key].trim() === '')
      ) {
        error[key + 'Error'] = true;
        errorFlag = true;
      } else {
        error[key + 'Error'] = false;
      }
    });
    return errorFlag;
  }
  checkCardValues(state) {
    let errorAch = {};
    let successMessages = false;
    if (this.state.tabValue === 0) {
      this.setState({ ach: { ...this.state.ach, isOpen: false } });
      if (state.card.cardNumber.indexOf('_') > -1 || state.card.cardNumber === '') {
        this.setState({ errorCard: { ...this.state.errorCard, cardNumber: true } });
        return false;
      } else if (state.card.cardExpiryDate.indexOf('_') > -1 || state.card.cardExpiryDate === '') {
        this.setState({ errorCard: { ...this.state.errorCard, cardExpiryDate: true } });
        return false;
      } else if (state.card.cardCsc.indexOf('_') > -1 || state.card.cardCsc === '') {
        this.setState({ errorCard: { ...this.state.errorCard, cardCsc: true } });
        return false;
      } else {
        return true;
      }
    } else {
      this.setState({ ach: { ...this.state.ach, isOpen: true }, payerName: this.state.ach.accountName }, () => {
        delete this.state.zip;
      });
      const errorFlag = this.validateAchCredentials(errorAch);
      if (errorFlag) {
        this.setState({ errorAch, successMessages });
        return false;
      } else {
        return true;
      }
    }
  }
  handleDateChange = (date, key) => {
    this.setState({ [key]: date });
  };

  handleSubmitAddNewCard(_e, _t) {
    const cardDataInital = {
      cardCsc: '___',
      cardNumber: '',
      cardExpiryDate: '__/____',
    };
    this.setState({ card: { ...cardDataInital }, mask_number: '', name: '', updateCard: true, addNewCard: true });
  }

  openAdjustSettings() {
    this.setState({ isOpen: true });
  }

  handleClose = () => {
    this.setState({ isOpen: false, isLoading: true });
    this.fetchSettingData();
  };
  a11yProps(index) {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  }

  handleChangeTab(e, val) {
    this.setState({ tabValue: val }, () => {
      if (val === 0) {
        this.setState({ achDiscount: 0, activeTab: 0, ach: { ...this.state.ach, isOpen: false } }, () => {
          this.getTotalFee();
        });
      } else {
        localStorage.setItem('achDiscount', this.state.svcFee);
        this.setState(
          {
            achDiscount: parseFloat(localStorage.getItem('achDiscount')),
            activeTab: 1,
            ach: { ...this.state.ach, isOpen: true },
          },
          () => {
            this.setState(
              {
                total:
                  parseFloat(this.state.amount) +
                  parseFloat(this.state?.salesTaxTotal) +
                  parseFloat(this.state.svcFee + parseFloat(this.state.lateFee)),
                cardSubTotal: parseFloat(this.state.amount) + parseFloat(this.state.svcFee),
                tax: parseFloat(this.state?.salesTaxTotal),
                surcharge: 0,
              },
              () => {
                this.setState({ total: parseFloat(this.state.total) - parseFloat(this.state.achDiscount) });
              }
            );
          }
        );
      }
    });
  }

  handlePolicyPDF(policyName) {
    const params = {
      companyId: this.state.companyId._id,
      locationId: this.state.locationId?._id,
      policyType: policyName,
    };
    this.setState({ isLoading: true });
    this.props.getPolicyPDF(params).then((response) => {
      if (response) {
        const { status, policyName } = response.payload.data;
        if (status === 200) {
          const fileURL = config.BASEURL + `getPolicies/${policyName}`;
          setTimeout(() => {
            this.setState({ isLoading: false });
            window.open(fileURL);
          }, 3500);
        }
      }
    });
  }

  removeCloseableMessage = (index) => {
    let misMatchError = this.state.misMatchError;
    delete misMatchError[index];
    this.setState({ misMatchError });
  };

  handleAddressChange = (event, type, isBillingAddressSameAsShippingAddress = false) => {
    let { id, value } = event.target;
    if (type === 'shippingAddress' || isBillingAddressSameAsShippingAddress) {
      const shippingAddress = {
        ...this.state.shippingAddress,
        [id]: value,
      };
      this.setState({ shippingAddress });
    }
    if (type === 'billingAddress') {
      const billingAddress = {
        ...this.state.billingAddress,
        [id]: value,
      };
      this.setState({ billingAddress }, () => {
        if (id === 'state') {
          this.getTotalFee();
        }
      });
    }
  };

  handleIsBillingAddressSameAsShippingAddress = (isBillingAddressSameAsShippingAddress = false) => {
    if (isBillingAddressSameAsShippingAddress) {
      this.setState({ shippingAddress: { ...this.state.billingAddress } });
    }
  };

  getPaymentTitle = () => {
    if (this.state.dualFeeEnabled === true) {
      if (this.state.activeTab === 0) return 'Card Price';
      else return 'Cash Price';
    } else {
      return 'Payment Amount';
    }
  };

  getBannerText = () => {
    return <p className={classes.bannerText}>{this.state.paymentPageBanner}</p>;
  };

  getCardTitle = () => {
    if (this.state.dualFeeEnabled) {
      return 'Card';
    } else if (!this.state.creditOrDebit) {
      return 'Card';
    } else {
      return `${this.state.creditOrDebit + ' Card'}`;
    }
  };

  getTotalName = () => {
    if (this.state.dualFeeEnabled === true) {
      if (this.state.activeTab === 0) return 'Card Total';
      else return 'Cash Total';
    } else {
      return 'Total';
    }
  };

  isCashLabel = () => {
    if (this.state.dualFeeEnabled === true) {
      return this.state.activeTab !== 0;
    } else {
      return false;
    }
  };

  getTotalValue = () => {
    if (this.state.dualFeeEnabled === true) {
      if (this.state.activeTab === 0) return this.state.total;
      else return this.state.cashTotal;
    } else {
      return this.state.total;
    }
  };

  capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  TsepCardError = () => {
    return (
      <div>
        <Alert variant="outlined" severity="error">
          Payment form could not be loaded. There seems to a problem with gateway configuration. Please contact
          developer support.
        </Alert>
      </div>
    );
  };

  TsepForm = () => {
    if (this.state.tsepAuth) {
      return (
        <form method="POST" id="payment-form">
          <div id="tsep-cardContainer">
            <div id="tsep-cardNumDiv" data-auto-formatting="Y" data-validate-cc="Y" data-detect-card-type="Y"></div>
          </div>
          <div id="tsep-CardData">
            <div id="tsep-datepickerDiv" data-validate-expiry-date="Y"></div>
            <div id="tsep-cvv2Div" data-validate-cvv2="Y"></div>
          </div>
          <div id="tsep-cardHolderNameDiv" data-validate-name="Y" style={{ display: 'none' }}></div>
          <div id="tsep-zipCodeDiv" data-validate-zipcode="Y" style={{ display: 'none' }}></div>
        </form>
      );
    } else {
      this.TsepCardError();
    }
  };

  render() {
    const { t } = this.props;
    const isDeleted = this.state.STATUS === 'Deleted' ? true : false;
    const paymentGateway = this.state.locationId?.gateway || 'paytrace';
    if (paymentGateway === 'multipass') {
      autoFillMultipassFields();
    }
    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <Container component="main" maxWidth="xl">
            <Stack sx={{ width: '100%' }} spacing={2}>
              {this.state.misMatchError.map((e, id) => (
                <CloseableAlerts
                  key={id}
                  index={id}
                  message={e}
                  severity="error"
                  closer={this.removeCloseableMessage}
                />
              ))}
            </Stack>
            {this.state.successMessages ? (
              <SuccessMessage successes={[this.props.info.invoiceInfo.data.message]} />
            ) : (
              ''
            )}
            {this.state.tsepFieldError ? <ErrorMessage errors={[this.state.tsepFieldError]} /> : ''}
            <div className="min-wrap pay-page desktopPage">
              <LoadingOverlay
                className={classes.tpTableFullW}
                active={this.state.isLoading}
                spinner
                text="Fetching your response ..."
              >
                {isDeleted ? (
                  <IsDeletedComponents invoiceId={this.state.invoice} />
                ) : this.state.successMessages ? (
                  <>
                    <h3 className="noteMsg">Thank you for your business.</h3>
                  </>
                ) : (
                  <>
                    {this.state.isPaid || this.state.invalidMsg ? (
                      <h3 className="errorMsg mb-3">
                        Payment was already made for the invoice <a href={config.APPURL}>Back</a>
                      </h3>
                    ) : (
                      <Grid container spacing={3}>
                        <Grid item md={8}>
                          <input type="hidden" name="_csrf" value={this.state.csrf['csrf-token']} />
                          <input type="hidden" name="_csrfSecret" value={this.state.csrf['csrf-secret']} />
                          <div style={{ marginLeft: '18px', marginTop: '-30px', marginBottom: '10px' }}>
                            <h3 className="terminal-title">Invoice Payment</h3>
                          </div>
                          <Card>
                            <CardContent>
                              <div className="terminal-inner">
                                {this.state.isPaid ? (
                                  <h3 className="errorMsg">Payment link already has been used !</h3>
                                ) : (
                                  ''
                                )}
                                {this.state.invalidMsg ? (
                                  <h3 className="errorMsg mb-3">{this.state.invalidMsg}</h3>
                                ) : (
                                  ''
                                )}
                                <Grid container className="terminal-title-grid" spacing={3} alignItems="center">
                                  {this.state?.isPPB && (
                                    <Grid item sm={12}>
                                      <div className="borderd">{this.getBannerText()}</div>
                                    </Grid>
                                  )}
                                </Grid>
                                <form className={classes.form} id="DemoForm">
                                  <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12}>
                                      <Grid container>
                                        <Grid item md={12} className="mb-3">
                                          <label>{this.getPaymentTitle()}</label>
                                          <div className="pay-amount">
                                            {' '}
                                            {this.state.total
                                              ? formatCurrency(this.getTotalValue())
                                              : formatCurrency(this.state.amount)}
                                          </div>
                                        </Grid>
                                        <AppBar position="static" color="default">
                                          <Tabs
                                            value={this.state.tabValue}
                                            onChange={(e, val) => {
                                              this.handleChangeTab(e, val);
                                            }}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="fullWidth"
                                            aria-label="action tabs example"
                                          >
                                            <Tab label={this.getCardTitle()} {...this.a11yProps(0)} />
                                            {this.state.locationId?.ACH &&
                                            this.state.companyId &&
                                            // this.state.companyId.ACH &&
                                            typeof this.state.createdBy !== 'undefined' &&
                                            this.state.createdBy.permissions.ach ? (
                                              <Tab label="Bank transfer" {...this.a11yProps(1)} />
                                            ) : (
                                              ''
                                            )}
                                          </Tabs>
                                        </AppBar>
                                        <TabedPanel value={this.state.tabValue} index={0}>
                                          <Grid container spacing={3}>
                                            <Grid item md={12}>
                                              <Textfield
                                                error={this.state.error.payerNameError}
                                                helperText={
                                                  this.state.error.payerNameError
                                                    ? rules.payerName.messages.characterlimit
                                                    : ''
                                                }
                                                id="payerName"
                                                type="text"
                                                variant="outlined"
                                                labels={t('Name')}
                                                value={this.state.payerName}
                                                width={true}
                                                onChange={(e) => this.handleChange(e, t)}
                                              />
                                            </Grid>
                                            <Grid item md={12}>
                                              {paymentGateway === 'multipass' ? (
                                                this.TsepForm()
                                              ) : this.state.isStoredCard ? (
                                                <Grid container spacing={3}>
                                                  <Grid item md={8}>
                                                    <div className="block">
                                                      <Card className={classNames(['maskData', 'customCardWraper'])}>
                                                        <div className="Card_header">
                                                          <Typography
                                                            variant="h5"
                                                            component="h2"
                                                            style={{ textTransform: 'capitalize' }}
                                                          >
                                                            Name :{' '}
                                                            {`${this.state.customerData.firstName} ${this.state.customerData.lastName}`}
                                                          </Typography>
                                                        </div>
                                                        <CardContent className="cardContents">
                                                          <label className="MuiTypography-gutterBottom">
                                                            {'maskCardNumber'}
                                                          </label>
                                                          <Grid container item xs={12}>
                                                            {this.state.customerData.masked_card_number}
                                                          </Grid>
                                                        </CardContent>
                                                        <CardActions>
                                                          <Buttons
                                                            id="AddNewCard"
                                                            type="button"
                                                            variant="contained"
                                                            color="secondary"
                                                            className={'addNewCardBtn'}
                                                            onClick={(e) => this.handleCard(e)}
                                                            text={'Add Another Card'}
                                                          />
                                                        </CardActions>
                                                      </Card>
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              ) : (
                                                <>
                                                  <Grid item md={12}>
                                                    {this.state.errorCard.cardNumber ? (
                                                      <label
                                                        className={classNames([
                                                          'MuiTypography-gutterBottom',
                                                          this.state.errorCard.cardNumber ? 'errorCard' : '',
                                                        ])}
                                                      >
                                                        Please enter credit card details
                                                      </label>
                                                    ) : (
                                                      <label
                                                        className={classNames([
                                                          'MuiTypography-gutterBottom',
                                                          this.state.errorCard.cardNumber ? 'errorCard' : '',
                                                        ])}
                                                      >
                                                        Card Number
                                                      </label>
                                                    )}
                                                    <MaskedInput
                                                      type="text"
                                                      value={this.state.card.cardNumber}
                                                      className={classNames([classes.cardNum, 'pt-encrypt'])}
                                                      mask={
                                                        this.state.americanExpress
                                                          ? '111111111111111'
                                                          : '1111111111111111'
                                                      }
                                                      labels={t('cardNumber')}
                                                      id="cardNumber"
                                                      name="encrypted_number"
                                                      size="20"
                                                      onChange={(e) => this.handleChangeCard(e, t)}
                                                    />
                                                  </Grid>
                                                  <Grid className={classes.exp_Cvv}>
                                                    <Grid item md={4}>
                                                      {this.state.errorCard.cardExpiryDate ? (
                                                        <label
                                                          className={classNames([
                                                            'MuiTypography-gutterBottom',
                                                            this.state.errorCard.cardExpiryDate ? 'errorCard' : '',
                                                          ])}
                                                        >
                                                          Invalid Expiry Date
                                                        </label>
                                                      ) : (
                                                        <label
                                                          className={classNames([
                                                            'MuiTypography-gutterBottom',
                                                            this.state.errorCard.cardExpiryDate ? 'errorCard' : '',
                                                          ])}
                                                        >
                                                          Exp Date
                                                        </label>
                                                      )}
                                                      <MaskedInput
                                                        mask="11/1111"
                                                        className={classes.expDate}
                                                        value={this.state.card.cardExpiryDate}
                                                        name="cardExpiryDate"
                                                        id="cardExpiryDate"
                                                        placeholder="mm/yyyy"
                                                        ref={this.expiryDateRef}
                                                        onChange={(e) => this.handleChangeCard(e, t)}
                                                      />
                                                    </Grid>
                                                    <Grid item md={2}>
                                                      {this.state.errorCard.cardCsc ? (
                                                        <label
                                                          className={classNames([
                                                            'MuiTypography-gutterBottom',
                                                            this.state.errorCard.cardCsc ? 'errorCard' : '',
                                                          ])}
                                                        >
                                                          CVV Invalid
                                                        </label>
                                                      ) : (
                                                        <label
                                                          className={classNames([
                                                            'MuiTypography-gutterBottom',
                                                            this.state.errorCard.cardCsc ? 'errorCard' : '',
                                                          ])}
                                                        >
                                                          CVV
                                                        </label>
                                                      )}
                                                      <MaskedInput
                                                        type="text"
                                                        className={classNames([classes.cardCvv, 'pt-encrypt'])}
                                                        value={this.state.card.cardCsc}
                                                        mask={this.state.americanExpress ? '1111' : '111'}
                                                        id="cardCsc"
                                                        name="encrypted_csc"
                                                        ref={this.cscCardRef}
                                                        onChange={(e) => this.handleChangeCard(e, t)}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </>
                                              )}
                                            </Grid>
                                            {this.state.isZip ? (
                                              <Grid item md={6}>
                                                <label>{t('Zip')}</label>
                                                <Textfield
                                                  error={this.state.error.zipError}
                                                  id="zip"
                                                  type="text"
                                                  variant="outlined"
                                                  // labels={t("Zip")}
                                                  value={this.state.zip}
                                                  width={true}
                                                  onChange={(e) => {
                                                    const numberRegex = /^[0-9\b]+$/;
                                                    if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                      this.handleChange(e, t);
                                                    }
                                                  }}
                                                />
                                              </Grid>
                                            ) : (
                                              ''
                                            )}
                                            {this.state.isAddress ? (
                                              <Grid item md={6}>
                                                <label>{t('Address')}</label>
                                                <Textfield
                                                  error={this.state.error.addressError}
                                                  id="address"
                                                  type="text"
                                                  variant="outlined"
                                                  // labels={t("Zip")}
                                                  value={this.state.address}
                                                  width={true}
                                                  onChange={(e) => this.handleChange(e, t)}
                                                />
                                              </Grid>
                                            ) : (
                                              ''
                                            )}
                                            {checkAddressEnabled(this.state.addressSettings?.billingAddress) && (
                                              <Grid item xs={12} sm={12} md={12}>
                                                <AddressFieldsDropDown
                                                  type="billing"
                                                  state={this.state}
                                                  handleChange={this.handleAddressChange}
                                                  handleSameAddress={this.handleIsBillingAddressSameAsShippingAddress}
                                                />
                                              </Grid>
                                            )}
                                            {checkAddressEnabled(this.state.addressSettings?.shippingAddress) && (
                                              <Grid item xs={12} sm={12} md={12}>
                                                <AddressFieldsDropDown
                                                  type="shipping"
                                                  state={this.state}
                                                  handleChange={this.handleAddressChange}
                                                  handleSameAddress={this.handleIsBillingAddressSameAsShippingAddress}
                                                />
                                              </Grid>
                                            )}
                                          </Grid>
                                        </TabedPanel>
                                        <TabedPanel value={this.state.tabValue} index={1}>
                                          <Textfield
                                            required={true}
                                            error={this.state.errorAch.accountNameError}
                                            id="accountName"
                                            type="text"
                                            labels={t('AccountName')}
                                            value={this.state.ach.accountName}
                                            width={true}
                                            onChange={(e) => {
                                              this.handleChangeAch(e);
                                            }}
                                            variant="standard"
                                          />
                                          <Textfield
                                            required={true}
                                            error={this.state.errorAch.accountNumberError}
                                            id="accountNumber"
                                            type="text"
                                            labels={t('AccountNumber')}
                                            value={this.state.ach.accountNumber}
                                            width={true}
                                            onChange={(e) => this.handleChangeAch(e)}
                                            variant="standard"
                                          />
                                          <Textfield
                                            required={true}
                                            error={this.state.errorAch.routingNumberError}
                                            id="routingNumber"
                                            type="text"
                                            labels={t('RoutingNumber')}
                                            value={this.state.ach.routingNumber}
                                            width={true}
                                            onChange={(e) => {
                                              this.handleChangeAch(e);
                                            }}
                                            variant="standard"
                                          />
                                        </TabedPanel>
                                      </Grid>
                                      <Grid item md={12}>
                                        {this.state.isPaid ||
                                        this.state.invoice === '' ||
                                        this.state.successMessages ? (
                                          ''
                                        ) : (
                                          <Buttons
                                            startIcon={<LockOutlinedIcon />}
                                            id="signUp"
                                            type="button"
                                            fullWidth={true}
                                            variant="contained"
                                            color="primary"
                                            className={classes.payBtn}
                                            disabled={
                                              this.state.tabValue === 0 &&
                                              paymentGateway === 'multipass' &&
                                              !this.state.tsepFieldSuccess
                                            }
                                            onClick={(e) => this.handleSubmit(e, t)}
                                            text={t('Pay')}
                                          />
                                        )}
                                      </Grid>
                                      <Grid item md={12}>
                                        {this.state.activeTab === 0 ? (
                                          <p className="font-sm">
                                            By clicking 'Pay' I authorize {this.state.locationId?.locationName} to
                                            charge my card{' '}
                                            {this.state.total
                                              ? formatCurrency(this.state.total)
                                              : formatCurrency(this.state.amount)}{' '}
                                            on {new Date(this.state.dueDate).toDateString()}.
                                            <Link
                                              className={[classes.back, classes.curser]}
                                              onClick={() => this.handlePolicyPDF('privacy_policy')}
                                            >
                                              {' '}
                                              Privacy Statement.
                                            </Link>
                                            <Link
                                              className={[classes.back, classes.curser]}
                                              onClick={() => this.handlePolicyPDF('refund_policy')}
                                            >
                                              {' '}
                                              Refund Policy.
                                            </Link>
                                          </p>
                                        ) : (
                                          <p className="font-sm">
                                            By clicking 'Pay' I authorize {this.state.locationId?.locationName} to debit
                                            my bank account by ACH{' '}
                                            {this.state.total
                                              ? formatCurrency(this.state.total)
                                              : formatCurrency(this.state.amount)}{' '}
                                            on {new Date().toDateString()}.{' '}
                                            <Link
                                              className={[classes.back, classes.curser]}
                                              onClick={() => this.handlePolicyPDF('privacy_policy')}
                                            >
                                              {' '}
                                              Privacy Statement.
                                            </Link>
                                            <Link
                                              className={[classes.back, classes.curser]}
                                              onClick={() => this.handlePolicyPDF('refund_policy')}
                                            >
                                              {' '}
                                              Refund Policy.
                                            </Link>
                                          </p>
                                        )}
                                      </Grid>
                                      <Grid item md={12}>
                                        <div
                                          className="font-sm"
                                          dangerouslySetInnerHTML={{ __html: this.state.billingTerms }}
                                        ></div>
                                        {this?.state?.isPaymentTermConditionEnabled && (
                                          <div
                                            className="font-sm"
                                            dangerouslySetInnerHTML={{ __html: this.state.paymentTermCondition }}
                                          ></div>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </form>
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item md={4}>
                          <Card>
                            <CardContent>
                              <Grid container spacing={3}>
                                <Grid item xs={12} className={classes.invoiceDetail}>
                                  <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableBody>
                                      <TableRow>
                                        <TableCell align="left">{this.state.invoiceLable}</TableCell>
                                        <TableCell align="right" style={{ overflowWrap: 'anywhere' }}>
                                          {this.state.jobId ? this.state.jobId : this.state.invoice}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell align="left">Due date</TableCell>
                                        <TableCell align="right">
                                          {new Date(this.state.dueDate).toDateString()}
                                        </TableCell>
                                      </TableRow>
                                      {this.state.dualFeeEnabled && this.isCashLabel() && (
                                        <TableRow>
                                          <TableCell align="left">Cash Subtotal</TableCell>
                                          <TableCell align="right">{formatCurrency(this.state.amount)}</TableCell>
                                        </TableRow>
                                      )}
                                      {this.state.dualFeeEnabled && !this.isCashLabel() && (
                                        <TableRow>
                                          <TableCell align="left">Card Subtotal</TableCell>
                                          <TableCell align="right">{formatCurrency(this.state.cardSubTotal)}</TableCell>
                                        </TableRow>
                                      )}
                                      {!this.state.dualFeeEnabled && (
                                        <TableRow>
                                          <TableCell align="left">Subtotal</TableCell>
                                          <TableCell align="right">{formatCurrency(this.state.amount)}</TableCell>
                                        </TableRow>
                                      )}
                                      {this.state.virtualTerminalSettings?.InvoiceDiscount &&
                                        !this.state.dualFeeEnabled && (
                                          <TableRow>
                                            <TableCell align="left">
                                              {this.state.virtualTerminalSettings?.DiscountFeeName
                                                ? this.state.virtualTerminalSettings?.DiscountFeeName
                                                : 'Svc Fee'}
                                            </TableCell>
                                            <TableCell align="right">{formatCurrency(this.state.svcFee)}</TableCell>
                                          </TableRow>
                                        )}

                                      {this.state.achDiscount > 0 && !this.state.dualFeeEnabled ? (
                                        <TableRow>
                                          <TableCell align="left">ACH Discount</TableCell>
                                          <TableCell align="right">-{formatCurrency(this.state.achDiscount)}</TableCell>
                                        </TableRow>
                                      ) : (
                                        ''
                                      )}

                                      {this.state.virtualTerminalSettings &&
                                        this.state.virtualTerminalSettings?.InvoiceSurcharge &&
                                        this.state?.creditOrDebit === 'CREDIT' && (
                                          <TableRow>
                                            <TableCell align="left">Surcharge</TableCell>
                                            <TableCell align="right">{formatCurrency(this.state.surcharge)}</TableCell>
                                          </TableRow>
                                        )}
                                      {this.state.virtualTerminalSettings?.InvoiceTax && (
                                        <TableRow>
                                          <TableCell align="left">Tax</TableCell>
                                          <TableCell align="right">{formatCurrency(this.state.tax)}</TableCell>
                                        </TableRow>
                                      )}

                                      {this.state.invoiceSettings?.CustomFeeOneEnabled &&
                                        this.state.isLateFeeApplicable && (
                                          <TableRow>
                                            <TableCell align="left">
                                              {this.state.invoiceSettings?.CustomFeeOneName
                                                ? this.state.invoiceSettings?.CustomFeeOneName
                                                : 'Late Fee'}
                                            </TableCell>
                                            <TableCell align="right">{formatCurrency(this.state.lateFee)}</TableCell>
                                          </TableRow>
                                        )}
                                      <TableRow>
                                        <TableCell align="left">
                                          <b>{this.getTotalName()}</b>
                                        </TableCell>
                                        <TableCell align="right">
                                          <b>
                                            {this.state.total
                                              ? formatCurrency(this.getTotalValue())
                                              : formatCurrency(this.state.amount)}
                                          </b>
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </LoadingOverlay>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
MultiInvoicePayment.propTypes = {
  value: PropTypes.any,
  index: PropTypes.any,
  getCsrfToken: PropTypes.any,
  match: PropTypes.any,
  InvoiceDetailByIdForPay: PropTypes.any,
  getLocationSettingsForSelfPayByLocId: PropTypes.any,
  PAYTRACE_AWS_PATH: PropTypes.any,
  t: PropTypes.any,
  UserDetail: PropTypes.any,
  children: PropTypes.any,
  invoiceId: PropTypes.any,
  UserDetailById: PropTypes.any,
  payByLink: PropTypes.any,
  DownloadInvoice: PropTypes.any,
  info: PropTypes.any,
  termsAndConditions: PropTypes.any,
  getPolicyPDF: PropTypes.any,
  getLocationTermsAndConditions: PropTypes.any,
  history: PropTypes.any,
  CustomerDetailsId: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.any,
  }),
};
function mapStateToProps(state) {
  return {
    info: state.InvoiceReducer,
    UserDetail: state.AdminUserReducer,
    PAYTRACE_AWS_PATH: state.EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH,
    termsAndConditions: state.TermsConditionReducer,
  };
}

export default connect(mapStateToProps, {
  getCsrfToken,
  DownloadInvoice,
  UserDetailById,
  InvoiceDetailByIdForPay,
  getLocationSettingsForSelfPayByLocId,
  payByLink,
  getPolicyPDF,
  getInvoiceNumber,
  countryDataList,
  getLocationTermsAndConditions,
  CustomerDetailsId,
})(withTranslation()(MultiInvoicePayment));
