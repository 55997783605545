import { ActionType } from './../constants';
import { DataApi, DataGetApi } from './../helpers/PostDataApi';
import { config } from './../constants';

/**
 * Company info Begin Action
 */
export const companyStart = () => ({
  type: ActionType.COMPANY_START,
});

/**
 * Company info Success Action
 */
export const companySuccess = (companyData) => {
  return {
    type: ActionType.COMPANY_SUCCESS,
    payload: { companyData },
  };
};

/**
 * Company info Failure Action
 */
export const companyError = (error) => ({
  type: ActionType.COMPANY_ERROR,
  payload: { error },
});

/**
 * Company driver info
 */
export const companyAdd = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(companyStart());
    return DataApi(data, config.BASEURL + 'companyAdd', token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(companySuccess(response));
        } else {
          return dispatch(companyError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const companyList = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(companyStart());
    return DataGetApi(config.BASEURL + 'companyList', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(companySuccess(response));
        } else {
          return dispatch(companyError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const countryDataList = () => {
  return (dispatch) => {
    dispatch(companyStart());
    return DataGetApi(config.BASEURL + 'countryDataList')
      .then((response) => {
        if (response.status === 200) {
          return dispatch(companySuccess(response));
        } else {
          return dispatch(companyError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const CompanyDetailById = (id) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(companyStart());
    return DataGetApi(config.BASEURL + 'CompanyDetailById', { id: id }, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(companySuccess(response));
        } else {
          return dispatch(companyError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const CompanyAllDetailById = (id) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(companyStart());
    return DataGetApi(config.BASEURL + 'CompanyAllDetailById', { id: id }, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(companySuccess(response));
        } else {
          return dispatch(companyError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const CompanyLocationDataById = (id) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    return DataGetApi(config.BASEURL + 'CompanyLocationDataById', { companyId: id }, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(companySuccess(response));
        } else {
          return dispatch(companyError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const AllCompany = () => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(companyStart());
    return DataGetApi(config.BASEURL + 'AllCompany', {}, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(companySuccess(response));
        } else {
          return dispatch(companyError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const companyEdit = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(companyStart());
    return DataApi(data, config.BASEURL + 'companyEdit', token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(companySuccess(response));
        } else {
          return dispatch(companyError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const companyDelete = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(companyStart());
    return DataApi(data, config.BASEURL + 'companyDelete', token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(companySuccess(response));
        } else {
          return dispatch(companyError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const companyStatus = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(companyStart());
    return DataApi(data, config.BASEURL + 'companyStatus', token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(companySuccess(response));
        } else {
          return dispatch(companyError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const companySettings = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(companyStart());
    return DataApi(data, config.BASEURL + 'companySettings', token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(companySuccess(response));
        } else {
          return dispatch(companyError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const companyTermCondition = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(companyStart());
    return DataApi(data, config.BASEURL + `companyTermCondition?id=${user.company.id}`, token, true, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(companySuccess(response));
        } else {
          return dispatch(companyError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const companyGetTermCondition = (data) => {
  return (dispatch) => {
    dispatch(companyStart());
    return DataGetApi(config.BASEURL + 'companyGetTermCondition', data)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(companySuccess(response));
        } else {
          return dispatch(companyError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
