import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Topheader } from '../../../components/Topheader';
import { getInvoiceList } from '../../../actions/PaymentAction';
import { fetchDetails } from '../../../actions/AdminUserAction';
import LoadingOverlay from 'react-loading-overlay';
import { Table } from '../../../components/Table';
import classes from './Invoice.module.scss';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { Checkbox } from '@material-ui/core';
import config from '../../../constants/config';
import { DataGetApi, DataApi } from '../../../helpers/PostDataApi';
import { formatCurrency } from '../../../helpers/commonFunction';
import { ErrorMessage } from '../../../components/Message/ErrorMessage';
import { whichLocationSelected } from '../../../helpers/getLocationFromLocalStorage';
import { CustomerDetailsId } from '../../../actions/CustomerAction';
import { PAYMENT_STATUS_BUTTON_CONFIG, customFilterAndSearch } from '../../../helpers/InvoiceHelper';

class Invoice extends Component {
  constructor(props) {
    const date = new Date();
    super(props);
    this.state = {
      sideShow: true,
      isLoading: false,
      reload: false,
      misMatchError: [],
      userData: { permissions: {} },
      userDetails: { permissions: {} },
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate() - 45)),
      invoiceLabel: localStorage.getItem('invoiceLabel'),
      customFieldsEnabled: false,
      locationId: '',
      invoiceData: [],
      customerDetails: {},
      selectedRows: [],
      selectedTotalSum: 0,
      selectedAmountSum: 0,
      selectedInvoiceNumbers: '',
    };
  }

  componentDidMount() {
    this.getCustomerDetails();
    this.getData().then((result) => this.setState({ invoiceData: result?.data }));
  }

  getCustomerDetails() {
    const userDetails = JSON.parse(localStorage.getItem('user'));
    this.props.CustomerDetailsId({ id: userDetails.customerId ?? process.env.CUSTOMER_TEMP_ID }).then((result) => {
      if (result.payload.customerData.data.statusCode === 200) {
        this.setState({ customerDetails: result.payload.customerData.data.response });
      }
    });
  }

  UNSAFE_componentWillMount() {
    let user = JSON.parse(localStorage.getItem('user'));
    const locationDetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    this.setState({
      locationId: locationDetails?.id,
    });
    if (locationDetails?.customFieldsEnabled) {
      const jobIdCustomField = locationDetails?.customFields?.find((cf) => cf && cf?.enabled);
      if (jobIdCustomField && jobIdCustomField?.enabled) {
        this.setState({
          invoiceLabel: jobIdCustomField.value,
          customFieldsEnabled: true,
        });
      }
    }
    let data = fetchDetails({ id: user._id, isCustomerUser: true });
    data.then((res) => {
      if (res.type === 'FETCH_SUCCESS') {
        this.setState({ userDetails: { ...res.payload.userData }, userData: { ...res.payload.userData } }, () => {});
      }
    });
  }
  UNSAFE_componentWillReceiveProps() {
    const currentLocation = whichLocationSelected();
    const jobIdCustomField = currentLocation?.customFields?.find((field) => field?.enabled);
    if (jobIdCustomField && currentLocation?.customFieldsEnabled) {
      this.setState({ invoiceLabel: jobIdCustomField.value, customFieldsEnabled: true });
    }
  }

  toDateFilter(date) {
    const query = { toDate: date };
    this.getData(query).then((result) => {
      this.setState({ invoiceData: result?.data, toDate: date });
    });
  }
  fromDateFilter(date) {
    const query = { fromDate: date };
    this.getData(query).then((result) => {
      this.setState({ invoiceData: result.data, fromDate: date });
    });
  }
  handleChangeId(rowData) {
    const { isPaid: status, _id: id } = rowData;
    const invoiceRedirectToPayment = ['pending, pastdue, inqueue'];

    const payload = {
      ...rowData,
      ...this.state,
      getHashForInvoice: true,
    };

    if (status || _.lowerCase(rowData.STATUS) === 'paid') {
      this.props.history.push('/Customer/EditInvoices/' + id);
    } else if (invoiceRedirectToPayment.indexOf(rowData.STATUS.toLowerCase()) === -1) {
      this.setState({ isLoading: true });
      this.getPayLink(payload).then(({ data }) => {
        this.setState({ isLoading: false });
        this.props.history.push('/Customer/Payment/' + data.hash);
      });
    }
  }

  getPayLink(rowData) {
    return new Promise((resolve, _reject) => {
      const userDetails = JSON.parse(localStorage.getItem('user'));
      const token = userDetails.token;
      const url = config.BASEURL;
      DataApi(rowData, `${url}payLink`, token, true, false).then((response) => {
        resolve({ data: response.data.response });
      });
    });
  }

  tableReload(status) {
    this.setState({ reload: status });
  }

  getPaymentStatus(invoice) {
    const isPastDueDate = new Date(invoice.dueDate).toLocaleDateString() < new Date().toLocaleDateString();
    const isPending = _.lowerCase(invoice.STATUS) === 'pending';
    if (isPending && isPastDueDate) {
      invoice.STATUS = 'PASTDUE';
      return invoice.STATUS;
    }
    return invoice.STATUS;
  }

  getPaymentType(result) {
    if (result.CARDTYPE === 'ACH' || result.RESPONSECODE === '120' || result?.achDetails?.isOpen) {
      return 'ACH';
    }
    if (result?.CASH) {
      return 'Cash';
    }
    if (result.creditOrDebit !== null && result.creditOrDebit !== '' && typeof result.creditOrDebit === 'string') {
      return _.capitalize(result.creditOrDebit);
    }
    if (result?.creditOrDebit === '') {
      return 'N/A';
    }
    if (result?.submitedToPax && result.creditOrDebit === null) {
      return 'N/A';
    }
    return 'N/A';
  }

  getHoverText(item) {
    if (['paid', 'submitted', 'deleted'].includes(item.STATUS.toLowerCase())) {
      return 'View Details';
    } else {
      return 'Click to pay';
    }
  }

  getFirstName(item) {
    let firstName = '';
    if (item?.isOneTimeCustomer && item?.submitedToPax) {
      firstName = item?.oneTimeCustomerDetails?.firstName;
    } else if (item?.firstName) {
      firstName = item.firstName;
    } else {
      firstName = item.customerId?.firstName ?? item?.payerName?.split(' ')[0] ?? item?.field1?.value;
    }
    return firstName;
  }
  getLastName(item) {
    let lastName = '';
    if (item?.isOneTimeCustomer && item?.submitedToPax) {
      lastName = item?.oneTimeCustomerDetails?.lastName;
    } else if (item?.lastName) {
      lastName = item.lastName;
    } else {
      lastName = item.customerId?.lastName ?? item?.payerName?.split(' ')[0] ?? item?.field1?.value;
    }
    return lastName;
  }
  getData(query) {
    const state = this.state ? this.state : query.props.componentState;
    return new Promise((resolve, _reject) => {
      let userDetails = JSON.parse(localStorage.getItem('user'));
      let locationID = localStorage.getItem('locationArrIds');
      let token = userDetails.token;
      let url = config.BASEURL;
      let param = {
        locationIds: locationID,
        role: userDetails.role,
        userId: userDetails._id,
        customerId: userDetails.customerId ?? process.env.CUSTOMER_TEMP_ID,
        companyId: userDetails.company._id,
        toDate: query?.toDate ? query.toDate : state.toDate,
        fromDate: query?.fromDate ? query.fromDate : state.fromDate,
        permissions: userDetails.permissions,
      };
      DataGetApi(url + 'customer/getInvoiceList', param, token).then((response) => {
        let modifiedData1 = [];
        modifiedData1 = response.data.response.map((item) => ({
          ...item,
          location: item.locationId ? item.locationId.locationName : '',
          firstName: item.firstName
            ? item.firstName
            : item?.customerId?.firstName ??
              item?.payerName?.split(' ')[0] ??
              item?.field1?.value ??
              item?.oneTimeCustomerDetails?.firstName,
          lastName: item.lastName
            ? item?.lastName
            : item?.customerId?.lastName ??
              item?.payerName?.split(' ')[1] ??
              item?.field2?.value ??
              item?.oneTimeCustomerDetails?.lastName,
          paymentStatus: this.getPaymentStatus(item),
          STATUS: this.getPaymentStatus(item),
          paymentType: this.getPaymentType(item),
        }));
        resolve({
          data: modifiedData1,
        });
      });
    });
  }

  getExportData(query) {
    const state = this.state ? this.state : query.props.componentState;
    let orderBy = query.orderBy !== undefined ? query.orderBy.field : '';
    let orderDirection = query.orderDirection !== '' ? query.orderDirection : '';
    return new Promise((resolve, _reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      let locationId = localStorage.getItem('locationArrIds');
      let token = user.token;
      let url = config.BASEURL;
      let param = {
        locationIds: locationId,
        role: user.role,
        userId: user._id,
        companyId: user.company._id,
        per_page: query.pageSize,
        page: +(query.page + 1),
        searchAll: typeof query.search === 'undefined' || query.search === '' ? false : true,
        toDate: query.toDate ? query.toDate : state.toDate,
        fromDate: query.toDate ? query.fromDate : state.fromDate,
        orderBy: orderBy,
        orderDirection: orderDirection,
        permissions: user.permissions,
      };
      DataGetApi(url + 'getExportList', param, token).then((result) => {
        let modifiedData = [];
        modifiedData = result.data.response.map((item) => ({
          ...item,
          location: item.locationId ? item.locationId.locationName : '',
          firstName: item.firstName
            ? item.firstName
            : item?.customerId?.firstName ??
              item?.payerName?.split(' ')[0] ??
              item?.field1?.value ??
              item?.oneTimeCustomerDetails?.firstName,
          lastName: item.lastName
            ? item?.lastName
            : item?.customerId?.lastName ??
              item?.payerName?.split(' ')[1] ??
              item?.field2?.value ??
              item?.oneTimeCustomerDetails?.lastName,
          paymentStatus: this.getPaymentStatus(item),
          STATUS: this.getPaymentStatus(item),
          paymentType: this.getPaymentType(item),
        }));
        resolve({
          data: modifiedData,
        });
      });
    });
  }

  getTotal = (data) => {
    const locationCurrent = JSON.parse(localStorage.getItem('locationPaydetails'));
    let total = 0;
    if (locationCurrent?.customTowingBuild && data.tax && data.total) {
      total = data.total + data.tax;
    } else if (data.total) {
      total = data.total;
    } else {
      total = data?.amount;
    }

    return formatCurrency(total);
  };
  handleRowClick = (event, rowData) => {
    const selectedIndex = this.state.selectedRows.findIndex((row) => row._id === rowData._id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(this.state.selectedRows, rowData);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(this.state.selectedRows.slice(1));
    } else if (selectedIndex === this.state.selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(this.state.selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        this.state.selectedRows.slice(0, selectedIndex),
        this.state.selectedRows.slice(selectedIndex + 1)
      );
    }
    this.setState({ selectedRows: newSelectedRows }, () => {});
  };
  isSelected = (id) => this.state.selectedRows.some((row) => row._id === id);
  calculateTotal = () => {
    const { selectedRows } = this.state;
    let totalSum = 0;
    let amountSum = 0;
    let invoiceNumbers = '';
    let invoices = '';
    selectedRows.forEach((row, index) => {
      totalSum += row.total;
      amountSum += row.amount;
      invoiceNumbers += (index === 0 ? '' : ', ') + (row.jobId ? row.jobId : row.invoice);
      invoices += (index === 0 ? '' : ', ') + row.invoice;
    });

    return { totalSum, amountSum, invoiceNumbers, invoices };
  };

  handlePayMultipleInvoices = () => {
    const { totalSum, amountSum, invoiceNumbers, invoices } = this.calculateTotal();
    this.setState(
      { selectedTotalSum: totalSum, selectedAmountSum: amountSum, selectedInvoiceNumbers: invoiceNumbers },
      () => {
        this.props.history.push({
          pathname: '/Customer/Invoices/Payment',
          state: {
            selectedTotalSum: totalSum,
            selectedAmountSum: amountSum,
            selectedInvoiceNumbers: invoiceNumbers,
            selectedInvoices: this.state.selectedRows,
            invoices: invoices,
          },
        });
      }
    );
  };
  render() {
    const locationCurrent = JSON.parse(localStorage.getItem('locationPaydetails'));
    let customInvoicePageFields = locationCurrent?.customInvoicePageFields;
    let isHidden = false;
    if (locationCurrent?.customTowingBuild) {
      isHidden = true;
    } else {
      customInvoicePageFields = [];
    }

    const columns = [
      {
        title: 'Select',
        hidden: !locationCurrent.multiInvoicePayment,
        render: (rowData) => {
          const isSelected = this.isSelected(rowData._id);
          const isSelectable = ['pending', 'pastdue', 'inqueue'].includes(rowData.STATUS.toLowerCase());

          return (
            <Tooltip title={this.getHoverText(rowData)}>
              <Checkbox
                id={`checkbox-Action-${rowData._id}`}
                disabled={!isSelectable}
                checked={isSelectable && isSelected}
                onChange={(event) => this.handleRowClick(event, rowData)}
                inputProps={{ 'aria-labelledby': `checkbox-list-label-${rowData._id}` }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: this.state.invoiceLabel,
        field: 'invoice',
        render: (rowData) => (
          <Tooltip title={this.getHoverText(rowData)}>
            <span className={classes.Underline} onClick={(_e) => this.handleChangeId(rowData)}>
              {this.state.customFieldsEnabled && rowData.jobId ? rowData.jobId : rowData.invoice}
            </span>
          </Tooltip>
        ),
        customFilterAndSearch: (term, rowData) => {
          const fieldToSearch = this.state.customFieldsEnabled && rowData.jobId ? rowData.jobId : rowData.invoice;
          return customFilterAndSearch(fieldToSearch, term);
        },
      },
      {
        title: customInvoicePageFields.length > 0 && customInvoicePageFields[0]?.value,
        hidden:
          customInvoicePageFields.length > 0 && locationCurrent?.customTowingBuild
            ? !customInvoicePageFields[0]?.enabled
            : true,
        field: customInvoicePageFields.length > 0 && customInvoicePageFields[0]?.value.replace(/\s/g, '').toLowerCase(),
        render: (rowData) =>
          rowData?.field1?.key && rowData?.field1?.value ? rowData?.field1?.value : rowData?.firstName ?? '',
      },
      {
        title: customInvoicePageFields.length > 0 && customInvoicePageFields[1]?.value,
        hidden:
          customInvoicePageFields.length > 0 && locationCurrent?.customTowingBuild
            ? !customInvoicePageFields[1]?.enabled
            : true,
        field: customInvoicePageFields.length > 0 && customInvoicePageFields[1]?.value.replace(/\s/g, '').toLowerCase(),
        render: (rowData) =>
          rowData?.field2?.key && rowData?.field2?.value ? rowData?.field2?.value : rowData?.lastName ?? '',
      },
      {
        title: 'First Name',
        field: 'firstName',
        hidden: isHidden,
        render: (rowData) => this.getFirstName(rowData),
      },
      {
        title: 'Last Name',
        field: 'lastName',
        hidden: isHidden,
        render: (rowData) => this.getLastName(rowData),
      },
      {
        title: 'Amount',
        hidden: true,
        field: 'amount',
        render: (rowData) => (rowData.amount ? formatCurrency(rowData.amount) : '$0.00'),
        customFilterAndSearch: (term, rowData) => formatCurrency(rowData.amount).indexOf(term) != -1,
      },
      {
        title: 'Total',
        field: 'total',
        render: (rowData) => this.getTotal(rowData),
      },
      { title: 'Location', field: 'location' },
      {
        title: 'Payment Type',
        field: 'paymentType',
      },
      {
        title: 'Email-Status',
        hidden: true,
        field: 'emailSent',
        lookup: {
          true: (
            <button type="button" className="btn sentBtn">
              Sent
            </button>
          ),
          false: '',
        },
      },
      {
        title: 'Text-Status',
        hidden: true,
        field: 'textSent',
        lookup: {
          true: (
            <button type="button" className="btn sentBtn">
              Sent
            </button>
          ),
          false: '',
        },
      },
      {
        title: 'Payment-Status',
        field: 'STATUS',
        render: (rowData) => {
          const statusKey = rowData.STATUS?.toLowerCase();
          const statusConfig = PAYMENT_STATUS_BUTTON_CONFIG[statusKey];

          return statusConfig ? (
            <button type="button" className={statusConfig.className}>
              {statusConfig.text}
            </button>
          ) : (
            ''
          );
        },
        customSort: (a, b) => a.STATUS.localeCompare(b.STATUS, undefined, { sensitivity: 'base' }),
        customFilterAndSearch: (term, rowData) => {
          const statusKey = rowData.STATUS?.toLowerCase();
          const fieldToSearch = PAYMENT_STATUS_BUTTON_CONFIG[statusKey]?.text || '';
          return customFilterAndSearch(fieldToSearch, term);
        },
      },
      { title: 'Date', field: 'dateOpened', render: (date) => new Date(date.dateOpened).toLocaleDateString() },
      {
        title: 'Due Date',
        field: 'dueDate',
        render: (date) => {
          if (date.dueDate == 'Invalid Date' || date.dueDate == null) {
            return new Date(date.dateOpened).toLocaleDateString();
          } else {
            return new Date(date.dueDate).toLocaleDateString();
          }
        },
      },
    ];

    const checkLocation = () => {
      /**check location is selected or not */
      let location = JSON.parse(localStorage.getItem('selected'));
      if (location === 0 && this.state.userDetails.permissions.allLocaton) {
        this.setState({ misMatchError: ['Please select a location'] });
        return false;
      }
    };
    const visibleColumns = columns.filter((column) => !column.hidden);
    const hasSelectedInvoices = this.state.selectedRows && this.state.selectedRows.length > 0;

    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          {!this.props.userInfo.permissions && this.props.userInfo.userId === ''}
          {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            <div>
              {locationCurrent?.multiInvoicePayment && (
                <button
                  id="batch-invoices-button"
                  className="btn payMultiInvoicesBtn"
                  disabled={!hasSelectedInvoices}
                  onClick={this.handlePayMultipleInvoices}
                  title={!hasSelectedInvoices ? 'Please select a pending invoice to pay.' : ''}
                >
                  Pay Invoices
                </button>
              )}
            </div>
            <div style={{ marginTop: '37px' }}>
              <Table
                tableReload={(e) => this.tableReload(e)}
                options={{
                  exportButton: true,
                  search: true,
                }}
                columns={visibleColumns}
                permissions={
                  this.props.userInfo.userId === '' ? this.state.userData.permissions : this.props.userInfo.permissions
                }
                data={this.state.invoiceData ? this.state.invoiceData : []}
                getExportData={this.getExportData.bind(this)}
                componentState={this.state}
                title={'Invoices'}
                checkLocation={checkLocation}
                search={true}
                exports={
                  this.props.userInfo.userId === ''
                    ? this.state.userData.permissions.exportRecord
                    : this.props.userInfo.permissions.exportRecord
                }
                paging={true}
                toDateFilter={(e) => this.toDateFilter(e)}
                fromDateFilter={(e) => this.fromDateFilter(e)}
                datefilter={true}
              />
            </div>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

Invoice.propTypes = {
  userInfo: PropTypes.any,
  history: PropTypes.any,
  CustomerDetailsId: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    userInfo: state.AdminUserReducer,
    locationCurrent: state.LocationReducer,
  };
}

export default connect(mapStateToProps, { getInvoiceList, CustomerDetailsId })(withTranslation()(Invoice));
