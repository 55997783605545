import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@material-ui/core/';
import PropTypes from 'prop-types';

function DropDown({ title, children, defaultExpanded = false, disableExpand = false }) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const handleAccordionChange = (event, isExpanded) => {
    setIsExpanded(isExpanded);
  };

  return (
    <Accordion expanded={isExpanded} onChange={!disableExpand && handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

DropDown.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
  defaultExpanded: PropTypes.bool,
  disableExpand: PropTypes.bool,
};

export default DropDown;
