import React from 'react';
import { Textfield } from '../../../components/Textfield';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const AchForm = ({ errorAch, t, ach, handleChangeAch }) => {
  const handleAchChange = (event, val) => {
    console.log('ACH Form Field Changed:', event.target.value);
    handleChangeAch(event, val);
  };
  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Textfield
          required={true}
          error={errorAch.accountNameError}
          id="accountName"
          type="text"
          labels={t('AccountName')}
          value={ach.accountName}
          width={true}
          autoFocus={true}
          onChange={(e, val) => handleAchChange(e, val)}
          variant="outlined"
        />
      </Grid>
      <Grid item md={12}>
        <Textfield
          required={true}
          error={errorAch.accountNumberError}
          id="accountNumber"
          type="text"
          labels={t('AccountNumber')}
          value={ach.accountNumber}
          width={true}
          onChange={(e, val) => handleAchChange(e, val)}
          variant="outlined"
        />
      </Grid>
      <Grid item md={12}>
        <Textfield
          required={true}
          error={errorAch.routingNumberError}
          id="routingNumber"
          type="text"
          labels={t('RoutingNumber')}
          value={ach.routingNumber}
          width={true}
          onChange={(e, val) => handleAchChange(e, val)}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

AchForm.propTypes = {
  errorAch: PropTypes.shape({
    accountNameError: PropTypes.string,
    accountNumberError: PropTypes.string,
    routingNumberError: PropTypes.string,
  }),
  t: PropTypes.func,
  ach: PropTypes.shape({
    accountName: PropTypes.string,
    accountNumber: PropTypes.string,
    routingNumber: PropTypes.any,
  }),
  handleChangeAch: PropTypes.any,
};

export default AchForm;
