import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core/';
import { Textfield } from './../../components/Textfield';
import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const statesList = [
  { name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' },
];

function AddressFields({
  isZip,
  isAddressOne,
  isAddressTwo,
  isCity,
  isState,
  zip,
  addressOne,
  addressTwo,
  city,
  state,
  handleChange,
  type,
  required,
  isZipRequired,
}) {
  const [selectedState, setSelectedState] = useState(null);
  useEffect(() => {
    if (state) {
      const selectedOption = statesList.find((option) => `${option.code}` === state);
      setSelectedState(selectedOption || null);
    }
  }, [state]);
  const handleStateChange = (event, newValue) => {
    handleChange({
      target: {
        id: 'state',
        value: `${newValue.code}`,
      },
    });
    setSelectedState(newValue);
  };

  const handleChangeValidateFirst = (event, type) => {
    const specialCharRegex = /[^a-zA-Z0-9\s]/;
    if (!specialCharRegex.test(event.target.value)) {
      handleChange(event, type);
    }
  };

  const windowPath = window.location.pathname;
  const gridStyle = windowPath === '/Terminal' ? {} : { paddingTop: '16px' };

  return (
    <>
      {isAddressOne && (
        <Grid item xs={10} sm={10} md={10}>
          <Textfield
            // error={state.error.zipError}
            id="addressOne"
            type="text"
            size="medium"
            style={{ paddingRight: '14px' }}
            // onKeyDown={(e) => handleKeyDown(e, 'totalRef')}
            labels="Address One"
            value={addressOne ? addressOne : ''}
            width={true}
            onChange={(e) => handleChange(e, type)}
            variant="outlined"
            required={required}
          />
        </Grid>
      )}
      {isAddressTwo && (
        <Grid item xs={10} sm={10} md={10}>
          <Textfield
            // error={state.error.zipError}
            id="addressTwo"
            type="text"
            size="medium"
            // onKeyDown={(e) => handleKeyDown(e, 'totalRef')}
            labels="Address Two"
            style={{ paddingRight: '14px' }}
            value={addressTwo ? addressTwo : ''}
            width={true}
            onChange={(e) => handleChange(e, type)}
            variant="outlined"
            required={required}
          />
        </Grid>
      )}
      {isCity && (
        <Grid item xs={10} sm={10} md={10}>
          <Textfield
            // error={this.state.error.zipError}
            id="city"
            type="text"
            // onKeyDown={(e) => this.handleKeyDown(e, 'totalRef')}
            labels="City"
            value={city ? city : ''}
            style={{ paddingRight: '14px' }}
            width={true}
            onChange={(e) => handleChangeValidateFirst(e, type)}
            variant="outlined"
            required={required}
          />
        </Grid>
      )}
      {isState && (
        <Grid item xs={10} sm={10} md={10} style={gridStyle}>
          <Autocomplete
            data-cy="state-select"
            value={selectedState}
            onChange={handleStateChange}
            options={statesList}
            getOptionLabel={(option) => `${option.name}`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select State"
                InputLabelProps={{
                  id: 'state-select-label',
                }}
                fullWidth
                id="state"
                size="medium"
                variant="outlined"
                style={{ paddingBottom: '8px', paddingRight: '14px' }}
              />
            )}
          />
        </Grid>
      )}
      {isZip && (
        <Grid item xs={10} sm={10} md={10}>
          <Textfield
            // error={this.state.error.zipError}
            id="zip"
            type="text"
            // onKeyDown={(e) => this.handleKeyDown(e, 'totalRef')}
            size="medium"
            labels="Zip"
            value={zip ? zip : ''}
            style={{ paddingRight: '14px' }}
            width={true}
            onChange={(e) => handleChange(e, type)}
            variant="outlined"
            required={isZipRequired}
          />
        </Grid>
      )}
    </>
  );
}

AddressFields.propTypes = {
  zip: PropTypes.any,
  addressOne: PropTypes.any,
  addressTwo: PropTypes.any,
  city: PropTypes.any,
  state: PropTypes.any,
  isZip: PropTypes.any,
  isAddressOne: PropTypes.any,
  isAddressTwo: PropTypes.any,
  isCity: PropTypes.any,
  isState: PropTypes.any,
  handleChange: PropTypes.any,
  type: PropTypes.any,
  required: PropTypes.bool,
  isZipRequired: PropTypes.bool,
};

export default AddressFields;
