import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import PropTypes from 'prop-types';
import { addCustomer, editCustomer } from '../../actions/CustomerAction';
import { Buttons } from './../../components/Buttons';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import validator from '../../helpers/checkValidations';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { makePhoneNumberForDB } from '../../helpers/util';
import LoadingOverlay from 'react-loading-overlay';
import { getLocationPaymentGateway, validateHtmlMarkUp } from '../../helpers/commonFunction';
import { rules } from '../../constants/IntakeFormConstants';
import { fetchDetails } from '../../actions/AdminUserAction';
import { setupPaymentGatewayProperties } from '../../helpers/functions/paymentGateway.properties';
const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});
const initialState = {
  sideShow: true,
  isLoading: false,
  isOpen: false,
  firstName: '',
  lastName: '',
  createdBy: '',
  companyId: '',
  email: '',
  phone: '',
  isSubmit: false,
  isClose: false,
  misMatchError: [],
  successMessages: false,
  userDetails: [],
  locationId: '',
  paytraceCredentials: {},
  multipassCredentials: {},
  error: {
    firstNameError: false,
    emailError: false,
    amountError: false,
    phoneError: false,
  },
};

class AddClient extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  setPaymentGatewayProperties(paymentGatewayValue) {
    const { misMatchErrors, paytraceCredentials, multipassCredentials } =
      setupPaymentGatewayProperties(paymentGatewayValue);
    if (misMatchErrors) {
      this.setState({ misMatchErrors });
    } else {
      paymentGatewayValue === 'paytrace'
        ? this.setState({ paytraceCredentials })
        : this.setState({ multipassCredentials });
    }
  }

  componentDidMount() {
    const paymentGateway = getLocationPaymentGateway();
    console.log('paymentGateway: ', paymentGateway);
    this.setPaymentGatewayProperties(paymentGateway);

    this.setState({ isLoading: true });
    if (this.props.editData) {
      let allCustomer = this.props.info.customerInfo.data.response;
      const editCustomerId = this?.props?.editData?._id ? this.props.editData._id : this.props.editData;
      let EditCustomer = allCustomer.filter((item) => {
        if (editCustomerId === item._id) {
          return item;
        }
      });
      this.setState({ ...EditCustomer[0], editClient: true, isLoading: false });
    }

    const locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    this.setState({ locationId: locationPaydetails.id });

    let userData = JSON.parse(localStorage.getItem('user'));
    let data = fetchDetails({ id: userData._id });
    data.then((res) => {
      if (res.type === 'FETCH_SUCCESS') {
        this.setState({ userDetails: { ...res.payload.userData } }, () => {});
      }
    });
    this.setState({ createdBy: userData._id, companyId: userData.company._id, isLoading: false });
  }

  deleteUnUsedKeys() {
    const keysTodeleteFromState = ['zip', 'address'];
    for (const [key, value] of Object.entries(this.state)) {
      if (
        (this.state[key] === '' && keysTodeleteFromState.includes(key)) ||
        (typeof this.state[key] === 'undefined' && keysTodeleteFromState.includes(key)) ||
        (this.state[key] === undefined && keysTodeleteFromState.includes(key)) ||
        (this.state[key] === null && keysTodeleteFromState.includes(key)) ||
        (value === '' && keysTodeleteFromState.includes(key)) ||
        (value === 0 && keysTodeleteFromState.includes(key) && key === 'zip')
      ) {
        delete this.state[key];
      }
    }
  }

  handleChange(event, _t) {
    let successMessages = false;
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    const isHtmlMarkupValid = validateHtmlMarkUp(value);

    if (isHtmlMarkupValid) {
      return false;
    }
    if (this.state.createdBy === '') {
      let userData = JSON.parse(localStorage.getItem('user'));
      this.setState({ createdBy: userData._id, companyId: userData.company._id });
    }

    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ [id]: value, error, misMatchError, isSubmit: false, successMessages });
  }

  inserCustomer() {
    let successMessages = false;
    this.setState({ isLoading: true });
    if (this.state.editClient) {
      this.props
        .editCustomer(
          { ...this.state, phone: makePhoneNumberForDB(this.state.phone), ...this.state.paytraceCredentials },
          null,
          this.state.paytraceCredentials
        )
        .then((result) => {
          if (result !== undefined && result.type === 'CUSTOMER_SUCCESS') {
            this.setState({ isLoading: false, successMessages: true });
          }
          if (result !== undefined && result.type === 'CUSTOMER_ERROR') {
            this.setState({
              isLoading: false,
              misMatchError: this.props.info.customerInfo.data.message,
              successMessages,
            });
          }
        });
    } else {
      this.props
        .addCustomer(
          { ...this.state, phone: makePhoneNumberForDB(this.state.phone), ...this.state.paytraceCredentials },
          null,
          this.state.paytraceCredentials
        )
        .then((result) => {
          if (result !== undefined && result.type === 'CUSTOMER_SUCCESS') {
            this.setState({ ...initialState, isLoading: false, successMessages: true });
          }
          if (result !== undefined && result.type === 'CUSTOMER_ERROR') {
            this.setState({
              isLoading: false,
              misMatchError: this.props.info.customerInfo.data.message,
              successMessages,
            });
          }
        });
    }
  }

  handleSubmit(_e, _t) {
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;

    this.setState({ isSubmit: true });

    this.deleteUnUsedKeys();
    /**check location is selected or not */
    let location = JSON.parse(localStorage.getItem('selected'));
    if (location === 0 && this.state.userDetails.permissions.allLocaton) {
      console.log('location', location);
      this.setState({ misMatchError: ['Please select a location'] });
      return false;
    }

    Object.keys(state).forEach((key) => {
      if (validator(key, state[key])) {
        return validator(key, state[key])
          ? ((error[key + 'Error'] = true), (errorFlag = true))
          : (error[key + 'Error'] = false);
      } else {
        return state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')
          ? ((error[key + 'Error'] = true), (errorFlag = true))
          : (error[key + 'Error'] = false);
      }
    });

    if (errorFlag) {
      this.setState({ misMatchError, logoError, error, successMessages });
      return false;
    } else {
      this.inserCustomer();
    }
  }

  render() {
    const { classes, t } = this.props;
    return (
      <>
        <LoadingOverlay
          // className={classes.tpTableFullW}
          active={this.state.isLoading}
          spinner
          text="Fetching your response ..."
        >
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                {this.state.editClient ? 'Edit Customer' : 'Customer'}
              </Typography>
            </Toolbar>
          </AppBar>
          {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
          {this.state.successMessages ? <SuccessMessage successes={[this.props.info.customerInfo.data.message]} /> : ''}

          <form className={classes.form} noValidate ref={this.myRef}>
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                <Textfield
                  required={true}
                  error={this.state.error.firstNameError}
                  helperText={
                    this.state.error.firstNameError
                      ? rules.firstName.messages.blankspace
                      : this.state.error.firstNameError
                      ? rules.firstName.messages.stringEmptySpaces
                      : this.state.error.firstNameError
                      ? rules.firstName.messages.characterlimit
                      : ''
                  }
                  id="firstName"
                  type="text"
                  labels={t('FirstName')}
                  value={this.state.firstName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>
              <Grid container item xs={12}>
                <Textfield
                  required={true}
                  error={this.state.error.lastNameError}
                  helperText={
                    this.state.error.firstNameError
                      ? rules.firstName.messages.blankspace
                      : this.state.error.firstNameError
                      ? rules.firstName.messages.stringEmptySpaces
                      : this.state.error.firstNameError
                      ? rules.firstName.messages.characterlimit
                      : ''
                  }
                  id="lastName"
                  type="text"
                  labels={t('LastName')}
                  value={this.state.lastName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>
              <Grid container item xs={12}>
                <Textfield
                  required={true}
                  error={this.state.error.emailError}
                  id="email"
                  type="text"
                  labels={t('Email')}
                  value={this.state.email}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>
              <Grid container item xs={12}>
                <Textfield
                  required={true}
                  error={this.state.error.phoneError}
                  id="phone"
                  type="text"
                  labels={t('Phone')}
                  value={this.state.phone}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>
            </Grid>
            <Buttons
              id="signUp"
              type="button"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={(e) => this.handleSubmit(e, t)}
              text={t('Save')}
            />
          </form>
        </LoadingOverlay>
      </>
    );
  }
}

AddClient.propTypes = {
  editData: PropTypes.string,
  info: PropTypes.any,
  editCustomer: PropTypes.func,
  addCustomer: PropTypes.func,
  classes: PropTypes.any,
  t: PropTypes.any,
};

const ApplyingStyles = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return <AddClient theme={theme} classes={classes} {...props} />;
};

function mapStateToProps(state) {
  return {
    info: state.CustomerReducer,
  };
}

export default connect(mapStateToProps, { addCustomer, editCustomer })(withTranslation()(withRouter(ApplyingStyles)));
