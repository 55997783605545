import React, { Component } from 'react';
import { changeFocus } from '../../helpers/changeFocusCard';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import PrintIcon from '@material-ui/icons/Print';
import SaveIcon from '@material-ui/icons/Save';
import Icon from '@material-ui/core/Icon';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { checkCreditOrDebit } from '../../helpers/PostDataApi';
import TsepCard from '../Terminal/components/TsepCard';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import autoFillMultipassFields from '../../helpers/functions/autoFillCardFields';
import config from '../../constants/config';
import { ALL_LOCATION_ERROR } from './../../constants/message';
import InputAdornment from '@material-ui/core/InputAdornment';
import { validateZipCode } from '../../helpers/functions/validateZipCode';
import { setupPaymentGatewayProperties } from '../../helpers/functions/paymentGateway.properties.js';
import { Textfield } from './../../components/Textfield';
import { fetchDetails, settingDataByCompanyId, UserDetailById } from '../../actions/AdminUserAction';
import { getInvoiceNumber, getTransactionListTodayData, getCsrfToken } from '../../actions/PaymentAction';
import { addInvoice, uploadInvoiceFile, textInvoice, updatePendingInvoice } from '../../actions/RequestFormAction';
import { customerDetailsByCompany, editCustomer } from '../../actions/CustomerAction';
import { productList } from '../../actions/ProductAction';
import { Buttons } from './../../components/Buttons';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { CloseableAlerts } from './../../components/Message/CloseableAlerts';
import LoadingOverlay from 'react-loading-overlay';
import validator from '../../helpers/checkValidations';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { setTsepAuth } from '../../actions/LocationAction';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Link from '@material-ui/core/Link';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { DialogBox } from './../../components/DialogBox/DialogBox';
import AddClient from './AddClient';
import TypableSelect from './../../helpers/TypableSelect';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import { initialAddressSettings, initialAddressState } from '../../helpers/addressSettingsState';
import CardContent from '@material-ui/core/CardContent';
import { CardActions, TextField } from '@material-ui/core';
import MaskedInput from 'react-maskedinput';
import { Checkbox } from '@material-ui/core';
import classesI from './Invoice.module.scss';
import ProductTable from './ProductTable';
import {
  formatCurrency,
  getLocationPaymentGateway,
  getRoundedExacts,
  maskCardNumber,
} from '../../helpers/commonFunction';
import AddIcon from '@material-ui/icons/Add';
import { DropzoneArea } from 'material-ui-dropzone';
import { getInvoiceState } from './Common.js';
import { validateNotesMarkUp } from '../../helpers/commonFunction';
import Stack from '@mui/material/Stack';
import { rules } from '../../constants/IntakeFormConstants';
import { CurrencyFormatterUS } from '../../helpers/currencyFormatters';
import { makePhoneNumberForDB } from '../../helpers/util';
import { Button } from '@mui/material';
import Switch from '../Settings/components/Switch';
import EditInvoiceConfirmationModal from './EditInvoiceConfirmationModal';
import { whichLocationSelected } from '../../helpers/getLocationFromLocalStorage';
import PrintInvoice from '../../helpers/PrintInvoice';
import AddressFieldsDropDown from '../../components/AddressFieldsDropDown/AddressFieldsDropDown';
import { checkAddressEnabled } from '../../helpers/functions/addressFieldsEnabled';
import { getFeeCalculations, getCustomFeeOne, getSurchargeFee } from '../../helpers/functions/feeCalculations';
import { handleAddressSettingError } from '../../helpers/functions/validateAddressfields';
import { isDueDatePassed } from '../../helpers/InvoiceHelper';
import { validateCityInAddress } from '../../helpers/functions/addressFieldsEnabled';

const mapToFormat = (values) => {
  return values.map((Obj) => ({
    value: Obj._id,
    label: `${Obj.firstName} ${Obj.lastName ?? ''}`,
  }));
};

const getValidationStyle = (cond) => {
  if (cond) {
    return { width: '100%', border: '2px solid red', padding: '10px 0px' };
  } else {
    return { width: '100%' };
  }
};

function TabPanel(props) {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}
const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  tableWrapper: {
    width: '100%',
    // overflow: 'auto',
  },
  formControl: {
    marginTop: '2rem',
  },
  '@media (min-width: 800px) and (max-width: 1023px)': {
    tableWrapper: {
      overflowX: 'scroll !important',
    },
  },
});
const getName = (data) => `${data[0].firstName} ${data[0].lastName || ''}`;

class AddInvoice extends Component {
  constructor(props) {
    super(props);
    this.handleCardChange = this.handleCardChange.bind(this);
    this.backToMaskCard = this.backToMaskCard.bind(this);
    this.handleChangeCheckboxUpdate = this.handleChangeCheckboxUpdate.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleSubmitAddNewCard = this.handleSubmitAddNewCard.bind(this);
    this.handleSubmitAddCardTsep = this.handleSubmitAddCardTsep.bind(this);
    this.handleTimeout = this.handleTimeout.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      FRONT_END: 'Invoice',
      isDisableInvoice: true,
      redirect: false,
      isPaid: false,
      redirectTransaction: false,
      cardPanelShow: false,
      americanExpress: false,
      isLoading: false,
      isEditClient: false,
      isOpen: false,
      multipassCredentials: {},
      amount: '',
      total: 0,
      cashTotal: 0,
      tax: 0,
      svcFee: 0,
      lateFee: 0,
      companyLogoPath: null,
      taxType: '%',
      svcType: '%',
      invoice: '',
      createdBy: '',
      linkSendDate: new Date(),
      companyId: '',
      discountType: 1,
      cardWalletID: null,
      tsepFieldError: null,
      tsepFieldSuccess: false,
      tsepSuccessResponse: null,
      tsepAuth: false,
      discount: 0,
      isSubmit: false,
      addNewCard: false,
      saveDraft: false,
      isClose: false,
      dueDate: new Date(),
      invoiceDate: new Date(),
      selectedCustomer: {},
      misMatchError: [],
      CoustmerData: [],
      ProductData: [],
      products: [],
      invoiceFile: [],
      customInvoice: true,
      CardData: [],
      TransactionData: [],
      productFields: [{ name: '', description: '', amount: '', id: 0 }],
      successMessages: false,
      userDetails: JSON.parse(localStorage.getItem('user')),
      discountErr: false,
      updateCard: false,
      tabValue: 0,
      indexValue: 0,
      customerId: null,
      settings: {},
      errorMessage: {},
      paytraceCredentials: {},
      addCustomInvoiceMode: false,
      openEditConfirmationModal: false,
      updatedInvoice: false,
      name: '',
      mask_number: '',
      shouldInvoiceUpdate: false,
      PO: '',
      creditOrDebit: null,
      dualFeeEnabled: false,
      cash: false,
      card: {
        cardHolderName: '',
        cardNumber: '_______________',
        cardExpiryDate: '__/____',
        cardCsc: '___',
        customerToken: false,
        customerTokenUpdate: false,
      },
      csrf: { 'csrf-token': '', 'csrf-secret': '' },
      userData: { permissions: {} },
      feeSettings: {
        InvoiceDiscount: false,
        InvoiceTax: false,
        virtualTerminalSettings: {},
        invoiceSettings: {},
        DualFeeEnabled: false,
      },
      isSizeMobile: window.innerWidth < 768,
      error: {
        firstNameError: false,
        emailError: false,
        amountError: false,
        phoneError: false,
        svcFeeError: false,
        lateFeeError: false,
      },
      limit: {
        perTransactionLimit: false,
        dailyTransactionLimit: false,
        perRefundLimit: false,
        dailyRefundLimit: false,
      },
      ...initialAddressSettings,
      ...initialAddressState,
      errorCard: {
        cardNumber: false,
        cardHolderName: false,
        cardExpiryDate: false,
        cardCsc: false,
      },
      invoiceLable: 'Invoice',
      lateFeeApplies: false,
      isLateFeeApplicable: false,
      isMobile: false,
      isStateSVCApplied: false,
      stateSVCAppliedRate: 0.0,
      notApplied: false,
      isPaymentButtonDisabled: false,
      binCodesResponse: {},
    };
    this.paymentRef = React.createRef();
    this.cardRef = React.createRef();
    this.msgRef = React.createRef();
    this.expiryDateRef = React.createRef();
    this.cscCardRef = React.createRef();
    this.editMode = this.props?.location?.state?.rowData;
  }

  UNSAFE_componentWillMount() {
    //redirect to intake form for road assitence client
    let locationsPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    if (locationsPaydetails?.customTowingBuild) {
      this.props.history.push('/RequestForm');
    }
  }

  checkAllLocationSelected() {
    const selectedLocation = JSON.parse(localStorage.getItem('selected'));
    const userDetails = JSON.parse(localStorage.getItem('user'));
    if (selectedLocation === 0 && userDetails.permissions.allLocaton) {
      this.setState({ allLocationError: true });
      return true;
    } else {
      this.setState({ allLocationError: false });
      return false;
    }
  }

  componentDidMount() {
    const paymentGateway = getLocationPaymentGateway();
    this.setPaymentGatewayProperties(paymentGateway);
    this.fetchAddressInfo();
    this.setInvoiceDetails();
    this.getCsrfTokens();
    this.checkMobile();
    this.getCustomerList();
    this.checkUserPermissions();
    this.getTransactionsListDailyData();
    this.getProductList();
    this.setUserData();
    this.checkIfInvoiceDisabled();
    this.injectEventListeners();
    this.handleResize();
    this.checkAllLocationError();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.addEventListener('resize', this.handleResize);
    }
  }

  setInvoiceDetails() {
    if (this.props?.location?.state?.rowData) {
      const invoiceData = this.props?.location?.state?.rowData;
      this.setInvoiceData(invoiceData);
    } else {
      this.getUniqueInvoiceNumber();
    }
  }

  checkIfInvoiceDisabled() {
    let userData = JSON.parse(localStorage.getItem('user'));
    const isDisableInvoice = userData.company._id === '5f62611e3b68d50e56e1000b' ? false : true;
    this.setState({ isDisableInvoice: isDisableInvoice });
  }

  checkUserPermissions() {
    let user = JSON.parse(localStorage.getItem('user'));
    let data = fetchDetails({ id: user._id });
    data.then((res) => {
      if (res.type === 'FETCH_SUCCESS') {
        this.setState({ userData: { ...res.payload.userData } }, () => {
          if (this.state.userData.permissions && !this.state.userData.permissions.createInvoice) {
            alert('Access denied!');
            this.props.history.push('/Dashboard');
          }
        });
      }
    });
  }

  setUserData() {
    let userData = JSON.parse(localStorage.getItem('user'));
    this.setState({ createdBy: userData._id, companyId: userData.company._id, isLoading: false }, () => {
      this.settingDataByCompanyId();
    });
  }

  injectEventListeners() {
    document.addEventListener('tsepEventUpdate', this.handleTsepEventUpdated);
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  checkAllLocationError() {
    let misMatchErr = [];
    if (this.checkAllLocationSelected()) {
      misMatchErr.push(ALL_LOCATION_ERROR);
      this.setState({ misMatchErrors: misMatchErr, allLocationError: true });
    } else {
      this.setState({ allLocationError: false });
    }
  }

  setPaymentGatewayProperties(paymentGatewayValue) {
    const { misMatchErrors, paytraceCredentials, multipassCredentials } =
      setupPaymentGatewayProperties(paymentGatewayValue);
    if (misMatchErrors) {
      this.setState({ misMatchErrors });
    } else {
      paymentGatewayValue === 'paytrace'
        ? this.setState({ paytraceCredentials })
        : this.setState({ multipassCredentials });
    }
  }

  handleResize = () => {
    const isMobile = window.innerWidth < 768;
    this.setState({ isMobile });
  };

  getLateFee() {
    if (this.state.isLateFeeApplicable) {
      return this.state.lateFee;
    } else {
      return 0;
    }
  }

  checkMobile() {
    const mobileSize = 850; // Set the threshold for mobile size (adjust as needed)
    this.setState({ isSizeMobile: window.innerWidth < mobileSize });
  }

  UNSAFE_componentWillReceiveProps() {
    /**
     * Following is not needed since we set the data coming from parent in componentDidMount. See setInvoiceDetails being called at Line #219.
     * Data from parent should only be referred on the first mount. Subsequent refreshes should rely on API data
     * Keeping the code commented for future reference in case there is side effect. Will be removed in future release.
     *
     * if (nextProps?.location?.state?.rowData) {
     *     const invoiceData = nextProps?.location?.state?.rowData;
     *     this.setInvoiceData(invoiceData);
     * }
     */

    const currentLocation = whichLocationSelected();
    const jobIdCustomField = currentLocation?.customFields?.find((field) => field?.enabled);
    if (jobIdCustomField && currentLocation?.customFieldsEnabled) {
      this.setState({
        invoiceLable: jobIdCustomField?.value,
        isDisableInvoice: jobIdCustomField?.autoGenerate,
        isInvoiceAutoGenerated: jobIdCustomField?.autoGenerate,
        newJobId: jobIdCustomField.autoGenerate
          ? this.state.invoice
          : this.state.newJobId
          ? this.state.newJobId
          : this.state.jobId || this.editMode
          ? this.state.invoice
          : '',
      });
    }
  }

  handleTsepEventUpdated = (event) => {
    const eventTsep = event.detail;
    if (eventTsep.eventType === 'FieldValidationErrorEvent') {
      this.setState({ tsepFieldError: eventTsep.event.message });
    } else if (eventTsep.eventType === 'TokenEvent' && eventTsep.event.status === 'PASS') {
      this.setState({
        tsepSuccessResponse: { ...eventTsep.event },
        tsepFieldSuccess: true,
        tsepFieldError: null,
      });
      // Handle other cases if needed
    } else if (eventTsep.eventType === 'TokenEvent' && eventTsep.event.status === 'FAILURE') {
      this.setState({ tsepFieldError: eventTsep.event.message });
    } else if (eventTsep.eventType === 'FocusOutEvent' && eventTsep.event.fieldName === 'tsep-cardNum') {
      this.setTsepCardPaymentType();
    } else if (
      eventTsep.eventType === 'ErrorEvent' &&
      eventTsep.event.status === 'FAIL' &&
      eventTsep.event.responseCode === 'TSEPERR911'
    ) {
      this.props.setTsepAuth(false);
    }
    console.log('Tsep catched event:', eventTsep);

    // Update your component state with the new card number
    // You might want to use this.setState for this
  };

  setTsepCardPaymentType = () => {
    const cardNumberElement = document.getElementById('tsep-cardNum');
    if (cardNumberElement) {
      const cardNumberValue = cardNumberElement?.value.replace(/\s/g, '');
      this.checkCardPaymentType(cardNumberValue);
    }
  };

  getCardType = (cardInfo) => {
    if (cardInfo.type === 'CHARGE CARD' || cardInfo.card === 'AMERICAN EXPRESS') {
      return 'CREDIT';
    }
    return cardInfo.type;
  };

  checkCardPaymentType = (cardInititals) => {
    checkCreditOrDebit(config.BIN_CHECKER_URL, config.BIN_API_KEY, cardInititals.substring(0, 6))
      .then((result) => {
        this.setState(
          {
            cardType: result.data.card ? result.data.card : null,
            creditOrDebit: result.data.type ? this.getCardType(result.data) : null,
            binCodesResponse: result.data,
          },
          () => {
            if (this.state.amount) {
              this.setAmount(this.state.amount);
            }
          }
        );
      })
      .catch((error) => {
        if (this.state.creditOrDebit !== null) {
          this.setState({ creditOrDebit: null, binCodesResponse: { error: JSON.stringify(error) } }, () => {
            if (this.state.amount) {
              this.setAmount(this.state.amount);
            }
          });
        } else {
          this.setState({ binCodesResponse: { error: JSON.stringify(error) } });
        }
      });
  };
  setInvoiceData(invoiceInfo) {
    const isLateFeeApplicable = isDueDatePassed(
      invoiceInfo,
      this.state.feeSettings?.invoiceSettings?.CustomFeeOneDueDays
    );
    this.setState({ isLateFeeApplicable: isLateFeeApplicable });
    this.setState(
      {
        ...invoiceInfo,
        ...(invoiceInfo.phone && { phone: makePhoneNumberForDB(invoiceInfo.phone.replace(/[a-z:()._-\s]/gi, '')) }),
        invoiceDate: invoiceInfo.dateOpened,
        selectedCustomer: invoiceInfo.customerId,
        invoice: invoiceInfo.invoice,
        customInvoice: invoiceInfo.customInvoice,
        addCustomInvoiceMode: invoiceInfo.customInvoice ? false : true,
        name: `${invoiceInfo.firstName} ${invoiceInfo?.lastName ? invoiceInfo.lastName : ''}`,
        shippingAddress: invoiceInfo.shippingAddress ? invoiceInfo.shippingAddress : this.state.billingAddress,
        billingAddress: invoiceInfo.billingAddress ? invoiceInfo.billingAddress : this.state.billingAddress,
      },
      () => {
        if (this.state.amount) {
          this.setAmount(this.state?.amount);
        }
      }
    );
  }

  getCompanyLogoPath() {
    let companyLogoPath = this.props.PAYTRACE_AWS_PATH + 'titanium-logo.png';
    let locationsPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    if (locationsPaydetails && locationsPaydetails?.image !== 'null') {
      companyLogoPath = this.props.PAYTRACE_AWS_PATH + locationsPaydetails?.image;
    }
    return companyLogoPath;
  }
  settingDataByCompanyId() {
    this.setState({ isLoading: true });
    let locationId = '';
    if (localStorage.getItem('locationArrIds') !== null) {
      locationId =
        JSON.parse(localStorage.getItem('locationArrIds')).length > 1
          ? null
          : JSON.parse(localStorage.getItem('locationArrIds'))[0];
    }
    let param = { id: this.state.companyId, locationId: locationId };
    this.props.settingDataByCompanyId(param).then((res) => {
      if (res.payload.error) {
        //svc disabled
        this.setState(
          {
            feeSettings: {
              InvoiceDiscount: false,
              InvoiceTax: false,
              DiscountFeeName: 'SVC',
              virtualTerminalSettings: {},
              invoiceSettings: {},
              DualFeeEnabled: false,
            },
          },
          () => {}
        );
      } else {
        this.setState(
          {
            feeSettings: {
              ...(res.payload.userData.data ? res.payload.userData.data.response : {}),
              virtualTerminalSettings: {
                ...(res.payload.userData.data ? res.payload.userData.data.response.virtualTerminalSettings : {}),
              },
              invoiceSettings: {
                ...(res.payload.userData.data ? res.payload.userData.data.response.invoiceSettings : {}),
              },
            },
          },
          () => {
            if (this.state.amount) {
              this.setAmount(this.state.amount);
            }

            const isLateFeeApplicable = isDueDatePassed(
              this.state,
              this.state.feeSettings?.invoiceSettings?.CustomFeeOneDueDays
            );
            this.setState({ isLateFeeApplicable: isLateFeeApplicable });
            this.setState({
              dualFeeEnabled: this.state.feeSettings.DualFeeEnabled,
            });
            this.setState({ isLoading: false });
          }
        );
      }
    });
  }
  getCsrfTokens() {
    this.props.getCsrfToken().then((res) => {
      this.setState({ csrf: { ...res.payload.paymentData.data } });
    });
  }
  getTransactionsListDailyData() {
    this.setState({ isLoading: true });
    let userDataa = JSON.parse(localStorage.getItem('user'));
    let dataObj = {
      date: new Date(),
      companyId: userDataa.company ? userDataa.company._id : '',
      role: userDataa.role,
      userId: userDataa._id,
    };
    this.props.getTransactionListTodayData(dataObj).then((response) => {
      if (response) {
        this.setState({
          TransactionData: response.payload.paymentData.data.response,
          isLoading: false,
        });
      }
    });
  }

  handleCardChange(event) {
    let error = {};
    let { id, value } = event.target;
    if (this.state.customerId == null || this.state.customerId === undefined) {
      alert('Select a customer first.');
      document.getElementById('cardNumber').value = '___________';
    } else {
      validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
      if (value.length === 16 && value[value.length - 1] === '_' && id === 'cardNumber' && value[0] === '3') {
        value = value.slice(0, -1);
      }
      if (id === 'cardExpiryDate') {
        const month = value.split('/')[0];
        if (month.length == 2 && (parseInt(month) <= 0 || parseInt(month) > 12)) {
          error[id + 'Error'] = true;
        }
      }
      this.updateCardState(id, value, error);
      changeFocus(id, value, this.expiryDateRef.current, this.cscCardRef.current);
    }
  }
  setCustomJobId(e) {
    if (e.target.value.match(/[%<>\\$'",()|/@#$^&*+=!~`;:?.{}[\]]/)) {
      this.setState({ customFieldInputError: 'Special characters not allowed' });
    } else {
      this.setState({
        newJobId: e.target.value,
        invoice: e.target.value,
        customFieldInputError: null,
      });
    }
  }

  setAmount(amount) {
    const feeComponents = getFeeCalculations(
      this.state?.feeSettings,
      amount,
      this.state.creditOrDebit,
      this.state?.billingAddress.state
    );
    // Set calculations based on fee settings here
    this.setState(
      {
        // amount: data[0].amount,
        total: feeComponents.totalAmount,
        tax: feeComponents.salesTaxFee,
        svcFee: (Math.round(parseFloat(feeComponents.svcFee) * 100) / 100).toFixed(2),
        salesTaxTotal: feeComponents.salesTaxTotal,
        lateFee: parseFloat(getCustomFeeOne(this.state?.feeSettings, amount).toFixed(2)),
        isStateSVCApplied: feeComponents.isStateSVCApplied,
        stateSVCAppliedRate: feeComponents.stateSVCAppliedRate,
        notApplied: feeComponents.notApplied,
      },
      () => {
        // Handle lateFee calculation based on CustomFeeOneEnabled here
        if (this.getLateFee() !== 0) {
          const totalFee = this.state.total + getCustomFeeOne(this.state?.feeSettings, amount);
          this.setState({ total: totalFee });
        }
        if (this.state.feeSettings?.DualFeeEnabled) {
          const lateFee = getCustomFeeOne(this.state?.feeSettings, amount);
          const cashTotal = parseFloat(
            (parseFloat(amount) + (this.state.salesTaxTotal || 0) + this.getLateFee()).toFixed(2)
          );
          const cardAmount = parseFloat(this.state?.amount) + parseFloat(this.state.svcFee || 0);
          this.setState({ lateFee, cashTotal, cardAmount });
        }
        if (this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurcharge) {
          this.setState({
            surcharge: getRoundedExacts(
              getSurchargeFee(
                this.state?.feeSettings,
                parseFloat(amount),
                this.state.creditOrDebit,
                this.state?.billingAddress.state
              )
            ),
          });
        }
      }
    );
  }

  handleChangeAmount(event, value) {
    let error = {};
    let misMatchError = [];
    let { id } = event.target;
    let errorKey = id + 'Error';
    error[errorKey] = validator(id, value);
    this.setState(
      {
        [id]: value,
        error,
        misMatchError,
        isSubmit: false,
        successMessages: false,
        errorCard: { ...this.state.errorCard, [id]: false },
      },
      () => {
        if (
          !this.state.feeSettings?.virtualTerminalSettings?.InvoiceDiscount &&
          !this.state.feeSettings?.virtualTerminalSettings?.InvoiceTax &&
          id === 'amount'
        ) {
          this.setAmount(value);
        } else if (id === 'amount' && value !== '0') {
          this.setAmount(value);
        }
      }
    );
  }
  handleChange(event, _t) {
    let error = {};
    let errorMessage = {};
    let misMatchError = [];
    let { id, value } = event.target;
    // Adding error messages in public and private notes
    if (id === 'publicNotes' || id === 'privateNotes') {
      if (validateNotesMarkUp(value)) {
        error[id + 'Error'] = true;
        errorMessage[id + 'ErrorMessage'] = rules.notes.messages.invalidCharacters;
      }
      if (value.length > rules.notes.rule.notesCharacterLimit) {
        error[id + 'Error'] = true;
        errorMessage[id + 'ErrorMessage'] = rules.notes.messages.characterLimit;
      }
      if (error[id + 'Error'] === true) {
        this.setState({ error, errorMessage });
        return false;
      } else {
        error = {};
        errorMessage = {};
        this.setState({ error, errorMessage });
      }
    }

    let errorKey = id + 'Error';
    error[errorKey] = validator(id, value);
    this.setState(
      {
        [id]: value,
        error,
        misMatchError,
        isSubmit: false,
        successMessages: false,
        errorCard: { ...this.state.errorCard, [id]: false },
      },
      () => {
        if (
          !this.state.feeSettings?.virtualTerminalSettings?.InvoiceDiscount &&
          !this.state.feeSettings?.virtualTerminalSettings?.InvoiceTax &&
          id === 'amount'
        ) {
          this.setAmount(value);
        } else if (id === 'amount' && value !== '0') {
          this.setAmount(value);
        }
      }
    );
  }

  handleCardSubmit(e, _t) {
    e.preventDefault();
  }

  checkMaxTransactionLimits(val) {
    let maxTransactionLimits = this.props.UserDetail.permissions.perTransactionLimit
      ? this.props.UserDetail.permissions.perTransactionLimit
      : 0;
    let maxDailyTransactionLimits = this.props.UserDetail.permissions.dailyTransactionLimit
      ? this.props.UserDetail.permissions.dailyTransactionLimit
      : 0;
    let maxDailyAmount = this.state.TransactionData.reduce((item, curr) => {
      return item + curr.AMOUNT;
    }, 0);
    if (val > maxDailyTransactionLimits - maxDailyAmount && maxDailyAmount > 0 && maxDailyTransactionLimits > 0) {
      this.setState({ limit: { ...this.state.limit, dailyTransactionLimit: true } }, () => {
        window.scrollTo(0, 0, 0);
        setTimeout(() => {
          this.setState({
            limit: { ...this.state.limit, dailyTransactionLimit: false },
          });
        }, 5000);
        return false;
      });
    } else if (val > maxTransactionLimits && maxTransactionLimits > 0) {
      this.setState({ limit: { ...this.state.limit, perTransactionLimit: true } }, () => {
        window.scrollTo(0, 0, 0);
        setTimeout(() => {
          this.setState({
            limit: { ...this.state.limit, perTransactionLimit: false },
          });
        }, 5000);
        return false;
      });
    } else {
      return true;
    }
  }

  handleSubmit(e, cashOption = false, _shouldInvoiceUpdate = false) {
    e.preventDefault();
    let logoError = [];
    let successMessages = false;
    // debugger;
    const paymentGateway = getLocationPaymentGateway();
    this.setState({ isSubmit: true, isPaymentButtonDisabled: true });

    if (this.state.openEditConfirmationModal && cashOption) {
      this.setState({ shouldInvoiceUpdate: true });
    }

    if (this.state.isPaid || (this.checkMaxTransactionLimits(this.state.total) && this.checkCardValues(this.state))) {
      if (this.getNameOrMaskNumber()) {
        delete this.state.mask_number;
      }

      if (cashOption) {
        this.setState({
          errorCard: {
            ...this.state.errorCard,
            cardExpiryDate: false,
            cardNumber: false,
            cardCsc: false,
          },
        });
      }
      const { error, errorFlag, misMatchError } = this.validateFormInputs(false);
      if (errorFlag) {
        this.setState({ misMatchError, logoError, error, successMessages });
        return false;
      } else if (paymentGateway === 'paytrace') {
        this.initiatePaytraceAddInvoice(cashOption, misMatchError, successMessages, errorFlag, logoError, error);
      } else {
        this.initiateMultipassAddInvoice(cashOption, misMatchError, successMessages, errorFlag, logoError, error);
      }
    }
  }

  initiateMultipassAddInvoice(cashOption, misMatchError, successMessages, _errorFlag, _logoError, _error) {
    const invoiceData = getInvoiceState(this.state);
    const tsepResponse = this.state?.tsepSuccessResponse;
    const walletId = this.state.cardWalletID;
    const locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    this.setState(
      {
        card: { ...this.state.card, cardNumber: '', cardCsc: '', cardExpiryDate: tsepResponse?.expirationDate },
        isLoading: true,
        encrypted_number: !walletId && tsepResponse ? tsepResponse.tsepToken : null,
        encrypted_csc: !walletId && tsepResponse ? tsepResponse.cvv2 : null,
        invoiceData,
        locationId: locationPaydetails.id,
        svcFee: cashOption ? 0 : this.state.svcFee,
        total: cashOption ? this.state.cashTotal : this.state.total,
        tax: cashOption ? this.state.salesTaxTotal : this.state.tax,
        cash: cashOption,
        isPaid: cashOption,
        creditOrDebit: this.state.creditOrDebit,
        surcharge: this.state?.surcharge,
        ...(this.state.selectedCustomer && {
          customerDetails: { ...this.state.selectedCustomer },
        }),
      },
      () => {
        this.sendAddInvoiceRequest(this.state.multipassCredentials, misMatchError, successMessages);
      }
    );
  }

  initiatePaytraceAddInvoice(cashOption, misMatchError, successMessages, errorFlag, logoError, error) {
    if (!Object.prototype.hasOwnProperty.call(this.state.paytraceCredentials, 'password')) {
      window.scrollTo(0, 0, 0);

      let msg = 'Select the payment location.';
      misMatchError.push(msg);
      this.setState({ misMatchError, logoError, error, successMessages });
      return false;
    }
    const invoiceData = getInvoiceState(this.state);
    window.paytrace.submitEncrypted('#DemoFormInvoice');

    const locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    this.setState(
      {
        card: { ...this.state.card },
        isLoading: true,
        encrypted_number: localStorage.getItem('0'),
        encrypted_csc: localStorage.getItem('1'),
        invoiceData,
        locationId: locationPaydetails.id,
        svcFee: cashOption ? 0 : this.state.svcFee,
        total: cashOption ? this.state.cashTotal : this.state.total,
        tax: cashOption ? this.state.salesTaxTotal : this.state.tax,
        cash: cashOption,
        isPaid: cashOption,
        creditOrDebit: this.state.creditOrDebit,
        surcharge: this.state?.surcharge,
        mask_number: maskCardNumber(this.state.card.cardNumber),
        ...(this.state.selectedCustomer && {
          customerDetails: { ...this.state.selectedCustomer },
        }),
      },
      () => {
        localStorage.removeItem('0');
        localStorage.removeItem('1');
        this.sendAddInvoiceRequest(this.state.paytraceCredentials, misMatchError, successMessages);
      }
    );
  }

  sendAddInvoiceRequest(credentials, misMatchError, successMessages) {
    this.props.addInvoice(this.state, this.state.csrf, credentials).then((result) => {
      window.scrollTo(0, 0);
      if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
        if (this.state.invoiceFile.length > 0) {
          this.uploadInvoiceFiles().then((_resp) => {
            this.successTransaction(misMatchError);
          });
        } else {
          this.successTransaction(misMatchError);
        }
      }
      if (result !== undefined && result.type === 'INVOICE_ERROR') {
        if (result.payload.error.data) {
          let message = result.payload.error.data.message;
          misMatchError.push(message);
          if (result.payload.error.data.success === false) {
            this.setState({
              isLoading: false,
              misMatchError,
              successMessages,
              ...(this.state.addNewCard && { mask_number: '', name: '' }),
            });
          }
          if (result.payload.error.data.response && result.payload.error.data.response.length > 0) {
            let responseErrors = JSON.parse(result.payload.error.data.response);
            if (responseErrors.response_code === 1) {
              for (let key in responseErrors.errors) {
                let i = 0;
                misMatchError.push(responseErrors.errors[key][i] + '\n');
              }
              // this.setState({ isLoading: false, misMatchError, successMessages });
            }
            if (responseErrors.response_code === 1001) {
              misMatchError.push(responseErrors.status_message);
            }
          }
          this.setState({
            isLoading: false,
            misMatchError,
            successMessages,
            ...(this.state.addNewCard && { mask_number: '', name: '' }),
          });
        }
      }
    });
  }

  successTransaction(misMatchError) {
    this.setState(
      {
        isLoading: false,
        successMessages: true,
        misMatchError,
        successMessagesText: 'Payment Added Successfully!',
      },
      () => {
        setTimeout(() => {
          this.setState({ successMessages: false });
          if (this.state.isPaid) {
            this.setState({ redirect: true });
          } else {
            this.setState({ redirectTransaction: true });
          }
        }, 2000);
      }
    );
  }

  getNameOrMaskNumber() {
    return this.state.name === '' || !this.state.mask_number;
  }

  getInvoiceSuccess(result) {
    return result !== undefined && result.type === 'INVOICE_SUCCESS';
  }

  getInvoiceError(result) {
    return result !== undefined && result.type === 'INVOICE_ERROR';
  }

  getResultPayload(result) {
    return (
      result.payload.error.data &&
      (result.payload.error.data.statusCode === 403 ||
        result.payload.error.data.statusCode === 401 ||
        result.payload.error.data.statusCode === 409)
    );
  }

  getInvoiceInfo() {
    return (
      this.props.info.invoiceInfo.data?.response &&
      this.props.info.invoiceInfo.data.response.length > 0 &&
      this.props.info.invoiceInfo.data.statusCode !== 401 &&
      this.props.info.invoiceInfo.data.statusCode !== 500
    );
  }

  getResponseError(responseErrors, misMatchError, successMessages) {
    if (responseErrors.response_code === 1) {
      for (let key in responseErrors.errors) {
        let i = 0;
        misMatchError.push(responseErrors.errors[key][i] + '\n');
      }
      this.setState({
        isLoading: false,
        misMatchError,
        successMessages,
      });
    } else {
      misMatchError.push(responseErrors.approval_message);
    }
  }

  updateCardState(id, value, error) {
    let misMatchError = [];
    this.setState(
      {
        card: { ...this.state.card, [id]: value },
        errorCard: { ...this.state.errorCard, [id]: error[id + 'Error'] },
        error,
        misMatchError,
        isSubmit: false,
      },
      () => {
        let firstNumber = this.state.card.cardNumber.substring(0, 14);
        if (firstNumber.split('_')[0].length > 5 && id == 'cardNumber') {
          this.checkCardPaymentType(firstNumber);
        }

        if (firstNumber.split('_')[0].length < 6 && id == 'cardNumber' && this.state.creditOrDebit !== null) {
          this.setState({ creditOrDebit: null }, () => {
            if (this.state.amount) {
              this.setAmount(this.state.amount);
            }
          });
        }
        this.setState({ card: { ...this.state.card } });
        if (firstNumber[0] === '3') {
          this.setState({ americanExpress: true });
        } else {
          this.setState({ americanExpress: false });
        }
      }
    );
  }

  handleInvoiceSuccess(response, misMatchError) {
    if (this.getInvoiceSuccess(response)) {
      if (this.state.updatedInvoice) {
        return this.successEmailInvoice(misMatchError);
      }

      if (this.state.invoiceFile.length > 0) {
        return this.uploadAndEmailInvoice(misMatchError);
      }

      return this.successEmailInvoice(misMatchError);
    }
  }

  uploadAndEmailInvoice(misMatchError) {
    return this.uploadInvoiceFiles()
      .then((_res) => {
        this.successEmailInvoice(misMatchError);
      })
      .catch((error) => {
        console.error('File upload error:', error);
      });
  }

  handleSubmitEmailInvoice(e, _t) {
    e.preventDefault();
    let logoError = [];
    let successMessages = false;
    const invoiceData = getInvoiceState(this.state);
    invoiceData.image = this.getCompanyLogoPath();
    const currentLocation = whichLocationSelected();
    const zipCodeError1 = validateZipCode(this.state);
    if (zipCodeError1.length > 0) {
      this.setState({ misMatchError: zipCodeError1 });
      return false;
    }
    const { error, errorFlag, misMatchError } = this.validateFormInputs(true);
    if (errorFlag) {
      this.setState({ misMatchError, logoError, error, successMessages });
      return false;
    } else {
      if (!this.state.selectedCustomer?.email) {
        misMatchError.push('No email was found. Please add an email.');
        this.setState({ misMatchError });
        window.scrollTo(0, 0);
        return false;
      }
      this.setState({ invoiceData, saveDraft: true, emailSent: true, isLoading: true }, async () => {
        const payloadData = {
          ...this.state,
          ...this.props.termsAndCondition,
          ...(this.state.selectedCustomer && {
            customerDetails: { ...this.state.selectedCustomer },
          }),
        };

        this.props
          .addInvoice(
            {
              ...payloadData,
              currentLocation,
              locationId: currentLocation?._id,
              selectedCustomer: {
                ...this.state.selectedCustomer,
                phone: makePhoneNumberForDB(this.state.selectedCustomer.phone),
              },
            },
            this.state.csrf,
            this.state.paytraceCredentials
          )
          .then((response) => {
            window.scrollTo(0, 0);
            this.handleInvoiceSuccess(response, misMatchError);
            if (this.getInvoiceError(response)) {
              if (this.getResultPayload(response)) {
                let message = response.payload.error.data.message;
                misMatchError.push(message);
                this.setState({
                  successMessages,
                  misMatchError,
                  isLoading: false,
                });
              }
              if (response.payload.error.data.success === false) {
                let message = response.payload.error.data.message;
                misMatchError.push(message);
                this.setState({
                  successMessages,
                  isLoading: false,
                  misMatchError,
                });
              }
              if (this.getInvoiceInfo()) {
                let responseErrors = JSON.parse(this.props.info.invoiceInfo.data.response);
                this.getResponseError(responseErrors, misMatchError, successMessages);
              }
              this.setState({
                successMessages,
                misMatchError,
                isLoading: false,
              });
            }
          });
      });
    }
  }

  handleSubmitTextInvoice(e, _t) {
    e.preventDefault();
    let logoError = [];
    let successMessages = false;
    const invoiceData = getInvoiceState(this.state);
    invoiceData.image = this.getCompanyLogoPath();

    const zipCodeError = validateZipCode(this.state);
    if (zipCodeError.length > 0) {
      this.setState({ misMatchError: zipCodeError });
      return false;
    }
    const { error, errorFlag, misMatchError } = this.validateFormInputs(true);
    if (errorFlag) {
      this.setState({ misMatchError, logoError, error, successMessages });
      return false;
    } else {
      if (!(this.state.selectedCustomer?.phone || this.state.phone)) {
        misMatchError.push('No phone number was found. Please add a phone number.');
        this.setState({ misMatchError });
        window.scrollTo(0, 0);
        return false;
      }
      this.setState({ invoiceData, saveDraft: true, textSent: true, isLoading: true }, () => {
        this.props
          .textInvoice(
            {
              ...this.state,
              ...(this.state.selectedCustomer && {
                customerDetails: { ...this.state.selectedCustomer },
              }),
              selectedCustomer: {
                ...this.state.selectedCustomer,
                phone: makePhoneNumberForDB(this.state.selectedCustomer.phone || this.state.phone),
              },
            },
            this.state.csrf,
            this.state.paytraceCredentials
          )
          .then((res) => {
            window.scrollTo(0, 0);
            if (this.getInvoiceSuccess(res)) {
              if (this.state.invoiceFile.length > 0) {
                this.uploadInvoiceFiles().then((_res) => {
                  this.successTextInvoice(misMatchError);
                });
              } else {
                this.successTextInvoice(misMatchError);
              }
            }
            if (this.getInvoiceError(res)) {
              if (this.getResultPayload(res)) {
                let message = res.payload.error.data.message;
                misMatchError.push(message);
                this.setState({
                  successMessages,
                  misMatchError,
                  isLoading: false,
                });
              }
              if (res.payload.error.data.success === false) {
                let message = res.payload.error.data.message;
                misMatchError.push(message);
                this.setState({
                  successMessages,
                  isLoading: false,
                  misMatchError,
                });
              }
              if (this.getInvoiceInfo()) {
                let responseErrors = JSON.parse(this.props.info.invoiceInfo.data.response);
                this.getResponseError(responseErrors, misMatchError, successMessages);
              }
              this.setState({
                successMessages,
                misMatchError,
                isLoading: false,
              });
            }
          });
      });
    }
  }

  uploadInvoiceFiles() {
    const formData = new FormData();
    if (this.state.invoiceFile && this.state.invoiceFile.length) {
      this.state.invoiceFile &&
        this.state.invoiceFile.forEach((item) => {
          formData.append('invoiceFile', item);
        });
    }
    formData.append('invoice', this.state.invoice);
    formData.append('isCustomInvoicePDF', true);
    return this.props.uploadInvoiceFile(formData);
  }

  successEmailInvoice(misMatchError) {
    this.setState(
      {
        isLoading: false,
        successMessages: true,
        misMatchError,
        successMessagesText: 'Email Sent Successfully!',
      },
      () => {
        setTimeout(() => {
          this.setState({ successMessages: false });
          this.setState({ redirect: true });
        }, 2000);
      }
    );
  }

  successTextInvoice(misMatchError) {
    this.setState(
      {
        isLoading: false,
        successMessages: true,
        misMatchError,
        successMessagesText: 'Text Invoice Sent Successfully!',
      },
      () => {
        setTimeout(() => {
          this.setState({ successMessages: false });
          this.setState({ redirect: true });
        }, 2000);
      }
    );
  }

  validateStateElements(misMatchError, error, errorFlag) {
    for (const key of Object.keys(this.state)) {
      const isKeyInvalid = validator(key, this.state[key]);
      if (isKeyInvalid) {
        error[key + 'Error'] = true;
        errorFlag = true;
        misMatchError.push(`Invalid input: ${key}`);
        break;
      } else if (this.state[key] === '' || (typeof this.state[key] === 'string' && this.state[key].trim() === '')) {
        error[key + 'Error'] = true;
        errorFlag = false;
        misMatchError.push(`invalid input: ${key}`);
        break;
      } else {
        error[key + 'Error'] = false;
        errorFlag = false;
      }
    }
    return errorFlag;
  }

  validateFormInputs(bypassAddressSetting) {
    let misMatchError = [];
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });

    const propertiesToDelete = [
      'PO',
      'timestamp_PAID',
      'achDetails',
      'payerName',
      'publicNotes',
      'privateNotes',
      'termNotes',
      'phone',
    ];

    /**
     * If `InvoiceDiscount` and `DiscountFeeName` are not present or set
     * exlucde 'discountFeeName' and 'DiscountFeeName' from saving in collection
     *
     */
    if (
      !this.state.feeSettings?.virtualTerminalSettings?.InvoiceDiscount &&
      !this.state.feeSettings?.virtualTerminalSettings?.DiscountFeeName
    ) {
      propertiesToDelete.push('discountFeeName');
      propertiesToDelete.push('DiscountFeeName');
    }

    propertiesToDelete.forEach((property) => {
      if (this.state[property] === '') {
        delete this.state[property];
      }
    });
    if (this.state.svcFee === 0 || this.state.svcFee == '' || this.state.svcFee === null) {
      delete this.state.svcFee;
    }

    if (this.state.lateFee === 0 || this.state.lateFee == '' || this.state.lateFee === null) {
      delete this.state.lateFee;
    }
    let addressError = '';
    if (!bypassAddressSetting) {
      addressError = handleAddressSettingError(this.state);
      if (addressError.length > 0) {
        errorFlag = true;
        misMatchError = addressError;
        return { misMatchError, errorFlag, error };
      }
    }

    const zipCodeErrorss = validateZipCode(this.state);
    if (zipCodeErrorss.length > 0) {
      errorFlag = true;
      misMatchError = addressError;
      return { misMatchError, errorFlag, error };
    }

    if (this.state.customerId === null) {
      this.openAddclient();
      errorFlag = true;
      error = 'Select Customer First.';
      misMatchError.push(error);
      return { misMatchError, errorFlag, error };
    }

    if (this.getNameOrMaskNumber()) {
      delete this.state.mask_number;
    }

    if (this.state.errorCard.cardExpiryDate) {
      errorFlag = true;
      error = 'Invalid card expiry date';
      misMatchError.push(error);
      return { misMatchError, errorFlag, error };
    }

    errorFlag = this.validateStateElements(misMatchError, error, errorFlag);
    // eslint-disable-next-line no-prototype-builtins
    let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));

    if (
      !Object.prototype.hasOwnProperty.call(this.state.paytraceCredentials, 'password') &&
      locationPaydetails?.gateway !== 'multipass'
    ) {
      window.scrollTo(0, 0, 0);
      let msg = 'Select the payment location.';
      misMatchError.push(msg);
      this.setState({ misMatchError });
      errorFlag = true;
    }

    return { misMatchError, errorFlag, error };
  }
  handleSubmitAddCardTsep(_e) {
    this.setState({ updateCard: true, cardWalletID: null, tsepFieldSuccess: false });
  }

  handleTimeout(payload, timeout = 500) {
    setTimeout(() => {
      this.setState(payload);
    }, timeout);
  }

  handleSubmitSaveDraft(e, _t) {
    e.preventDefault();
    let logoError = [];
    let successMessages = false;
    const invoiceData = getInvoiceState(this.state);
    const rowData = this?.props?.location?.state?.rowData;
    invoiceData.image = this.getCompanyLogoPath();
    const paymentGateway = getLocationPaymentGateway();
    if (paymentGateway !== 'multipass') {
      window.paytrace.submitEncrypted('#DemoFormInvoice');
    }

    const zipCodeError = validateZipCode(this.state);
    if (zipCodeError.length > 0) {
      this.setState({ misMatchError: zipCodeError });
      return false;
    }
    const { error, errorFlag, misMatchError } = this.validateFormInputs(true);
    if (errorFlag) {
      if (errorFlag) {
        this.setState({ ...this.state, misMatchError, logoError, error, successMessages });
      } else {
        this.state.misMatchError.push('You cannot mark the invoice as paid and save as a draft.');
      }
      return false;
    } else {
      const encrypted_number = localStorage.getItem('0');
      const encrypted_csc = localStorage.getItem('1');
      const currentLocation = whichLocationSelected();
      this.setState(
        {
          invoiceData,
          saveDraft: true,
          isLoading: true,
          locationId: currentLocation?._id,
          ...(encrypted_number && { encrypted_number: encrypted_number }),
          ...(encrypted_csc && { encrypted_csc: encrypted_csc }),
        },
        () => {
          if (rowData) {
            this.props
              .updatePendingInvoice(
                {
                  ...this.state,
                  ...(this.state.selectedCustomer && {
                    customerDetails: { ...this.state.selectedCustomer },
                  }),
                  selectedCustomer: {
                    ...this.state.selectedCustomer,
                    phone: makePhoneNumberForDB(this.state.selectedCustomer?.phone),
                  },
                  currentLocation,
                },
                this.state.csrf,
                this.state.paytraceCredentails
              )
              .then((response) => {
                window.scrollTo(0, 0);
                if (this.getInvoiceSuccess(response)) {
                  if (this.state.invoiceFile.length > 0) {
                    this.uploadInvoiceFiles()
                      .then(() => {
                        this.setState(
                          {
                            successMessagesUpdate: true,
                            isLoading: false,
                            misMatchError,
                            updatedInvoice: true,
                            saveDraft: false,
                            name: rowData.firstName + ' ' + rowData.lastName,
                          },
                          this.handleTimeout({
                            openEditConfirmationModal: true,
                            successMessagesUpdate: false,
                          })
                        );
                      })
                      .catch((error) => {
                        this.setState({ saveDraft: false });
                        console.log('Error12', error);
                      });
                  } else {
                    this.setState(
                      {
                        isLoading: false,
                        successMessagesUpdate: true,
                        misMatchError,
                        updatedInvoice: true,
                        saveDraft: false,
                      },
                      this.handleTimeout({
                        successMessagesUpdate: false,
                        openEditConfirmationModal: true,
                      })
                    );
                  }
                }
                if (this.getInvoiceError(response)) {
                  if (this.getResultPayload(response)) {
                    let message = response.payload.error.data.message;
                    misMatchError.push(message);
                    if (response.payload.error.data.success === false) {
                      this.setState({
                        successMessagesUpdate: false,
                        isLoading: false,
                        misMatchError,
                        saveDraft: false,
                      });
                    }
                    if (response.payload.error.data.response.length > 0) {
                      let responseErrors = JSON.parse(response.payload.error.data.response);
                      this.getResponseError(responseErrors, misMatchError, successMessages);
                      if (responseErrors.response_code === 1001) {
                        misMatchError.push(responseErrors.status_message);
                        this.setState({
                          misMatchError,
                          isLoading: false,
                          successMessagesUpdate: successMessages,
                          saveDraft: false,
                        });
                      }
                    }
                  }
                }
              });
          } else {
            this.props
              .addInvoice(
                {
                  ...this.state,
                  ...(this.state.selectedCustomer && {
                    customerDetails: { ...this.state.selectedCustomer },
                  }),
                  selectedCustomer: {
                    ...this.state.selectedCustomer,
                    phone: makePhoneNumberForDB(this.state.selectedCustomer?.phone),
                  },
                  currentLocation,
                },
                this.state.csrf,
                this.state.paytraceCredentials
              )
              .then((resp) => {
                window.scrollTo(0, 0);
                if (this.getInvoiceSuccess(resp)) {
                  if (this.state.invoiceFile.length > 0) {
                    this.uploadInvoiceFiles()
                      .then(() => {
                        this.setState(
                          {
                            misMatchError,
                            successMessages: true,
                            isLoading: false,
                          },
                          this.handleTimeout(
                            {
                              successMessages: false,
                              redirect: true,
                            },
                            2002
                          )
                        );
                      })
                      .catch((error) => {
                        console.log('Error1', error);
                      });
                  } else {
                    this.setState(
                      { isLoading: false, successMessages: true, misMatchError },
                      this.handleTimeout({ successMessages: false, redirect: true }, 2001)
                    );
                  }
                }
                if (this.getInvoiceError(resp)) {
                  if (this.getResultPayload(resp)) {
                    let message = resp.payload.error.data.message;
                    misMatchError.push(message);
                    if (resp.payload.error.data.success === false) {
                      this.setState({
                        misMatchError,
                        isLoading: false,
                        successMessages,
                      });
                    }
                    if (resp.payload.error.data.response.length > 0) {
                      let responseErrors = JSON.parse(resp.payload.error.data.response);
                      this.getResponseError(responseErrors, misMatchError, successMessages);
                      if (responseErrors.response_code === 1001) {
                        misMatchError.push(responseErrors.status_message);
                        this.setState({
                          successMessages,
                          misMatchError,
                          isLoading: false,
                        });
                      }
                    }
                  }
                }
              });
          }
        }
      );
    }
  }
  checkCardValues(state) {
    if (this.state.name === undefined) {
      return false;
    } else if (this.state.name !== '') {
      return true;
    }

    if (state.card.cardNumber.indexOf('_') > -1) {
      this.setState({
        errorCard: { ...this.state.errorCard, cardNumber: true },
      });
      return false;
    } else if (state.card.cardExpiryDate.indexOf('_') > -1) {
      this.setState({
        errorCard: { ...this.state.errorCard, cardExpiryDate: true },
      });
      window.scrollTo(0, 0);
      return false;
    } else if (state.card.cardCsc.indexOf('_') > -1) {
      this.setState({ errorCard: { ...this.state.errorCard, cardCsc: true } });
      return false;
    } else {
      return true;
    }
  }
  getUniqueInvoiceNumber() {
    this.setState({ isLoading: true });
    this.props.getInvoiceNumber().then((res) => {
      this.setState({
        invoice: res.payload.paymentData.data.invoice,
        isLoading: false,
        // newJobId: res.payload.paymentData.data.invoice,
      });
    });
  }

  getCustomerList() {
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem('user'));
    const locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    const locations = JSON.stringify([{ locationId: locationPaydetails.id }]);
    let data = {
      role: userData.role,
      userId: userData._id,
      companyId: userData.company._id,
      locations: locations,
    };
    this.props.customerDetailsByCompany(data).then((res) => {
      let selectedCustomer = { ...this.state.selectedCustomer };
      const data = res.payload.customerData.data.response.filter((item) => item._id === selectedCustomer.id);
      this.setState(
        {
          CoustmerData: res.payload.customerData.data.response.filter((customer) => customer.status === true),
          isLoading: false,
          selectedCustomer: data[0],
        },
        () => {
          if (this.props?.location?.state?.rowData) {
            const invoiceData = this.props?.location?.state?.rowData;
            const customerValue = {
              value: invoiceData.customerId.id ?? invoiceData.customerId._id,
              label: invoiceData.customerId.firstName + ' ' + invoiceData.customerId.lastName,
            };
            this.setClientValue(customerValue);
          }
        }
      );
    });
  }
  getProductList() {
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem('user'));
    this.props.productList({ companyId: userData.company._id }).then((res) => {
      this.setState({
        ProductData: res.payload.productData.data.response,
        isLoading: false,
      });
    });
  }

  handleChangeTab(e, val) {
    this.setState({ tabValue: val });
  }

  handleDateChange = (date, key) => {
    if (date) {
      this.setState({ [key]: date });
    } else {
      alert('Date can not be empty!');
      this.setState({ [key]: new Date() });
    }
  };

  openAddclient() {
    this.setState({ isOpen: true });
  }

  handleClose = () => {
    this.getCustomerList();
    this.setState({ isOpen: false, isEditClient: false, isLoading: true });
  };

  a11yProps(index) {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  }
  handleSubmitAddNewCard(_e) {
    const cardDataInital = {
      cardCsc: '___',
      cardNumber: '______________',
      cardExpiryDate: '__/____',
    };
    this.setState({
      card: { ...cardDataInital },
      mask_number: '',
      updateCard: true,
      addNewCard: true,
      creditOrDebit: null,
    });
  }

  getAddressDetails(customer) {
    const { shippingAddress, billingAddress } = this.state;
    const data = this.state.CoustmerData.filter((item) => item._id === customer.value);
    if (this.editMode) {
      return { shippingAddress, billingAddress };
    } else {
      const customerShippingAddress = data[0].shippingAddress ? data[0].shippingAddress : shippingAddress;
      const customerBillingAddress = data[0].billingAddress ? data[0].billingAddress : billingAddress;

      return { shippingAddress: customerShippingAddress, billingAddress: customerBillingAddress };
    }
  }
  setClientValue(val) {
    if (val !== null) {
      let misMatchError = [];
      const errCard = {
        cardNumber: false,
        cardHolderName: false,
        cardExpiryDate: false,
        cardCsc: false,
      };
      const cardDataInital = {
        cardCsc: '___',
        cardNumber: '______________',
        cardExpiryDate: '__/____',
        customerToken: false,
        customerTokenUpdate: false,
      };

      this.setState({
        card: { ...cardDataInital },
        errorCard: { ...errCard },
        misMatchError,
        error: { ...this.state.error },
        customerId: val.value,
        cardPanelShow: true,
        addNewCard: false,
        creditOrDebit: null,
        shippingAddress: { zip: '', addressOne: '', addressTwo: '', city: '', state: '' },
        billingAddress: { zip: '', addressOne: '', addressTwo: '', city: '', state: '' },
      });

      //For set mask number
      const data = this.state.CoustmerData.filter((item) => item._id === val.value);
      const { shippingAddress, billingAddress } = this.getAddressDetails(val);
      this.setState({ selectedCustomer: data[0] });
      if (data[0]?.customer_id) {
        // THis conditions evaluates wheather customer has been creatd on paymentgateway or not
        const { firstName, lastName } = data[0];
        this.setState({
          cardType: data[0].cardType,
          mask_number: data[0].masked_card_number,
          creditOrDebit: data[0]?.creditOrDebit,
          cardPanelShow: true,
          shippingAddress,
          billingAddress: validateCityInAddress(billingAddress),
          cardWalletID: data[0]?.cardWalletID,
          name: `${firstName} ${lastName ?? ''}`,
          customerCode: data[0]?.customerCode,
        });
        if (this.state.amount) {
          this.setAmount(this.state.amount);
        }

        if (this.editMode === undefined) {
          this.setState({
            name: getName(data),
          });
        }
      } else {
        let namee = getName(data);
        this.setState({
          cardWalletID: null,
          mask_number: '',
          name: namee,
          updateCard: false,
          shippingAddress,
          billingAddress: validateCityInAddress(billingAddress),
        });
      }
    } else {
      let misMatchError = [];
      const errCard = {
        cardNumber: false,
        cardHolderName: false,
        cardExpiryDate: false,
        cardCsc: false,
      };
      const cardElement = document.getElementById('cardNumber');
      if (cardElement) {
        cardElement.value = '___________';
      }
      const cardDataInital = {
        cardCsc: '___',
        cardNumber: '______________',
        cardExpiryDate: '__/____',
        customerToken: false,
        customerTokenUpdate: false,
      };
      this.setState(
        {
          card: { ...cardDataInital },
          misMatchError,
          errorCard: { ...errCard },
          customerId: null,
          cardPanelShow: false,
          addNewCard: false,
          cardWalletID: null,
          creditOrDebit: null,
          selectedCustomer: null,
          shippingAddress: { zip: '', addressOne: '', addressTwo: '', city: '', state: '' },
          billingAddress: { zip: '', addressOne: '', addressTwo: '', city: '', state: '' },
        },
        () => {
          if (this.state.amount) {
            this.setAmount(this.state.amount);
          }
        }
      );
    }
  }
  fetchDiscountAmount = () => {
    let discount = { message: '', total: '' };

    if (this.state.amount > 0 || this.state.discount > 0) {
      const total = this.state.amount - this.state.discount;
      discount.total = total;

      switch (total) {
        case 0:
          discount.message = 'Amount can not be zero';
          return discount;
        case total < 0:
          discount.message = 'Amount can not be negative';
          return discount;
        default:
          return discount;
      }
    }

    return discount;
  };
  dicountTabHandle(e) {
    let { value } = e.target;

    this.setState({ discountType: value });
  }
  handleCheckAsPaid(event) {
    this.setState({ ...this.state, [event.target.id]: event.target.checked }, () => {
      let misMatchError = [];
      let InitialsvcFee = 0;
      if (this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFeeType === '$') {
        InitialsvcFee = this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFee;
      } else {
        InitialsvcFee = (this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFee / 100) * this.state.amount;
      }

      if (this.state.isPaid) {
        this.setState(
          {
            errorCard: {
              ...this.state.errorCard,
              cardExpiryDate: false,
              cardNumber: false,
              cardCsc: false,
            },
            svcFee: 0,
            cash: true,
          },
          () => {
            this.setState({
              svcFee: this.state.svcFee.toFixed(2),
              total: parseFloat(this.state.amount) + this.state.tax + this.state.svcFee + this.getLateFee(),
              cashTotal: parseFloat((parseFloat(this.state.amount) + this.state.tax + this.getLateFee()).toFixed(2)),
            });
          }
        );
      } else {
        this.setState(
          {
            misMatchError,
            svcFee: InitialsvcFee,
            errorCard: {
              ...this.state.errorCard,
              cardExpiryDate: false,
              cardCsc: false,
              cardNumber: false,
            },
            cash: false,
          },
          () => {
            this.setState({
              svcFee: this.state.svcFee.toFixed(2),
              total: parseFloat(this.state.amount) + this.state.tax + this.state.svcFee + this.getLateFee(),
              cashTotal: parseFloat((parseFloat(this.state.amount) + this.state.tax + this.getLateFee()).toFixed(2)),
            });
          }
        );
      }
    });
  }
  handleChangeCheckbox(event) {
    this.setState({
      card: { ...this.state.card, [event.target.id]: event.target.checked },
    });
  }
  handleChangeCheckboxUpdate(event) {
    this.setState({
      card: { ...this.state.card, [event.target.id]: event.target.checked },
    });
  }
  backToMaskCard(_e, _t) {
    //For set mask number
    const errCard = {
      cardNumber: false,
      cardHolderName: false,
      cardExpiryDate: false,
      cardCsc: false,
    };
    const cardDataInital = {
      cardCsc: '___',
      cardNumber: '______________',
      cardExpiryDate: '__/____',
    };
    this.setState({
      card: { ...cardDataInital },
      errorCard: { ...errCard },
      cardPanelShow: true,
      updateCard: false,
    });
    const data = this.state.CoustmerData.filter((item) => {
      if (item._id === this.state.customerId) {
        return item;
      }
    });
    if (data[0].customer_id) {
      this.setState({
        mask_number: data[0].masked_card_number,
        cardPanelShow: true,
      });

      if (this.editMode === undefined) {
        const { firstName, lastName } = data[0];
        const name = `${firstName} ${lastName ? lastName : ''}`;
        this.setState({
          name: !this.state.updatedInvoice ? name : this.state.name,
        });
      }
    }
  }
  setEditClient() {
    this.setState({ isEditClient: true });
  }
  handleRemove(i) {
    const values = [...this.state.productFields];
    values.splice(i, 1);
    this.setState({ productFields: values });
  }

  changeValue(key, value, projects) {
    for (let i in projects) {
      if (projects[i].key === 'description') {
        projects[i].value = value;
        break; //Stop this loop, we found it!
      }
      if (projects[i].key === 'unitCost') {
        projects[i].value = value;
        break; //Stop this loop, we found it!
      }
    }
  }
  productFieldsAmount(data) {
    if (data.length > 1) {
      let values = [...data];
      let productData = values.filter((item, _i) => {
        if (item.name !== '') {
          return item;
        }
      });

      this.setState({ products: [...productData] });
      let amountData = data.reduce((sum, { amount }) => sum + Number(amount), 0);
      this.setState({ amount: Number(amountData).toFixed(2) }, () => {
        if (
          !this.state.feeSettings?.virtualTerminalSettings?.InvoiceTax &&
          !this.state.feeSettings?.virtualTerminalSettings?.InvoiceDiscount &&
          !this.state.feeSettings?.DualFeeEnabled &&
          !this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurcharge
        ) {
          this.setState({ total: parseFloat(amountData) });
        } else if (amountData !== '0') {
          const feeComponents = getFeeCalculations(
            this.state?.feeSettings,
            this.state.amount,
            this.state.creditOrDebit,
            this.state?.billingAddress.state
          );
          // Set calculations based on fee settings here
          this.setState(
            {
              // amount: data[0].amount,
              total: feeComponents.totalAmount,
              tax: feeComponents.salesTaxFee,
              svcFee: parseFloat(feeComponents.svcFee).toFixed(2),
              salesTaxTotal: feeComponents.salesTaxTotal,
              isStateSVCApplied: feeComponents.isStateSVCApplied,
              stateSVCAppliedRate: feeComponents.stateSVCAppliedRate,
              notApplied: feeComponents.notApplied,
            },
            () => {
              // Handle lateFee calculation based on CustomFeeOneEnabled here
              if (this.state.feeSettings?.DualFeeEnabled) {
                const lateFee = getCustomFeeOne();
                const cashTotal = parseFloat(
                  (parseFloat(this.state.amount) + (this.state.salesTaxTotal || 0) + this.getLateFee()).toFixed(2)
                );
                const cardAmount = parseFloat(this.state?.amount) + parseFloat(this.state.svcFee || 0);
                this.setState({ lateFee, cashTotal, cardAmount });
              }
              if (this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurcharge) {
                this.setState({
                  surcharge: getRoundedExacts(
                    getSurchargeFee(
                      this.state.feeSettings,
                      parseFloat(this.state.amount),
                      this.state.creditOrDebit,
                      this.state?.billingAddress.state
                    )
                  ),
                });
              }
            }
          );
        }
      });
    } else {
      this.setState({ amount: 0, total: 0, tax: 0, svcFee: '', lateFee: 0, cashTotal: 0 });
    }
  }
  handleChangeSvcFee(e) {
    let { id, value } = e.target;
    if (value !== '' && id === 'svcFee') {
      this.setState({ svcFee: value }, () => {
        this.setState({
          total:
            parseFloat(this.state.amount) +
            parseFloat(this.state.tax) +
            parseFloat(this.state.svcFee) +
            parseFloat(this.getLateFee()),
          cashTotal: parseFloat(
            (parseFloat(this.state.amount) + parseFloat(this.state.tax) + parseFloat(this.getLateFee())).toFixed(2)
          ),
        });
      });
    } else {
      this.setState({ svcFee: '' }, () => {
        this.setState({
          total:
            parseFloat(this.state.amount) +
            parseFloat(this.state.tax) +
            parseFloat(this.state.svcFee) +
            parseFloat(this.getLateFee()),
          cashTotal: parseFloat(
            (parseFloat(this.state.amount) + parseFloat(this.state.tax) + parseFloat(this.getLateFee())).toFixed(2)
          ),
        });
      });
    }
  }

  removeCloseableMessage = (index) => {
    let misMatchError = this.state.misMatchError;
    delete misMatchError[index];
    this.setState({ misMatchError });
  };

  handleChangeSwitch(e, name) {
    this.setState({ [name]: e.target.checked });
  }
  handlePrint = () => {
    PrintInvoice({
      ...this.state,
      customInvoiceName: this.state.invoiceLable,
      discountFeeName: this.state.feeSettings?.virtualTerminalSettings?.DiscountFeeName,
      isSurchargeApplied: !!this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurcharge,
    });
    return;
  };

  fetchAddressInfo() {
    this.props.UserDetailById({ id: this.state?.userDetails?._id }).then((resp) => {
      if (resp.type === 'USER_SUCCESS' && resp.payload.userData.status === 200) {
        this.setState({
          addressSettings: {
            ...this.state.addressSettings,
            shippingAddress: resp.payload.userData.data.settings?.invoiceSettings?.shippingAddress,
            billingAddress: resp.payload.userData.data.settings?.invoiceSettings?.billingAddress,
          },
        });
      }
    });
  }

  handleChangeAddress = (event, type, isBillingAddressSameAsShippingAddress = false) => {
    let { id, value } = event.target;
    if (type === 'shippingAddress' || isBillingAddressSameAsShippingAddress) {
      const addressShipping = {
        ...this.state.shippingAddress,
        [id]: value,
      };
      this.setState({ shippingAddress: addressShipping });
    }
    if (type === 'billingAddress') {
      const billingAddress = {
        ...this.state.billingAddress,
        [id]: value,
      };
      this.setState({ billingAddress }, () => {
        if (id === 'state') {
          this.setAmount(this.state.amount);
        }
      });
    }
  };

  handleIsBillingAddressSameAsShippingAddress = (isBillingAddressSameAsShippingAddress = false) => {
    if (isBillingAddressSameAsShippingAddress) {
      this.setState({ shippingAddress: { ...this.state.billingAddress } });
    }
  };
  capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  render() {
    const maxDailyAmount = this.state.TransactionData.reduce((item, curr) => {
      return item + curr.AMOUNT;
    }, 0);
    const { classes, t } = this.props;
    const { CoustmerData, redirect, redirectTransaction, isSizeMobile, creditOrDebit } = this.state;
    const rowData = this.props?.location?.state?.rowData;
    const paymentGateway = getLocationPaymentGateway();
    if (paymentGateway === 'multipass') {
      autoFillMultipassFields();
    }
    return (
      <div className="rightPanel">
        {redirect ? <Redirect to="/Invoices" /> : ''}
        {redirectTransaction ? <Redirect to="/Transactions" /> : ''}
        <Topheader />
        <Container component="main" maxWidth={false}>
          <Stack sx={{ width: '100%' }} spacing={2}>
            {this.state.misMatchError?.map((e, i) => (
              // eslint-disable-next-line react/jsx-key
              <CloseableAlerts key={i} index={i} message={e} severity="error" closer={this.removeCloseableMessage} />
            ))}
          </Stack>
          {this.state.successMessages ? <SuccessMessage successes={[this.props.info.invoiceInfo.data.message]} /> : ''}
          {this.state.successMessagesUpdate ? <SuccessMessage successes={['Invoice updated successfully']} /> : ''}
          {this.state.tsepFieldError ? <ErrorMessage errors={[this.state.tsepFieldError]} /> : ''}
          {this.state.limit.perTransactionLimit ? (
            <h3 className="errorMsg">
              <span>Warning : </span>Your per transaction limit is $
              {this.props.UserDetail.permissions.perTransactionLimit
                ? this.props.UserDetail.permissions.perTransactionLimit
                : 0}{' '}
            </h3>
          ) : (
            ''
          )}
          {this.state.limit.dailyTransactionLimit ? (
            <h3 className="errorMsg">
              <span>Warning : </span>Your daily transaction limit is{' '}
              {this.props.UserDetail.permissions.dailyTransactionLimit
                ? CurrencyFormatterUS(this.props.UserDetail.permissions.dailyTransactionLimit)
                : 0}
              .You have already made the payment of {CurrencyFormatterUS(maxDailyAmount)}, Make a payment less than{' '}
              {CurrencyFormatterUS(this.props.UserDetail.permissions.dailyTransactionLimit - maxDailyAmount)}.
            </h3>
          ) : (
            ''
          )}
          <DialogBox open={this.state.isOpen} handleClose={this.handleClose} message={<AddClient />} />
          <DialogBox
            open={this.state.isEditClient}
            handleClose={this.handleClose}
            message={<AddClient editData={this.state.customerId} />}
          />
          <LoadingOverlay
            className={classes.tpTableFullW}
            active={this.state.isLoading}
            spinner
            text="Fetching your response ..."
          >
            <form className={classes.form} id="DemoFormInvoice" ref={this.msgRef}>
              <Grid container spacing={3} className="addInvoiceCustomePage">
                <Grid item md={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <input type="hidden" name="_csrf" value={this.state.csrf['csrf-token']} />
                      <input type="hidden" name="_csrfSecret" value={this.state.csrf['csrf-secret']} />
                      <FormControl
                        className="frequencyInput"
                        fullWidth
                        style={getValidationStyle(this.state.customerId == null && this.state.isSubmit)}
                      >
                        <div data-cy="invoice-customer-select">
                          <TypableSelect
                            placeholder={'Customer Name'}
                            suggestions={mapToFormat(CoustmerData)}
                            onChange={(val) => this.setClientValue(val)}
                            isClearable={true}
                            value={
                              rowData || this.state.selectedCustomer
                                ? {
                                    value: this.state.selectedCustomer?.id,
                                    label: `${this.state.selectedCustomer?.firstName ?? ''} ${
                                      this.state.selectedCustomer?.lastName ? this.state.selectedCustomer.lastName : ''
                                    }`,
                                  }
                                : ''
                            }
                          />
                        </div>
                      </FormControl>
                      <Grid className="addClientLink">
                        {this.state?.customerId !== null &&
                        this.state?.customerId !== 'undefined' &&
                        this.state?.customerId?._id !== '' &&
                        this.state?.customerId?._id !== 'undefined' ? (
                          <Buttons
                            className={classesI.addNewClient}
                            variant="contained"
                            color="primary"
                            text={t('EditCustomer')}
                            onClick={(e) => {
                              this.setEditClient(e);
                            }}
                          />
                        ) : (
                          <Buttons
                            className={classesI.addNewClient}
                            variant="contained"
                            color="primary"
                            text={t('AddNewCustomer')}
                            onClick={() => {
                              this.openAddclient();
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {creditOrDebit ? this.capitalizeFirstLetter(creditOrDebit) + ' Card' : 'Credit Card/Debit Card'}
                      {paymentGateway === 'multipass' ? (
                        <TsepCard
                          handleChangeCheckbox={this.handleChangeCheckbox}
                          tsepFieldError={this.state.tsepFieldError}
                          tsepSuccessResponse={this.state.tsepSuccessResponse}
                          customerToken={this.state.card?.customerToken}
                          customerTokenUpdate={this.state.card?.customerTokenUpdate}
                          cardWalletID={this.state?.cardWalletID}
                          maskedCardNumber={this.state.mask_number}
                          cardPanelCustomerShow={this.state.cardPanelCustomerShow}
                          cardPanelShow={this.state.cardPanelShow}
                          handleChangeCheckboxUpdate={this.handleChangeCheckboxUpdate}
                          handleSubmitAddCard={this.handleSubmitAddCardTsep}
                          updateCard={this.state.updateCard}
                          tsepAuth={this.props.LocationCurrent?.tsepAuth}
                        />
                      ) : (
                        <>
                          <Card
                            className={classNames([
                              this.state.cardPanelShow ? 'cardData' : 'blur',
                              'customCardWraper',
                              this.state.cardPanelShow && this.state.mask_number ? 'none' : 'block',
                            ])}
                          >
                            <div className="Card_header">
                              {!this.state.updateCard ? (
                                <Typography variant="h5" component="h2">
                                  Card Details
                                </Typography>
                              ) : (
                                <>
                                  <Typography variant="h5" component="h2">
                                    Card Details
                                  </Typography>
                                  <a className="cardBackButton" onClick={(e) => this.backToMaskCard(e, t)}>
                                    back
                                  </a>
                                </>
                              )}
                            </div>
                            <CardContent ref={this.cardRef} className="cardContents">
                              {this.state.errorCard?.cardNumber ? (
                                <label
                                  className={classNames([
                                    'MuiTypography-gutterBottom',
                                    this.state.errorCard?.cardNumber ? 'errorCard' : '',
                                  ])}
                                >
                                  {t('cardNumberInvalid')}
                                </label>
                              ) : (
                                <label
                                  className={classNames([
                                    'MuiTypography-gutterBottom',
                                    this.state.errorCard?.cardNumber ? 'errorCard' : '',
                                  ])}
                                >
                                  {t('cardNumber')}
                                </label>
                              )}
                              <MaskedInput
                                type="text"
                                value={this.state.card?.cardNumber}
                                className="pt-encrypt"
                                mask={this.state.americanExpress ? '111111111111111' : '1111111111111111'}
                                labels={t('cardNumber')}
                                id="cardNumber"
                                name="encrypted_number"
                                size="19"
                                onChange={(e) => this.handleCardChange(e)}
                              />
                              <Grid container item xs={12}>
                                <Grid item md={6} xs={4}>
                                  {this.state.errorCard?.cardExpiryDate ? (
                                    <label
                                      className={classNames([
                                        'MuiTypography-gutterBottom',
                                        this.state.errorCard.cardExpiryDate ? 'errorCard' : '',
                                      ])}
                                    >
                                      {t('cardExpiryDateInvalid')}
                                    </label>
                                  ) : (
                                    <label
                                      className={classNames([
                                        'MuiTypography-gutterBottom',
                                        this.state.errorCard?.cardExpiryDate ? 'errorCard' : '',
                                      ])}
                                    >
                                      {t('cardExpiryDate')}
                                    </label>
                                  )}
                                  <MaskedInput
                                    mask="11/1111"
                                    value={this.state.card?.cardExpiryDate}
                                    name="cardExpiryDate"
                                    id="cardExpiryDate"
                                    placeholder="mm/yyyy"
                                    ref={this.expiryDateRef}
                                    onChange={(e) => this.handleCardChange(e)}
                                  />
                                </Grid>
                                <Grid item md={6} xs={4}>
                                  {this.state.errorCard?.cardCsc ? (
                                    <label
                                      className={classNames([
                                        'MuiTypography-gutterBottom',
                                        this.state.errorCard?.cardCsc ? 'errorCard' : '',
                                      ])}
                                    >
                                      {t('cardCscInvalid')}
                                    </label>
                                  ) : (
                                    <label
                                      className={classNames([
                                        'MuiTypography-gutterBottom',
                                        this.state.errorCard.cardCsc ? 'errorCard' : '',
                                      ])}
                                    >
                                      {t('cardCsc')}
                                    </label>
                                  )}
                                  <MaskedInput
                                    type="text"
                                    value={this.state.card?.cardCsc}
                                    mask={this.state.americanExpress ? '1111' : '111'}
                                    className="pt-encrypt"
                                    id="cardCsc"
                                    name="encrypted_csc"
                                    ref={this.cscCardRef}
                                    onChange={(e) => this.handleCardChange(e)}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                            <CardActions>
                              {!this.state.updateCard ? (
                                <>
                                  {' '}
                                  <Checkbox
                                    id="customerToken"
                                    labels="creatNewUser"
                                    checked={this.state.card.customerToken}
                                    onChange={(e) => {
                                      this.handleChangeCheckbox(e);
                                    }}
                                    value={this.state.card.customerToken}
                                    color="primary"
                                    inputProps={{
                                      'aria-label': 'secondary checkbox',
                                    }}
                                  />
                                  <label>Save card for future payments</label>
                                </>
                              ) : (
                                <>
                                  {' '}
                                  <Checkbox
                                    id="customerTokenUpdate"
                                    labels="creatNewUser"
                                    checked={this.state.card.customerTokenUpdate}
                                    onChange={(e) => {
                                      this.handleChangeCheckboxUpdate(e);
                                    }}
                                    value={this.state.card.customerTokenUpdate}
                                    color="primary"
                                    inputProps={{
                                      'aria-label': 'secondary checkbox',
                                    }}
                                  />
                                  <label>Update card for future payments</label>
                                </>
                              )}
                            </CardActions>
                          </Card>

                          {/* for mask mask number */}
                          <div className={this.state.cardPanelShow && this.state.mask_number ? 'block' : 'none'}>
                            <Card
                              className={classNames([
                                this.state.cardPanelShow ? 'maskData' : 'blur',
                                'customCardWraper',
                              ])}
                            >
                              <div className="Card_header">
                                <Typography variant="h5" component="h2">
                                  Name : {this.state?.name}
                                </Typography>
                              </div>
                              <CardContent ref={this.cardRef} className="cardContents">
                                <label className="MuiTypography-gutterBottom">{t('maskCardNumber')}</label>
                                <Grid container item xs={12}>
                                  {this.state.mask_number ? this.state.mask_number : ''}
                                </Grid>
                              </CardContent>
                              <CardActions>
                                <Buttons
                                  id="AddNewCard"
                                  type="button"
                                  variant="contained"
                                  color="secondary"
                                  className={'addNewCardBtn'}
                                  onClick={(e) => this.handleSubmitAddNewCard(e)}
                                  text={t('addAnotherCard')}
                                />
                              </CardActions>
                            </Card>
                          </div>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={8}>
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          variant="inline"
                          autoOk
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline1"
                          minDate={
                            this.props?.location?.state?.rowData
                              ? this.props?.location?.state?.rowData?.dateOpened
                              : new Date()
                          }
                          label={t('InvoiceDate')}
                          value={this.state.invoiceDate ? this.state.invoiceDate : null}
                          onChange={(date) => {
                            this.handleDateChange(date, 'invoiceDate');
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={6}>
                      <Textfield
                        required={true}
                        error={
                          this.state.invoiceLable !== 'Invoice'
                            ? this.state.error.newJobIdError
                            : this.state.error.invoiceError
                        }
                        id="invoice"
                        type="text"
                        labels={t(`${this.state.invoiceLable}`)}
                        value={this.state.invoiceLable == 'Invoice' ? this.state.invoice : this.state.newJobId}
                        disabled={this.state.isDisableInvoice || this.editMode}
                        InputProps={{ maxLength: 25 }}
                        width={true}
                        helperText={this.state.customFieldInputError}
                        onChange={(e) => this.setCustomJobId(e)}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          autoOk
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          minDateMessage={'Due date should not be before invoice date'}
                          minDate={this.state.invoiceDate}
                          id="date-picker-inline"
                          label={t('DueDate')}
                          value={this.state.dueDate}
                          onChange={(date) => {
                            this.handleDateChange(date, 'dueDate');
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={6}>
                      <Textfield
                        id="PO"
                        type="text"
                        labels={t('PO')}
                        value={this.state.PO}
                        width={true}
                        onChange={(e) => this.handleChange(e, t)}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Grid container spacing={3}>
                        <Grid item md={6} sm={6} xs={6} />
                        <Grid item md={6} sm={6} xs={6}>
                          <FormControl className={classes.formControl} />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item md={6} className={classesI.pt7}>
                      <CurrencyTextField
                        required={true}
                        modifyValueOnWheel={false}
                        label={t('Amount')}
                        variant="outlined"
                        value={this.state.amount}
                        currencySymbol="$"
                        minimumValue="0"
                        outputFormat="string"
                        decimalCharacter="."
                        digitGroupSeparator=","
                        id="amount"
                        textAlign="left"
                        onChange={(e, value) => this.handleChangeAmount(e, value)}
                        onBlur={(event, value) => this.handleChangeAmount(event, value)}
                        style={{ width: '100%' }}
                      />
                    </Grid>

                    <Grid item md={6}>
                      <Switch
                        label="Late Fee Applies"
                        onChange={(e) => {
                          this.handleChangeSwitch(e, 'lateFeeApplies');
                        }}
                        checked={this.state.lateFeeApplies}
                      ></Switch>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={3} direction="row">
                  {checkAddressEnabled(this.state.addressSettings?.billingAddress) && (
                    <Grid item md={12} sm={12}>
                      <AddressFieldsDropDown
                        align={isSizeMobile ? 'column' : 'row'}
                        type="billing"
                        state={this.state}
                        handleChange={this.handleChangeAddress}
                        handleSameAddress={this.handleIsBillingAddressSameAsShippingAddress}
                      />
                    </Grid>
                  )}
                  {checkAddressEnabled(this.state.addressSettings?.shippingAddress) && (
                    <Grid item md={12} sm={12}>
                      <AddressFieldsDropDown
                        align={isSizeMobile ? 'column' : 'row'}
                        type="shipping"
                        state={this.state}
                        handleChange={this.handleChangeAddress}
                        handleSameAddress={this.handleIsBillingAddressSameAsShippingAddress}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                {this.props.UserDetail.permissions.displayProducts === true ? (
                  <div className={classes.tableWrapper}>
                    <ProductTable
                      productFieldsAmount={(data) => this.productFieldsAmount(data)}
                      products={this?.props?.location?.state?.rowData?.products}
                    />
                  </div>
                ) : (
                  ''
                )}
                <Grid container spacing={1} className="mt-m">
                  <Grid container item xs={12} spacing={3}>
                    <Grid item md={6} xs={12}>
                      <AppBar position="static" color="default">
                        <Tabs
                          value={this.state.tabValue}
                          onChange={(e, val) => {
                            this.handleChangeTab(e, val);
                          }}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="fullWidth"
                          aria-label="action tabs example"
                        >
                          <Tab label="Public Notes" {...this.a11yProps(0)} />
                          <Tab label="Private Notes" {...this.a11yProps(1)} />
                          <Tab label="Terms" {...this.a11yProps(2)} />
                        </Tabs>
                      </AppBar>
                      <TabPanel value={this.state.tabValue} index={0}>
                        <Textfield
                          error={this.state.error && this.state.error.publicNotesError}
                          helperText={
                            this.state.errorMessage && this.state.errorMessage.publicNotesErrorMessage
                              ? this.state.errorMessage.publicNotesErrorMessage
                              : ''
                          }
                          id="publicNotes"
                          type="text"
                          labels={t('PublicNotes')}
                          value={this.state.publicNotes}
                          width={true}
                          onChange={(e) => this.handleChange(e, t)}
                          multiline={true}
                        />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={1}>
                        <Textfield
                          error={this.state.error && this.state.error.privateNotesError}
                          helperText={
                            this.state.errorMessage && this.state.errorMessage.privateNotesErrorMessage
                              ? this.state.errorMessage.privateNotesErrorMessage
                              : ''
                          }
                          id="privateNotes"
                          type="text"
                          labels={t('PrivateNotes')}
                          value={this.state.privateNotes}
                          width={true}
                          rowsMax={'4'}
                          onChange={(e) => this.handleChange(e, t)}
                          multiline={true}
                        />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={2}>
                        <Textfield
                          error={this.state.error.termNotesError}
                          id="termNotes"
                          type="text"
                          labels={t('termNotes')}
                          value={this.state.termNotes}
                          width={true}
                          onChange={(e) => this.handleChange(e, t)}
                          rowsMax={'4'}
                          multiline={true}
                        />
                      </TabPanel>
                      {rowData && this.state.customInvoice && !this.state.addCustomInvoiceMode ? (
                        <Box
                          style={{
                            marginLeft: 10,
                            padding: 10,
                            border: 1,
                            borderColor: 'black',
                            borderStyle: 'dashed',
                            width: '100%',
                            paddingTop: 20,
                            paddingBottom: 20,
                          }}
                        >
                          <Typography variant="h6" fontWeight={600} style={{ marginBottom: 40, textAlign: 'center' }}>
                            Uploaded Custom Invoice
                          </Typography>
                          <Typography style={{ marginBottom: 30, textAlign: 'center' }}>
                            {this.state.invoice}invoice.pdf
                          </Typography>
                          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <a
                              href={`${process.env.REACT_APP_AWS_S3_BUCKET}${this.state.invoice}invoice.pdf`}
                              target="blank"
                            >
                              <Button variant="outlined">View</Button>
                            </a>
                            <Button
                              style={{ marginLeft: 10 }}
                              variant="contained"
                              onClick={() => this.setState({ addCustomInvoiceMode: true })}
                            >
                              Update Custom Invoice
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <DropzoneArea
                            dropzoneText={`Drag and drop a custom PDF here. Or click on + to open file explorer`}
                            Icon={AddIcon}
                            showFileNames={true}
                            filesLimit={10}
                            maxFileSize={500000000}
                            acceptedFiles={['application/pdf']}
                            onChange={async (files) => {
                              this.setState({ invoiceFile: files });
                            }}
                          />
                          {rowData && this.state.customInvoice && (
                            <Button
                              style={{ marginLeft: 10 }}
                              variant="contained"
                              onClick={() => this.setState({ addCustomInvoiceMode: false })}
                            >
                              Cancel
                            </Button>
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid container>
                        <Grid container item xs={12}>
                          <Grid item sm={4} xs={12} />
                          <Grid item sm={8} xs={12}>
                            <div className="sub-total">
                              <Grid container item xs={12} spacing={3}>
                                {/* <Grid item xs={7}>
                                  Mark As Paid
                                </Grid>
                                <Grid item xs={5}>
                                  <Checkbox
                                    id="isPaid"
                                    labels="markAsPaid"
                                    checked={this.state.isPaid}
                                    onChange={(e) => {
                                      this.handleCheckAsPaid(e);
                                    }}
                                    value={this.state.isPaid}
                                    color="primary"
                                    inputProps={{
                                      'aria-label': 'secondary checkbox',
                                    }}
                                  />
                                </Grid> */}
                                {this.state.feeSettings?.virtualTerminalSettings?.InvoiceDiscount &&
                                this.state.feeSettings?.virtualTerminalSettings?.DiscountFeeName !== '' &&
                                !this.state.feeSettings?.DualFeeEnabled ? (
                                  <>
                                    <Grid item xs={7}>
                                      {this.state.feeSettings?.virtualTerminalSettings?.DiscountFeeName} (
                                      {this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFeeType === '$'
                                        ? this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFeeType +
                                          '' +
                                          this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFee
                                        : this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFee +
                                          ' ' +
                                          this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFeeType}
                                      )
                                    </Grid>
                                    <Grid item xs={5}>
                                      <TextField
                                        data-cy="svc-fee-textbox"
                                        disabled={!this.props.UserDetail.permissions.feeEdit}
                                        error={this.state.error.svcFeeError}
                                        InputProps={{
                                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        id="svcFee"
                                        type="text"
                                        labels={t('SvcFee')}
                                        placeholder="0.00"
                                        onChange={(e) => this.handleChangeSvcFee(e, t)}
                                        value={this.state.svcFee}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <hr />
                                    </Grid>
                                  </>
                                ) : (
                                  ''
                                )}
                                {this.state.feeSettings?.invoiceSettings?.CustomFeeOneEnabled &&
                                this.state.feeSettings?.invoiceSettings?.CustomFeeOneName !== '' &&
                                this.state.isLateFeeApplicable ? (
                                  <>
                                    <Grid item xs={7}>
                                      {this.state.feeSettings?.invoiceSettings?.CustomFeeOneName} (
                                      {this.state.feeSettings?.invoiceSettings?.CustomFeeOneType === '$'
                                        ? '$' + this.state.feeSettings?.invoiceSettings?.CustomFeeOne
                                        : this.state.feeSettings?.invoiceSettings?.CustomFeeOne +
                                          ' ' +
                                          this.state.feeSettings?.invoiceSettings?.CustomFeeOneType}
                                      )
                                    </Grid>
                                    <Grid item xs={5}>
                                      <TextField
                                        disabled={true}
                                        error={this.state.error.svcFeeError}
                                        InputProps={{
                                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        id="lateFee"
                                        type="text"
                                        labels={t('LateFee')}
                                        placeholder="0.00"
                                        value={this.state.lateFee}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <hr />
                                    </Grid>
                                  </>
                                ) : (
                                  ''
                                )}
                                {this.state.feeSettings?.virtualTerminalSettings?.InvoiceTax ? (
                                  <>
                                    {' '}
                                    <Grid item xs={8} className="taxSelect">
                                      <label>Tax</label>
                                      <select>
                                        {this.state.feeSettings?.virtualTerminalSettings?.InvoiceTax ? (
                                          <option>
                                            Sales Tax{' '}
                                            {this.state.feeSettings?.virtualTerminalSettings?.InvoiceSalesTaxFeeType ===
                                            '$'
                                              ? this.state.feeSettings?.virtualTerminalSettings
                                                  ?.InvoiceSalesTaxFeeType +
                                                '' +
                                                this.state.feeSettings?.virtualTerminalSettings?.InvoiceSalesTaxFee.toFixed(
                                                  2
                                                )
                                              : this.state.feeSettings?.virtualTerminalSettings?.InvoiceSalesTaxFee.toFixed(
                                                  2
                                                ) +
                                                ' ' +
                                                this.state.feeSettings?.virtualTerminalSettings?.InvoiceSalesTaxFeeType}
                                          </option>
                                        ) : (
                                          <option />
                                        )}
                                      </select>
                                    </Grid>
                                    <Grid item xs={4} className="textRight">
                                      {this.state.tax ? formatCurrency(this.state.tax) : '$0.00'}
                                    </Grid>
                                    <Grid item xs={12}>
                                      <hr />
                                    </Grid>
                                  </>
                                ) : (
                                  ''
                                )}

                                {this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurcharge ? (
                                  <>
                                    {' '}
                                    <Grid item xs={8} className="taxSelect">
                                      <label>Surcharge</label>
                                      <select>
                                        {this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurcharge ? (
                                          !this.state.isStateSVCApplied && !this.state.notApplied ? (
                                            <option>
                                              Surcharge Fee{' '}
                                              {this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurchargeFee ===
                                              '$'
                                                ? this.state.feeSettings?.virtualTerminalSettings
                                                    ?.InvoiceSurchargeFeeType +
                                                  '' +
                                                  this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurchargeFee.toFixed(
                                                    2
                                                  )
                                                : this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurchargeFee.toFixed(
                                                    2
                                                  ) +
                                                  ' ' +
                                                  this.state.feeSettings?.virtualTerminalSettings
                                                    ?.InvoiceSurchargeFeeType}
                                            </option>
                                          ) : (
                                            <option>Surcharge Fee {this.state.stateSVCAppliedRate || '0.00'}</option>
                                          )
                                        ) : (
                                          <option />
                                        )}
                                      </select>
                                    </Grid>
                                    <Grid item xs={4} className="textRight">
                                      {this.state.surcharge ? formatCurrency(this.state.surcharge) : '$0.00'}
                                    </Grid>
                                    <Grid item xs={12}>
                                      <hr />
                                    </Grid>
                                  </>
                                ) : (
                                  ''
                                )}

                                {this.state.feeSettings?.DualFeeEnabled ? (
                                  <>
                                    <Grid item xs={7}>
                                      Cash Total
                                    </Grid>
                                    <Grid item xs={5}>
                                      {this.state.cashTotal ? formatCurrency(this.state.cashTotal) : '$0.00'}
                                    </Grid>
                                  </>
                                ) : (
                                  ''
                                )}
                                <Grid item xs={7}>
                                  {this.state.feeSettings?.DualFeeEnabled ? 'Card Total' : t('Total amount')}
                                </Grid>
                                <Grid item xs={5}>
                                  {this.state.total ? formatCurrency(this.state.total) : '$0.00'}
                                </Grid>
                                {this.state?.feeSettings?.virtualTerminalSettings?.InvoiceSurcharge &&
                                  !this.state.creditOrDebit && (
                                    <Link onClick={(e) => this.handleSubmitAddNewCard(e)}>
                                      <p className={classesI.surchargeWarning}>
                                        Card type not found. Please enter or update card to apply surcharge in total
                                      </p>
                                    </Link>
                                  )}
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                md={12}
                xs={12}
                sm={12}
                style={{
                  display: this.state.isMobile ? 'none' : '',
                }}
              >
                {!rowData && (
                  <>
                    <Buttons
                      id="emailInvoice"
                      type="submit"
                      variant="contained"
                      className={'emailInvoiceTerminal email-invoice'}
                      // className={classesI.emailInvoice}
                      onClick={(e) => this.handleSubmitEmailInvoice(e, t)}
                      startIcon={<Icon>send</Icon>}
                      text={t('EmailInvoice')}
                    />
                    {(this.state.userData.permissions.textToInvoice || this.state.userData.permissions.textInvoice) && (
                      <Buttons
                        id="textInvoice"
                        type="submit"
                        variant="contained"
                        className={'emailInvoiceTerminal textInvoiceBtn'}
                        // className={classesI.emailInvoice}
                        onClick={(e) => this.handleSubmitTextInvoice(e, t)}
                        startIcon={<Icon>message</Icon>}
                        text={t('TextInvoice')}
                      />
                    )}
                    <Buttons
                      id="signUp"
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={'emailInvoiceTerminal'}
                      disabled={
                        paymentGateway === 'multipass' && !this.state.cardWalletID && !this.state.tsepFieldSuccess
                      }
                      // className={classesI.submit}
                      onClick={(e) => this.handleSubmit(e, false)}
                      startIcon={<Icon>payment</Icon>}
                      text={t('Payment')}
                    />
                    {this.state.feeSettings.DualFeeEnabled ? (
                      <Buttons
                        id="cashPayment"
                        type="submit"
                        variant="contained"
                        className={'emailInvoiceTerminal'}
                        onClick={(e) => this.handleSubmit(e, true)}
                        text="Cash Payment"
                      />
                    ) : (
                      ''
                    )}
                  </>
                )}

                <Buttons
                  id="signUp"
                  type="submit"
                  variant="contained"
                  className={'emailInvoiceTerminal'}
                  // className={classesI.saveDraft}
                  onClick={(e) => this.handleSubmitSaveDraft(e, t)}
                  startIcon={<SaveIcon />}
                  text={t('SaveDraft')}
                />
                <Buttons
                  className={'emailInvoiceTerminal'}
                  variant="contained"
                  color="secondary"
                  text={t('Back')}
                  onClick={this.props.history.goBack}
                />

                <Buttons
                  variant="contained"
                  color="primary"
                  className={'emailInvoiceTerminal'}
                  onClick={this.handlePrint.bind(this)}
                  text="Print"
                  startIcon={<PrintIcon style={{ color: 'white' }} className={'printInvoice'} />}
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                sm={12}
                style={{
                  display: this.state.isMobile ? '' : 'none',
                }}
              >
                {!rowData && (
                  <>
                    {this.state.feeSettings.DualFeeEnabled ? (
                      <Grid item s={4} m={4} xs={12} lg={4}>
                        <Buttons
                          id="signUp"
                          type="submit"
                          variant="contained"
                          className={classesI.payment_btn}
                          onClick={(e) => this.handleSubmit(e, false)}
                          startIcon={<Icon>payment</Icon>}
                          text={t('Card')}
                        />
                        {this.state.feeSettings.DualFeeEnabled ? (
                          <Buttons
                            id="cashPayment"
                            type="submit"
                            variant="contained"
                            className={classesI.payment_btn}
                            onClick={(e) => this.handleSubmit(e, true)}
                            text="Cash"
                          />
                        ) : (
                          ''
                        )}
                      </Grid>
                    ) : (
                      <Grid item s={4} m={4} xs={12} lg={4}>
                        <Buttons
                          id="signUp"
                          type="submit"
                          variant="contained"
                          className={classesI.onlycard_btn}
                          onClick={(e) => this.handleSubmit(e, false)}
                          startIcon={<Icon>payment</Icon>}
                          text={t('Card')}
                        />
                      </Grid>
                    )}
                    <Grid item s={4} m={4} xs={12} lg={4}>
                      <Buttons
                        id="emailInvoice"
                        type="submit"
                        variant="contained"
                        className={classesI.email_btn}
                        onClick={(e) => this.handleSubmitEmailInvoice(e, t)}
                        startIcon={<Icon>send</Icon>}
                        text={t('EmailInvoice')}
                      />
                      {(this.state.userData.permissions.textToInvoice ||
                        this.state.userData.permissions.textInvoice) && (
                        <Buttons
                          id="textInvoice"
                          type="submit"
                          variant="contained"
                          className={classesI.email_btn}
                          onClick={(e) => this.handleSubmitTextInvoice(e, t)}
                          startIcon={<Icon>message</Icon>}
                          text={t('TextInvoice')}
                        />
                      )}
                    </Grid>
                  </>
                )}
                <Grid item s={4} m={4} xs={12} lg={4}>
                  <Buttons
                    id="signUp"
                    type="submit"
                    variant="contained"
                    className={classesI.draft_print_btn}
                    onClick={(e) => this.handleSubmitSaveDraft(e, t)}
                    startIcon={<SaveIcon />}
                    text={t('SaveDraft')}
                  />

                  <Buttons
                    variant="contained"
                    color="primary"
                    className={classesI.draft_print_btn}
                    onClick={this.handlePrint.bind(this)}
                    text="Print"
                    startIcon={<PrintIcon style={{ color: 'white' }} className={'printInvoice'} />}
                  />
                </Grid>
                <Grid item s={4} m={4} xs={12} lg={4}>
                  <Buttons
                    className={classesI.backbtn}
                    variant="contained"
                    color="secondary"
                    text={t('Back')}
                    onClick={this.props.history.goBack}
                  />
                </Grid>
              </Grid>
            </form>
            <EditInvoiceConfirmationModal
              DualFeeEnabled={this.state.feeSettings.DualFeeEnabled}
              open={this.state.openEditConfirmationModal}
              handleClose={() => this.setState({ openEditConfirmationModal: false })}
              handleSubmit={this.handleSubmit}
              handleSubmitTextInvoice={(e) => this.handleSubmitTextInvoice(e, t)}
              handleSubmitEmailInvoice={(e) => this.handleSubmitEmailInvoice(e, t)}
              isMobile={this.state.isMobile}
              isPaymentButtonDisabled={this.state.isPaymentButtonDisabled}
            />
          </LoadingOverlay>
        </Container>
      </div>
    );
  }
}

const ApplyingStyles = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return <AddInvoice theme={theme} classes={classes} {...props} />;
};

AddInvoice.propTypes = {
  editData: PropTypes.func,
  settingDataByCompanyId: PropTypes.func,
  getTransactionListTodayData: PropTypes.any,
  classes: PropTypes.any,
  history: PropTypes.any,
  addCustomer: PropTypes.func,
  UserDetail: PropTypes.any,
  t: PropTypes.any,
  PAYTRACE_AWS_PATH: PropTypes.any,
  getCsrfToken: PropTypes.func,
  addInvoice: PropTypes.func,
  updatePendingInvoice: PropTypes.func,
  editCustomer: PropTypes.func,
  info: PropTypes.any,
  productList: PropTypes.func,
  getInvoiceNumber: PropTypes.func,
  customerDetailsByCompany: PropTypes.func,
  UserDetailById: PropTypes.func,
  uploadInvoiceFile: PropTypes.func,
  textInvoice: PropTypes.func,
  termsAndCondition: PropTypes.object,
  LocationCurrent: PropTypes.any,
  location: PropTypes.any,
  isMobile: PropTypes.bool,
  setTsepAuth: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    info: state.InvoiceReducer,
    UserDetail: state.AdminUserReducer,
    LocationCurrent: state.LocationReducer,
    PAYTRACE_AWS_PATH: state.EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH,
    termsAndCondition: state.TermsConditionReducer,
  };
}

export default connect(mapStateToProps, {
  settingDataByCompanyId,
  getCsrfToken,
  getTransactionListTodayData,
  productList,
  UserDetailById,
  addInvoice,
  updatePendingInvoice,
  editCustomer,
  customerDetailsByCompany,
  getInvoiceNumber,
  uploadInvoiceFile,
  textInvoice,
  setTsepAuth,
})(withTranslation()(ApplyingStyles));
