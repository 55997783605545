import React, { useState, useEffect } from 'react';
import DropDown from '../DropDown/DropDown';
import AddressFields from '../AddressFields/AddressFields';
import PropTypes from 'prop-types';

function RenderCheckbox({ disabled, sameAsBilling, setSameAsBilling, state }) {
  const { isShippingAddressRequired, zip, addressOne, addressTwo, shippingState, city } =
    state.addressSettings.shippingAddress || {};

  return (
    <div style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
      {(isShippingAddressRequired || zip || addressOne || addressTwo || shippingState || city) && (
        <label id="shipping-checkbox-label">
          <input type="checkbox" checked={sameAsBilling} onChange={() => setSameAsBilling(!sameAsBilling)} />
          <> </>
          Shipping same as Billing Address
        </label>
      )}
    </div>
  );
}
function AddressFieldsDropDown({
  type,
  state,
  handleChange,
  handleSameAddress,
  align = 'column',
  disabled = false,
  disableExpand = false,
}) {
  const [sameAsBilling, setSameAsBilling] = useState(false);

  useEffect(() => {
    handleSameAddress(sameAsBilling);
  }, [sameAsBilling]);

  if (type === 'shipping') {
    return (
      <DropDown title="Shipping Address" defaultExpanded={disableExpand} disableExpand={disableExpand}>
        <div style={{ display: 'flex', flexDirection: align, pointerEvents: disabled ? 'none' : 'auto' }}>
          <AddressFields
            required={state.addressSettings.shippingAddress?.isShippingAddressRequired}
            type="shippingAddress"
            isZip={state.addressSettings.shippingAddress.zip}
            handleChange={(e) => handleChange(e, 'shippingAddress', sameAsBilling)}
            isAddressOne={state.addressSettings.shippingAddress?.addressOne}
            isAddressTwo={state.addressSettings.shippingAddress?.addressTwo}
            isZipRequired={state.addressSettings.shippingAddress?.isZipRequired}
            isState={state.addressSettings.shippingAddress?.state}
            isCity={state.addressSettings.shippingAddress?.city}
            zip={state?.shippingAddress?.zip}
            addressOne={state?.shippingAddress?.addressOne}
            addressTwo={state?.shippingAddress?.addressTwo}
            city={state?.shippingAddress?.city}
            state={state?.shippingAddress?.state}
          />
        </div>
      </DropDown>
    );
  } else if (type === 'billing') {
    return (
      <DropDown title="Billing Address" defaultExpanded={true} disableExpand={disableExpand}>
        <div style={{ display: 'flex', flexDirection: align, pointerEvents: disabled ? 'none' : 'auto' }}>
          <AddressFields
            required={state.addressSettings.billingAddress?.isBillingAddressRequired}
            type="billingAddress"
            isZip={state.addressSettings.billingAddress?.zip}
            handleChange={(e) => handleChange(e, 'billingAddress', sameAsBilling)}
            isAddressOne={state.addressSettings.billingAddress?.addressOne}
            isAddressTwo={state.addressSettings.billingAddress?.addressTwo}
            isState={state.addressSettings.billingAddress?.state}
            isZipRequired={state.addressSettings.billingAddress?.isZipRequired}
            isCity={state.addressSettings.billingAddress?.city}
            zip={state?.billingAddress?.zip}
            addressOne={state?.billingAddress?.addressOne}
            addressTwo={state?.billingAddress?.addressTwo}
            city={state?.billingAddress?.city}
            state={state?.billingAddress?.state}
          />
        </div>
        <RenderCheckbox
          disabled={disabled}
          sameAsBilling={sameAsBilling}
          setSameAsBilling={setSameAsBilling}
          state={state}
        />
      </DropDown>
    );
  } else if (type === 'Self Payment Link') {
    return (
      <>
        <DropDown title="Billing Address" defaultExpanded={true}>
          <div style={{ display: 'flex', flexDirection: align, pointerEvents: disabled ? 'none' : 'auto' }}>
            <AddressFields
              required={state.settings?.invoiceSettings?.billingAddress?.isBillingAddressRequired}
              type="billingAddress"
              isZip={state.settings?.invoiceSettings?.billingAddress?.zip}
              handleChange={(e) => handleChange(e, 'billingAddress', sameAsBilling)}
              isAddressOne={state.settings?.invoiceSettings?.billingAddress?.addressOne}
              isAddressTwo={state.settings?.invoiceSettings?.billingAddress?.addressTwo}
              isState={state.settings?.invoiceSettings?.billingAddress?.state}
              isZipRequired={state?.settings.invoiceSettings?.billingAddress?.isZipRequired}
              isCity={state.settings?.invoiceSettings?.billingAddress?.city}
              zip={state?.billingAddress?.zip}
              addressOne={state?.billingAddress?.addressOne}
              addressTwo={state?.billingAddress?.addressTwo}
              city={state?.billingAddress?.city}
              state={state?.billingAddress?.state}
            />
          </div>
        </DropDown>

        <DropDown title="Shipping Address" defaultExpanded={false}>
          <div style={{ display: 'flex', flexDirection: align, pointerEvents: disabled ? 'none' : 'auto' }}>
            <AddressFields
              required={state.settings?.invoiceSettings?.shippingAddress?.isShippingAddressRequired}
              type="shippingAddress"
              isZip={state.settings?.invoiceSettings?.shippingAddress?.zip}
              handleChange={(e) => handleChange(e, 'shippingAddress', sameAsBilling)}
              isAddressOne={state.settings?.invoiceSettings?.shippingAddress?.addressOne}
              isAddressTwo={state.settings?.invoiceSettings?.shippingAddress?.addressTwo}
              isZipRequired={state.settings?.invoiceSettings?.shippingAddress?.isZipRequired}
              isState={state.settings?.invoiceSettings?.shippingAddress?.state}
              isCity={state.settings?.invoiceSettings?.shippingAddress?.city}
              zip={state?.shippingAddress?.zip}
              addressOne={state?.shippingAddress?.addressOne}
              addressTwo={state?.shippingAddress?.addressTwo}
              city={state?.shippingAddress?.city}
              state={state?.shippingAddress?.state}
            />
          </div>
        </DropDown>
      </>
    );
  }
}
RenderCheckbox.propTypes = {
  disabled: PropTypes.bool,
  sameAsBilling: PropTypes.any,
  setSameAsBilling: PropTypes.any,
  state: PropTypes.any,
};
AddressFieldsDropDown.propTypes = {
  type: PropTypes.any,
  state: PropTypes.any,
  handleChange: PropTypes.any,
  handleSameAddress: PropTypes.func,
  align: PropTypes.any,
  disabled: PropTypes.bool,
  disableExpand: PropTypes.bool,
};

export default AddressFieldsDropDown;
