import { getRoundedExacts } from '../commonFunction';

// refactor the function to subtract svc fee in case of ACH payment type
export function getFeeCalculations(settings, amount, cardType = null, state) {
  const feeSettings = settings?.virtualTerminalSettings;
  const taxEnabled = feeSettings?.InvoiceTax;
  const discountEnabled = feeSettings?.InvoiceDiscount;
  const svcType = feeSettings?.InvoiceSVCFeeType;
  const taxType = feeSettings?.InvoiceSalesTaxFeeType;

  const svcFee = discountEnabled ? feeSettings?.InvoiceSVCFee : 0;
  const salesTaxFee = taxEnabled ? feeSettings?.InvoiceSalesTaxFee : 0;
  let surchargeFee = getSurchargeFee(settings, parseFloat(amount), cardType, state);
  surchargeFee = getRoundedExacts(surchargeFee);
  const { isStateSVCApplied, stateSVCAppliedRate, notApplied } = getSurchargeFeeApplication(
    settings,
    parseFloat(amount),
    cardType,
    state
  );
  const taxAppliedLast = taxEnabled && settings?.applyTaxLast;
  const totalAmount = parseFloat(amount);
  let svcFeeTotal = 0;
  let salesTaxTotal = 0;
  svcFeeTotal = svcType === '%' ? (svcFee / 100) * totalAmount : svcFee;
  salesTaxTotal = getRoundedExacts(taxType === '%' ? (salesTaxFee / 100) * totalAmount : salesTaxFee);
  if (taxAppliedLast) {
    const calculateSalesTax = () => {
      return getRoundedExacts(
        taxType === '%' ? (salesTaxFee / 100) * (totalAmount + svcFeeTotal + surchargeFee) : salesTaxFee
      );
    };
    return {
      totalAmount: totalAmount + svcFeeTotal + surchargeFee + calculateSalesTax(),
      salesTaxFee: calculateSalesTax(),
      svcFee: svcFeeTotal,
      salesTaxTotal,
      surchargeFee,
      isStateSVCApplied,
      stateSVCAppliedRate,
      notApplied,
    };
  } else {
    const calculateSvcFee = () => {
      return getRoundedExacts(svcType === '%' ? (svcFee / 100) * (totalAmount + salesTaxTotal) : svcFee);
    };

    const svcFeeTotal = calculateSvcFee();
    let surchargeFee = getSurchargeFee(settings, parseFloat(amount), cardType, state);
    surchargeFee = getRoundedExacts(surchargeFee);
    const { isStateSVCApplied, stateSVCAppliedRate, notApplied } = getSurchargeFeeApplication(
      settings,
      parseFloat(amount),
      cardType,
      state
    );
    const finalTotal = totalAmount + salesTaxTotal + svcFeeTotal + surchargeFee;
    const roundTotal = getRoundedExacts(finalTotal);
    return {
      totalAmount: roundTotal,
      salesTaxFee: salesTaxTotal,
      svcFee: calculateSvcFee(),
      salesTaxTotal,
      surchargeFee: surchargeFee,
      isStateSVCApplied,
      stateSVCAppliedRate,
      notApplied,
    };
  }
}

function getSurchargeByState(stateCode, surchargeByStateRates, amount) {
  let surchargeFee = 0;
  if (surchargeByStateRates[stateCode].application) {
    surchargeFee =
      surchargeByStateRates[stateCode].type === '%'
        ? (parseFloat(surchargeByStateRates[stateCode].amount) / 100) * amount
        : parseFloat(surchargeByStateRates[stateCode].amount);
  }
  return surchargeFee;
}

function getSurchargeByStateRates(stateCode, surchargeByStateRates) {
  let stateSVCAppliedRate = 0;
  let isStateSVCApplied = false;
  let notApplied = false;
  if (surchargeByStateRates[stateCode].application) {
    isStateSVCApplied = true;
    stateSVCAppliedRate =
      surchargeByStateRates[stateCode].type === '%'
        ? `${surchargeByStateRates[stateCode].amount}%`
        : `${surchargeByStateRates[stateCode].amount}$`;
  } else {
    notApplied = true;
  }
  return { stateSVCAppliedRate, isStateSVCApplied, notApplied };
}

export function getSurchargeFee(settings, amount, cardType, state) {
  let surchargeFee = 0;
  const virtualTerminalSettings = settings?.virtualTerminalSettings;
  const InvoiceSurcharge = virtualTerminalSettings?.InvoiceSurcharge;
  const surchargeByState = virtualTerminalSettings?.surchargeByState;
  const surchargeByStateRates = virtualTerminalSettings?.surchargeByStateRates;
  const InvoiceSurchargeFee = virtualTerminalSettings?.InvoiceSurchargeFee;
  const InvoiceSurchargeFeeType = virtualTerminalSettings?.InvoiceSurchargeFeeType;
  if (!InvoiceSurcharge) {
    return surchargeFee;
  }
  if (cardType && cardType?.toUpperCase() !== 'DEBIT' && cardType?.toUpperCase() !== 'ACH') {
    if (surchargeByState && state) {
      let stateCode = state;
      surchargeFee = getSurchargeByState(stateCode, surchargeByStateRates, amount);
    } else {
      surchargeFee = InvoiceSurchargeFeeType === '%' ? (InvoiceSurchargeFee / 100) * amount : InvoiceSurchargeFee;
    }
  }
  return surchargeFee;
}

export function getSurchargeFeeApplication(settings, amount, cardType, state) {
  const virtualTerminalSettings = settings?.virtualTerminalSettings;
  const surchargeByState = virtualTerminalSettings?.surchargeByState;
  const surchargeByStateRates = virtualTerminalSettings?.surchargeByStateRates;
  if (cardType && cardType?.toUpperCase() !== 'DEBIT') {
    if (surchargeByState && state) {
      const { isStateSVCApplied, stateSVCAppliedRate, notApplied } = getSurchargeByStateRates(
        state,
        surchargeByStateRates
      );
      return { isStateSVCApplied, stateSVCAppliedRate, notApplied };
    } else {
      return { isStateSVCApplied: false, stateSVCAppliedRate: 0.0, notApplied: false };
    }
  } else {
    return { isStateSVCApplied: false, stateSVCAppliedRate: 0.0, notApplied: false };
  }
}

export function getCustomFeeOne(settings, amount) {
  let lateFee = 0;
  if (isNotEmpty(settings?.invoiceSettings)) {
    lateFee =
      settings?.invoiceSettings?.CustomFeeOneType === '$'
        ? settings?.invoiceSettings?.CustomFeeOne
        : (settings?.invoiceSettings?.CustomFeeOne / 100) * amount;
  }
  return lateFee;
}

const isNotEmpty = (obj) => {
  return obj && Object.keys(obj).length !== 0;
};
