import React from 'react';
import { Textfield } from '../../../../components/Textfield';
import Grid from '@material-ui/core/Grid';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  mt_2: {
    marginTop: '20px !important',
  },
});

const CustomerForm = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={3} style={{ padding: '16px' }}>
      <Grid container spacing={3}>
        <Grid item md={6} className="mb-3">
          <Textfield
            id="invoice"
            type="text"
            variant="outlined"
            labels={t('Invoice Number')}
            value={props.invoice}
            required={true}
            width={true}
            autoFocus={true}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item md={6} className={[classes.mt_2]}>
          <CurrencyTextField
            modifyValueOnWheel={false}
            label={t('Amount')}
            variant="outlined"
            value={props.amount}
            width={true}
            currencySymbol="$"
            decimalCharacter="."
            digitGroupSeparator=","
            id="amount"
            textAlign="left"
            onChange={(e, value) => props.handleChange(e, value)}
            required={true}
            style={{ minWidth: '100%' }}
            inputProps={{ style: { paddingLeft: '10px' } }}
          />
        </Grid>
        <Grid item md={12} className="mb-3">
          <Textfield
            error={props.error.firstNameError}
            id="name"
            type="text"
            variant="outlined"
            required={true}
            labels={t('name')}
            width={true}
            value={props.customerData.name}
            autoFocus={true}
            // onChange={props.handleChangeCustomer}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item md={12} className="mb-3">
          <Textfield
            error={props.error.emailError}
            id="email"
            type="text"
            variant="outlined"
            labels={t('Email')}
            width={true}
            value={props.customerData.email}
            autoFocus={true}
            onChange={(e, value) => props.handleChangeCustomerEmail(e, value)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

CustomerForm.propTypes = {
  invoice: PropTypes.any,
  handleChange: PropTypes.func,
  amount: PropTypes.any,
  error: PropTypes.shape({
    firstNameError: PropTypes.any,
    emailError: PropTypes.any,
  }),
  customerData: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  handleChangeCustomerEmail: PropTypes.func,
};

export default CustomerForm;
