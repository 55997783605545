import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Textfield } from './../../components/Textfield';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';

class CallerInfo extends Component {
  render() {
    const { t, onChange, error } = this.props;
    const customInvoicePageFields = this.props?.fields;

    return (
      <div>
        <strong> Caller Info. </strong>
        <FormControl fullWidth>
          <Grid container align="center">
            <Grid item xs={1} sm={2} md={2} />
            <Grid item xs={10} sm={8} md={8}>
              <Grid container align="center">
                {customInvoicePageFields &&
                  customInvoicePageFields.slice(0, 3).map((field, index) =>
                    field.enabled ? (
                      <Grid key={`field${index + 1}`} item xs={12} sm={4} md={4}>
                        <FormControl>
                          <Textfield
                            id={`field${index + 1}`}
                            type="text"
                            labels={t(field.value)}
                            required={field.required}
                            error={error[`field${index + 1}Error`]}
                            value={this.props.input[`field${index + 1}`].value}
                            width={true}
                            onChange={(e) => onChange(e, field.value)}
                            focus={true}
                          />
                        </FormControl>
                      </Grid>
                    ) : (
                      ''
                    )
                  )}
              </Grid>
            </Grid>
            <Grid item xs={1} sm={2} md={2} />
          </Grid>
        </FormControl>
      </div>
    );
  }
}

CallerInfo.propTypes = {
  t: PropTypes.func,
  onChange: PropTypes.func,
  input: PropTypes.object,
  fields: PropTypes.any,
  error: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    input: state.InputReducer,
  };
}

export default connect(mapStateToProps)(withTranslation()(CallerInfo));
