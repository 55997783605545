export const checkAddressEnabled = (fields) => {
  let isEnabled = false;
  const entries = Object.entries(fields ?? {});
  for (let [key, value] of entries) {
    if (key !== 'isShippingAddressRequired' || key !== 'isBillingAddressRequired') {
      if (value) {
        isEnabled = true;
      }
    }
  }
  return isEnabled;
};

export const validateCityInAddress = (address) => {
  const specialCharRegex = /[^a-zA-Z0-9\s]/;

  if (!specialCharRegex.test(address?.city)) {
    return address;
  }
  address.city = '';
  return address;
};
