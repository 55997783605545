import React from 'react';
import Button from '@material-ui/core/Button';
import { config } from '../constants';
import PropTypes from 'prop-types';

export const UserCardForm = ({ merchantRef, date, hash }) => (
  <form action={config.TEST_SECURED_CARD} method="post">
    <input type="hidden" name="ACTION" value="register" />
    <input type="hidden" name="TERMINALID" value={config.TERMINALID} />
    <input type="hidden" name="MERCHANTREF" value={merchantRef} />
    <input type="hidden" name="DATETIME" value={date} />
    <input type="hidden" name="HASH" value={hash} />
    <Button type="submit" variant="contained" color="primary">
      Secure Card
    </Button>
  </form>
);

// Types are unknown at time of this addition. Adding to satisfy linting rules during security updates.
UserCardForm.propTypes = {
  merchantRef: PropTypes.any,
  date: PropTypes.any,
  hash: PropTypes.any,
};
