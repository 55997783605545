import { formatCurrency } from './commonFunction';

const PrintInvoice = (data) => {
  const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const date = data && data.dateOpened ? new Date(data.dateOpened).toISOString() : null;
  const paymentDate = date ? new Date(data.timestamp_PAID).toISOString() : null;
  const formattedDate = date ? new Date(date).toLocaleString('en-US', options) : 'N/A';
  const formattedPaymentDate = paymentDate ? new Date(paymentDate).toLocaleString('en-US', options) : 'N/A';
  const my_window = window.open('', 'mywindow', 'status=1,width=650,height=450');
  my_window.document.write(`<html><head><title>Print Invoice</title></head>`);
  my_window.document.write(`<body onafterprint="self.close()">`);
  my_window.document.write(
    `<div style="text-align: center"> <img alt="logo" src="${data.companyLogoUrl}" width="100" style="padding: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; margin: 5px auto; display: block; width: 10%;" /></div>`
  );
  data.name &&
    my_window.document.write(
      `<p style="padding: 0; margin: 0; box-sizing: border-box; font-family: sans-serif; font-size: 18px;">Hi <strong style="padding: 0; margin: 0; box-sizing: border-box; font-family: sans-serif; font-size: 18px;">${data.name}</strong>,</p>`
    );

  my_window.document.write(
    `<h3 style="padding: 0; box-sizing: border-box; font-family: sans-serif; text-align: center; margin: 25px 0; font-size: 25px; font-weight: 700; color: rgba(53, 94, 170, 1);">Receipt</h3>`
  );
  my_window.document
    .write(`<table style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; width: 100%; border-collapse: collapse;" width="100%">
     
    ${
      data.invoiceOrJobId
        ? ` <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family:sans-serif;">${
                 data.customInvoiceName || 'Invoice'
               } </strong></td>
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${
                 data.invoiceOrJobId
               }</td>
            </td>
           </tr>`
        : ``
    }

    ${
      data?.transactionId
        ? ` <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family:sans-serif;">Transaction ID </strong></td>
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.transactionId}</td>
            </td>
           </tr>`
        : ``
    }

    ${
      data?.customerName
        ? ` <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family:sans-serif;">Customer Name </strong></td>
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.customerName}</td>
            </td>
           </tr>`
        : ``
    }

    ${
      data?.paymentType
        ? ` <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family:sans-serif;">Payment Type </strong></td>
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.paymentType}</td>
            </td>
           </tr>`
        : ``
    }

    ${
      formattedDate
        ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
            <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Date</strong></td>
            <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formattedDate}</td>
           </tr>`
        : ``
    }

    ${
      data.isCardNumberAvailable
        ? ` <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family:sans-serif;">Card Number </strong></td>
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.maskedCardNumber}</td>
            </td>
           </tr>`
        : ``
    }

    ${
      data.isCardTypeAvailable
        ? ` <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family:sans-serif;">Card Type </strong></td>
               <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.cardType}</td>
            </td>
           </tr>`
        : ``
    }

  ${
    data.isSurchargeApplied
      ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">${
        data.surchargeFieldName
      }</strong></td>
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(
        data.surchargeAmount
      )}</td>
  </tr>`
      : ``
  }

  ${
    data.tax
      ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Tax</strong></td>
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formatCurrency(
        data.tax
      )}</td>
  </tr>`
      : ``
  }     
    
    ${
      data.STATUS
        ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Payment Status</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${data.STATUS}</td>
    </tr>`
        : ``
    }

  ${
    data.timestamp_PAID
      ? `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Payment Date</strong></td>
      <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: right; padding: 8px 0;">${formattedPaymentDate}</td>
  </tr>`
      : ``
  }
</table>


${
  data.isProductsAvailable
    ? `
<h3>Products</h3>
<table style="padding: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; margin: 30px 0 30px; width: 100%; border-collapse: collapse; border-top: 2px solid rgba(0, 0, 0, 0.87); border-bottom: 2px solid rgba(0, 0, 0, 0.87);" width="100%">
<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <th style="margin: 0; font-size: 14px; box-sizing: border-box;  font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Name</strong></th>
        <th style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Quantity</strong></th>
        <th style="margin: 0; font-size: 14px; box-sizing: border-box; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Description</strong></th>
        <th style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">Amount</strong></th>
</tr>
${data.Products.map((product) => {
  return `<tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">${
          product.name
        }</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">${
          product.quantity
        }</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">${
          product.description
        }</strong></td>
        <td style="margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; text-align: left; padding: 8px 0;"><strong style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">${formatCurrency(
          product.unitCost
        )}</strong></td>
      </tr>`;
})}
</table>`
    : ''
}

${
  !data.isDualFeeEnabled
    ? `<table style="padding: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; margin: 30px 0 30px; width: 100%; border-collapse: collapse; border-top: 2px solid rgba(0, 0, 0, 0.87); border-bottom: 2px solid rgba(0, 0, 0, 0.87);" width="100%">
      <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
          <td style="margin: 0; font-family: sans-serif; text-align: left; box-sizing: border-box; padding: 10px 0; font-size: 16px; font-weight: bold;">Total amount</td>
          <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: right; padding: 10px 0; font-size: 16px; font-weight: bold;">${formatCurrency(
            data.total
          )}</td>
        </td>
      </tr> 
    </table>`
    : ``
}

${
  data?.isDualFeeEnabled
    ? `<table style="padding: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; margin: 30px 0 30px; width: 100%; border-collapse: collapse; border-top: 2px solid rgba(0, 0, 0, 0.87); border-bottom: 2px solid rgba(0, 0, 0, 0.87);" width="100%">
      <tr style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;">
          <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: left; padding: 10px 0; font-size: 16px; font-weight: bold;">Card Total</td>
          <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: right; padding: 10px 0; font-size: 16px; font-weight: bold;">${formatCurrency(
            data.total
          )}</td>
        </td>
      </tr> 
    </table>`
    : ``
}


 <table style="padding: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; margin: 30px 0 30px; width: 100%; border-collapse: collapse; border-top: 2px solid rgba(0, 0, 0, 0.87); border-bottom: 2px solid rgba(0, 0, 0, 0.87);" width="100%">
  ${
    data.publicNotes
      ? `<tr>
  <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: left; padding: 10px 0; font-size: 16px; font-weight: bold;">Public Notes</td>
  <td style="margin: 0; box-sizing: border-box; font-family: sans-serif; text-align: right; padding: 10px 0; font-size: 16px; font-weight: bold;">${data.publicNotes}</td>
  </tr>`
      : ``
  }

  </table>
  <p style="padding: 0; margin: 0; box-sizing: border-box; font-family: sans-serif; font-size: 16px; font-weight: 600; margin-bottom: 10px; text-align: center;">Thank you for your business. </p>
  <p style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; font-weight: 600; margin-bottom: 5px; margin-top: 30px;">Regards,</p>
  <p style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif; font-weight: 600; margin-bottom: 5px; margin-top:5px;">${
    data?.locationId?.locationName
  }</p>

 
   
  
  <p style="padding: 0; margin: 0; box-sizing: border-box; font-size: 14px; font-family: sans-serif;"></p>
  
  `);

  my_window.document.write(`</body></html>`);
  my_window.print();
};

export default PrintInvoice;
