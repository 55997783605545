import React, { Component } from 'react';
import { getRoadsideTotalAmount, getSVCFee } from '../../helpers/svcFeeAmount';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import { TextfieldWithLable } from './../../components/TextfieldWithLable';
import { DialogBox } from './../../components/DialogBox/DialogBox';
import classes from './RequestForm.module.scss';
import LoadingOverlay from 'react-loading-overlay';
import classNames from 'classnames';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { connect } from 'react-redux';

import {
  setInputErrorState,
  fetchDetails,
  setInputState,
  reset,
  invoiceGenerate,
  emailInvoice,
  saveFormData,
} from './../../actions/RequestFormAction';
import { CallerInfo } from '../CallerInfo';
import { VehicleInfo } from '../VehicleInfo';
import { ServiceInfo } from '../ServiceInfo';
import { PickupDropLocation } from '../PickupDropLocation';
import { AutoSuggestInput } from '../AutoSuggestInput';
import { Topheader } from './../../components/Topheader';
import validator from '../../helpers/checkValidations';
import { PaymentInfoComponent } from './Components/PaymentInfo';

const exceptionsValidation = [
  'zipForCalc',
  'error',
  'success',
  'distanceDetails',
  'suggestionsData',
  'touched',
  'notes',
  'endAddress',
  'pickUpNotes',
  'problemType',
  'open',
  'userAccount',
  'basePrice',
  'system',
];

class RequestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      responseError: false,
      misMatchError: [],
      paytraceCredentials: {},
      successMessages: false,
      redirectTransaction: false,
      saveDraft: false,
      locationCurrent: null,
      error: {
        passwordError: false,
        userNameError: false,
      },
      locationId: '',
      locationImage: JSON.parse(localStorage.getItem('locationPaydetails'))?.image,
    };
  }

  handleClickOpen = () => {
    this.props.setInputState('open', true);
    this.props.setInputState('success', false);
  };

  handleClose = () => {
    this.props.setInputState('open', false);
    this.redirect();
  };
  UNSAFE_componentWillMount() {
    //redirect to intake form for road assitence client
    let currentLocation = JSON.parse(localStorage.getItem('locationPaydetails'));

    if (currentLocation?.customDefaultBuild) {
      this.props.history.push('/AddInvoices');
    }
  }

  componentDidMount() {
    let misMatchErrors = [];
    let currentLocation = JSON.parse(localStorage.getItem('locationPaydetails'));
    let user = JSON.parse(localStorage.getItem('user'));
    if (currentLocation) {
      setTimeout(() => {
        const paytraceCredentials = {
          password: currentLocation?.paytracePassword,
          userName: currentLocation?.paytraceUserName,
          paytraceKey: currentLocation?.paytraceKey,
        };
        this.setState({
          locationId: currentLocation._id,
          paytraceCredentials: paytraceCredentials,
          locationCurrent: currentLocation,
        });
      }, 3000);
    } else {
      misMatchErrors.push('Select the payment location.');
      this.setState({ misMatchError: misMatchErrors });
    }

    this.props.invoiceGenerate();
    this.props.reset();

    if (user.token) {
      this.props.setInputState('userAccount', user.email);
      this.props.setInputState('companyId', user.company._id);
      this.props.setInputState('createdBy', user._id);
    }

    if (this.props.UserDetail?.settings?.virtualTerminalSettings) {
      const InvoiceDiscount = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceDiscount ?? 0;
      this.props.setInputState('InvoiceDiscount', InvoiceDiscount);
      const InvoiceSVCFee = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSVCFee ?? 0;
      this.props.setInputState('settingsInvoiceFee', InvoiceSVCFee);
      const InvoiceSVCFeeType = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSVCFeeType ?? '';
      this.props.setInputState('InvoiceSVCFeeType', InvoiceSVCFeeType);
      const DiscountFeeName = this.props.UserDetail?.settings?.virtualTerminalSettings.DiscountFeeName ?? '';
      this.props.setInputState('DiscountFeeName', DiscountFeeName);

      this.props.setInputState('InvoiceSVCFee', '');
    } else {
      this.props.history.push('/Invoices');
    }
  }
  setMethod(event) {
    this.props.setInputState(event.target.name, event.target.value);
  }

  handleChangeName(event) {
    this.setState({ [event.target.name]: event.target.value, responseError: false });
  }
  textHandler(event, key = null) {
    //    const inputDemo= {invoice: "", firstName: "", lastName: "", phoneNumber: "", year: ""};
    const { setInputState, input, setInputErrorState } = this.props;
    let stop = null;
    let pause = null;
    let value = event.target.value.toString();
    let id = event.target.id;
    switch (event.target.id) {
      case 'email':
        if (validator(id, value)) {
          stop = true;
        } else {
          stop = false;
        }
        break;
      case 'phoneNumber':
      case 'phonenumber':
      case 'phone':
        if (validator(id, value)) {
          stop = true;
        } else {
          stop = false;
        }
        break;
      // case 'phoneNumber':
      //   if (value.length > 10 || isNaN(value)) {
      //     pause = true;
      //   } break;
      case 'zip':
        if (value.length > 5) {
          stop = true;
        }
        break;
      case 'year':
        if (validator(id, value)) {
          stop = true;
        } else {
          stop = false;
        }
        break;
      case 'amount':
        if (validator(id, value)) {
          stop = true;
        } else {
          stop = false;
        }
        break;

      default:
        stop = false;
    }

    if (!event.target.id) {
      setInputState('amount', event.target.value);
    } else {
      if (pause) {
        setInputState(event.target.id, input[event.target.id]);
      } else {
        if (stop) {
          setInputErrorState(id, value);
        } else {
          const { InvoiceDiscount } = this.props.UserDetail.settings;
          if (id === 'amount' && InvoiceDiscount) {
            const { InvoiceSVCFee, InvoiceSVCFeeType } = this.props.UserDetail.settings;
            const svcFee = getSVCFee(InvoiceSVCFeeType, InvoiceSVCFee, parseFloat(value));
            setInputState(event.target.id, value, key);
            setInputState('InvoiceSVCFee', svcFee);
          } else {
            setInputState(event.target.id, value, key);
          }
        }
      }
    }
  }

  displayErrorFields(state) {
    Object.keys(state).forEach((key) => {
      if (exceptionsValidation.indexOf(key) >= 0) {
        return;
      } else if (!state[key].length > 0) {
        this.props.setInputErrorState(key, '');
      }
    });
  }

  dynamicFieldValidation(serviceType, doAllWheelsTurn) {
    const newExceptions = [];
    switch (serviceType) {
      case 'Fuel/Fluids':
        newExceptions.push('neutral', 'doAllWheelsTurn', 'doesFrontWheelsTurn', 'doesBackWheelsTurn');
        break;
      case 'Towing':
        newExceptions.push('regularGasOrDiesel');
        if (doAllWheelsTurn === 'YES') {
          newExceptions.push('doesFrontWheelsTurn', 'doesBackWheelsTurn');
        }
        break;
      default:
        newExceptions.push(
          'regularGasOrDiesel',
          'neutral',
          'doAllWheelsTurn',
          'doesFrontWheelsTurn',
          'doesBackWheelsTurn'
        );
        break;
    }

    exceptionsValidation.push(...newExceptions);
  }

  validate(state) {
    let valid = true;
    let error = {};
    //  eslint-disable-next-line no-unused-vars
    let errorFlag = {};
    const { locationCurrent } = this.state;
    const customInvoicePageFields = locationCurrent?.customInvoicePageFields;
    let dynamiclist = [];

    for (let i = 0; i < customInvoicePageFields?.length; i++) {
      if (customInvoicePageFields[i]?.required && customInvoicePageFields[i]?.enabled) {
        let index = i + 1;
        dynamiclist.push('field' + index);
      }
    }
    const staticlist = ['amount'];
    this.dynamicFieldValidation(this.props.input.serviceType, this.props.input.doAllWheelsTurn);

    Object.keys(state).forEach((keyValue) => {
      let key;
      let value;
      if (dynamiclist && dynamiclist?.includes(keyValue)) {
        key = state[keyValue]?.key?.replace(/\s/g, '').toLowerCase();
        value = state[keyValue]?.value;
      } else if (staticlist.includes(keyValue)) {
        key = keyValue;
        value = state[keyValue];
      }

      if (validator(key, value)) {
        return validator(key, value)
          ? ((error[keyValue + 'Error'] = true), (errorFlag = true))
          : (error[keyValue + 'Error'] = false);
      } else {
        return value === '' || (typeof value === 'string' && value?.trim() === '')
          ? ((error[keyValue + 'Error'] = true), (errorFlag = true))
          : (error[keyValue + 'Error'] = false);
      }
    });
    state = { ...state, error: error };
    this.setState({ error: error });
    Object.keys(state.error).forEach((key) => {
      if (state.error[key]) {
        valid = false;
      }
    });
    return valid;
  }
  updateStateAfterSave(result) {
    let misMatchError = [];
    let successMessages = false;
    if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
      this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {});
      this.handleClickOpen();
      setInputState('setInputState', false);
    }
    if (result !== undefined && result.type === 'INVOICE_ERROR') {
      misMatchError.push(this.props.info.invoiceInfo.data.message);
      this.setState({ successMessages, isLoading: false, misMatchError }, () => {
        this.props.reset();
      });
    }
  }

  saveAsDraft(state, InvoiceSalesTaxFee, InvoiceSalesTaxFeeType, InvoiceDiscount, InvoiceTax) {
    if (this.validate(this.props.input)) {
      const cashTotal = getRoadsideTotalAmount(
        false,
        InvoiceTax,
        undefined, // InvoiceSVCFeeType is not used when InvoiceDiscount is false
        undefined, // InvoiceSVCFee is not used when InvoiceDiscount is false
        InvoiceSalesTaxFee,
        InvoiceSalesTaxFeeType,
        state.amount
      );

      this.setState({ isLoading: true });
      this.props
        .saveFormData({
          ...state,
          saveAsDraft: true,
          tax: parseFloat(getSVCFee(InvoiceSalesTaxFeeType, InvoiceSalesTaxFee, state.amount)),
          ...(this.isDualPricingEnabled() ? { cashTotal } : {}),
          InvoiceDiscount: InvoiceDiscount,
          InvoiceTax: InvoiceTax,
          InvoiceSalesTaxFee,
          InvoiceSalesTaxFeeType,
          isDualPricingEnabled: this.isDualPricingEnabled(),
        })
        .then((result) => {
          window.scrollTo(0, 0);
          this.updateStateAfterSave(result);
        });
    }
  }
  sendLinkToPhone(state, InvoiceSalesTaxFee, InvoiceSalesTaxFeeType, InvoiceDiscount, InvoiceTax) {
    if (this.validate(this.props.input)) {
      const cashTotal = getRoadsideTotalAmount(
        false,
        InvoiceTax,
        undefined, // InvoiceSVCFeeType is not used when InvoiceDiscount is false
        undefined, // InvoiceSVCFee is not used when InvoiceDiscount is false
        InvoiceSalesTaxFee,
        InvoiceSalesTaxFeeType,
        state.amount
      );

      this.setState({ isLoading: true });
      window.scrollTo(0, 0);

      let user = JSON.parse(localStorage.getItem('user'));
      let locPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));

      const paytraceCredentials = {
        userName: user.company.paytraceUserName,
        password: user.company.paytracePassword,
        integratorId: user.company.paytraceIntegratorId,
      };
      this.setState({
        paytraceCredentials: paytraceCredentials,
        locationId: locPaydetails?._id,
      });

      this.props
        .saveFormData({
          ...state,
          type: 'emailLink',
          saveAsDraft: false,
          tax: parseFloat(getSVCFee(InvoiceSalesTaxFeeType, InvoiceSalesTaxFee, state.amount)),
          ...(this.isDualPricingEnabled() ? { cashTotal } : {}),
          InvoiceDiscount: InvoiceDiscount,
          InvoiceTax: InvoiceTax,
          InvoiceSalesTaxFee,
          InvoiceSalesTaxFeeType,
          isDualPricingEnabled: this.isDualPricingEnabled(),
        })
        .then((result) => this.updateStateAfterSave(result));
    }
  }
  redirect() {
    if (this.props.input.invoice) {
      this.props.reset();
      let Id = this.props.info.invoiceInfo.data.response.id;
      this.props.history.push('/InvoiceEdit/' + Id);
    }
  }

  isDualPricingEnabled() {
    return this.props.UserDetail?.settings?.DualFeeEnabled;
  }

  render() {
    const {
      invoice,
      email,
      success,
      notes,
      sendTo,
      open,
      regularGasOrDiesel,
      willAnyoneBeWithTheVehicle,
      serviceType,
    } = this.props.input;

    let InvoiceDiscount = 0;
    let InvoiceSVCFee = 0;
    let InvoiceSVCFeeType = '';
    let InvoiceTax = '';
    let InvoiceSalesTaxFee = 0;
    let InvoiceSalesTaxFeeType = '';
    let DiscountFeeName = '';

    if (this.props.UserDetail?.settings?.virtualTerminalSettings) {
      InvoiceDiscount = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceDiscount ?? 0;
      InvoiceSVCFee = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSVCFee ?? 0;
      InvoiceSVCFeeType = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSVCFeeType ?? '';
      DiscountFeeName = this.props.UserDetail?.settings?.virtualTerminalSettings.DiscountFeeName ?? '';
      InvoiceTax = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceTax ?? '';
      InvoiceSalesTaxFee = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSalesTaxFee ?? 0;
      InvoiceSalesTaxFeeType = this.props.UserDetail?.settings?.virtualTerminalSettings.InvoiceSalesTaxFeeType ?? '';
    } else {
      this.props.history.push('/Invoices');
    }
    const { t, setInputState, reset } = this.props;
    const { redirectTransaction, locationCurrent, error, locationImage } = this.state;
    //  let user = JSON.parse(localStorage.getItem('user'));
    const customInvoicePageFields = locationCurrent?.customInvoicePageFields;
    if (success) {
      this.handleClickOpen();
      setInputState('setInputState', false);
    }
    const image =
      locationImage && locationImage !== 'null'
        ? `${this.props.PAYTRACE_AWS_PATH}${locationImage}`
        : '/assets/images/RAL-Logo.png';
    return (
      <div className="rightPanel">
        <Topheader />
        {redirectTransaction ? <Redirect to="/Invoices" /> : ''}

        <div className={classNames([classes.Appheader, classes.requestformpage])}>
          <LoadingOverlay
            className={classes.requestformpageLoading}
            active={this.state.isLoading}
            spinner
            text="Fetching your response ..."
          >
            <div className={classes.intakePageHeading}>
              <img src={image} style={{ maxWidth: '95%' }} height={'100rem'} alt="logo" /> <br />
              <h2> Roadside Assistance</h2>
            </div>
            <br />

            <FormControl>
              <Grid container align="center" className={classes.grid}>
                <Grid item xs={6} sm={6} md={6}>
                  <a href="/Transactions" style={{ textDecoration: 'none' }}>
                    <Button
                      className={classes.adminBtn}
                      color={'secondary'}
                      style={{ color: '#FFF', width: '10rem' }}
                      variant="contained"
                    >
                      Admin Page
                    </Button>
                  </a>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <a href="https://Refundlink.roadsideassistanceltd.com" style={{ textDecoration: 'none' }}>
                    <Button
                      className={classes.refundBtn}
                      color={'secondary'}
                      style={{ color: '#FFF', width: '10rem' }}
                      variant="contained"
                    >
                      Refund Request
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </FormControl>
            {this.state.misMatchError.map((e, i) => (
              <ErrorMessage errors={[e]} key={i} />
            ))}
            {this.state.successMessages ? (
              <SuccessMessage successes={[this.props.info.invoiceInfo.data.message]} />
            ) : (
              ''
            )}
            <DialogBox
              open={open}
              handleClose={this.handleClose.bind(this)}
              message={this.state.successMessages ? this.props.info.invoiceInfo.data.message : ''}
            />
            <Paper className={classes.containerControl} id={'paper'}>
              <form autoComplete={'none'}>
                <TextfieldWithLable
                  required={true}
                  error={this.state.error.userNameError}
                  id="invoice"
                  type="text"
                  labels={t('Invoice')}
                  value={invoice}
                  width={true}
                  onChange={(e) => this.textHandler(e)}
                  disabled={true}
                />
                <CallerInfo
                  onChange={(evt, key) => this.textHandler(evt, key)}
                  fields={customInvoicePageFields}
                  error={error}
                />{' '}
                <hr />
                <VehicleInfo
                  onChange={(evt, key) => this.textHandler(evt, key)}
                  onChangeDropDown={(event, key) => setInputState(event.target.name, event.target.value, key)}
                  fields={customInvoicePageFields}
                  error={error}
                />
                <hr />
                <ServiceInfo onChange={(event) => setInputState(event.target.name, event.target.value)} /> <hr />
                <PickupDropLocation
                  onChangeDropDown={(event) => setInputState(event.target.name, event.target.value)}
                  onChangeTextHandler={(evt) => this.textHandler(evt)}
                />
                <Grid container align="center">
                  {/* <Grid item xs={2} sm={2} md={2}></Grid> */}
                  <Grid item xs={12} sm={12} md={12}>
                    <AutoSuggestInput />
                  </Grid>
                </Grid>
                <strong> Payment Info. </strong>
                <FormControl style={{ width: '100%' }}>
                  <TextfieldWithLable
                    id="email"
                    type="email"
                    labels={t('Email')}
                    required={false}
                    //error={error['emailError']}
                    value={email}
                    width={true}
                    onChange={(evt) => this.textHandler(evt)}
                  />
                </FormControl>
                <br style={{ lineHeight: '5.5rem' }} />
                <PaymentInfoComponent
                  amount={this.props.input.amount}
                  amountInputHandler={this.textHandler.bind(this)}
                  translator={t}
                  invoiceDiscount={Boolean(InvoiceDiscount)}
                  invoiceTax={Boolean(InvoiceTax)}
                  invoiceSalesTaxFee={InvoiceSalesTaxFee}
                  invoiceSalesTaxFeeType={InvoiceSalesTaxFeeType}
                  invoiceSVCFee={InvoiceSVCFee}
                  invoiceSVCFeeType={InvoiceSVCFeeType}
                  discountFeeName={DiscountFeeName}
                  isDualPricingEnabled={this.isDualPricingEnabled()}
                />
                <TextfieldWithLable
                  id="notes"
                  type="text"
                  labels={t('Notes')}
                  value={notes}
                  width={true}
                  onChange={(evt) => this.textHandler(evt)}
                  rows={4}
                  multiline={true}
                />
                <RadioGroup
                  aria-label="Options"
                  name="gender2"
                  value={sendTo}
                  style={styles.radioGroupStyle}
                  onChange={(event) => setInputState('sendTo', event.target.value)}
                >
                  <p style={styles.textPStyle}>
                    {' '}
                    <i> How would you like to send the payment link? </i>{' '}
                  </p>

                  <FormControlLabel
                    value="email"
                    control={<Radio color="primary" />}
                    label="Email"
                    labelPlacement="start"
                  />
                  {((customInvoicePageFields &&
                    customInvoicePageFields.length > 0 &&
                    customInvoicePageFields[2]?.value.toLowerCase() === 'phone') ||
                    (customInvoicePageFields &&
                      customInvoicePageFields.length > 0 &&
                      customInvoicePageFields[2]?.value.toLowerCase() === 'phonenumber')) &&
                  customInvoicePageFields &&
                  customInvoicePageFields.length > 0 &&
                  customInvoicePageFields[2]?.enabled ? (
                    <>
                      <FormControlLabel
                        value="phone"
                        control={<Radio color="primary" />}
                        label="Phone"
                        labelPlacement="start"
                      />
                    </>
                  ) : (
                    ''
                  )}
                </RadioGroup>
                <Grid container align="center">
                  <Grid item xs={12} sm={4}>
                    <Button
                      className={classes.saveforLetterBtn}
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        if (this.validate(this.props.input)) {
                          this.saveAsDraft(
                            this.props.input,
                            InvoiceSalesTaxFee,
                            InvoiceSalesTaxFeeType,
                            InvoiceDiscount,
                            InvoiceTax
                          );
                        } else {
                          // this.displayErrorFields(this.props.input);
                          alert('Please complete the required fields of the form!');
                        }
                      }}
                    >
                      {' '}
                      Save for Later{' '}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      className={classes.sendPayment}
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        if (this.validate(this.props.input)) {
                          this.sendLinkToPhone(
                            this.props.input,
                            InvoiceSalesTaxFee,
                            InvoiceSalesTaxFeeType,
                            InvoiceDiscount,
                            InvoiceTax
                          );
                          if (document.getElementById('origin')) document.getElementById('origin').value = '';
                          if (document.getElementById('destination')) document.getElementById('destination').value = '';
                          reset();
                        } else {
                          // this.displayErrorFields(this.props.input);
                          alert('Please complete the required fields of the form!');
                        }
                      }}
                      disabled={
                        willAnyoneBeWithTheVehicle === 'NO' ||
                        (serviceType === 'Fuel/Fluids' && regularGasOrDiesel === 'DIESEL GAS')
                      }
                      color="primary"
                    >
                      Send Payment Link
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={async () => {
                        if (document.getElementById('origin')) document.getElementById('origin').value = '';
                        if (document.getElementById('destination')) document.getElementById('destination').value = '';
                        reset();
                        setTimeout(() => reset(), 10);
                      }}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}
const styles = {
  radioGroupStyle: {
    margin: '30px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stateStyle: { width: '62%', margin: '0.5rem', marginTop: '1rem' },
  textPStyle: { fontSize: '19px', color: '#000', marginRight: '50px' },
  zipStyle: { width: '62%', marginLeft: '1rem' },
};
RequestForm.propTypes = {
  t: PropTypes.func,
  setInputState: PropTypes.func,
  history: PropTypes.any,
  PAYTRACE_AWS_PATH: PropTypes.any,
  invoiceGenerate: PropTypes.func,
  reset: PropTypes.func,
  input: PropTypes.any,
  setInputErrorState: PropTypes.func,
  touched: PropTypes.any,
  saveFormData: PropTypes.func,
  info: PropTypes.any,
  UserDetail: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    input: state.InputReducer,
    info: state.InvoiceReducer,
    UserDetail: state.AdminUserReducer,
    PAYTRACE_AWS_PATH: state.EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH,
  };
}

export default connect(mapStateToProps, {
  setInputErrorState,
  saveFormData,
  fetchDetails,
  emailInvoice,
  invoiceGenerate,
  setInputState,
  reset,
})(withTranslation()(RequestForm));
