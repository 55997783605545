import getTsepDeviceIdsAndUrls from '../constants/keys';

function getTsepUrl() {
  let tsepUrls = getTsepDeviceIdsAndUrls();
  if (window.location.hostname === 'new.mytitaniumflex.com') {
    return tsepUrls.TSEP_URL_PROD;
  } else {
    return tsepUrls.TSEP_URL_DEV;
  }
}
console.log('Evar', process.env);
const buildNumber = process.env.REACT_APP_APP_VERSION
  ? `V.${process.env.REACT_APP_APP_VERSION}${
      process.env.BITBUCKET_BUILD_NUMBER ? `.${process.env.BITBUCKET_BUILD_NUMBER}` : ''
    }`
  : 'V.1.9.0';

export default {
  VERSION: buildNumber,
  // For local dev use http://localhost:3000/api/
  BASEURL: process.env.REACT_APP_BASEURL || '/api/',
  APPURL: process.env.REACT_APP_APPURL || '',
  IMGURL: process.env.REACT_APP_IMGURL || '/uploads/',
  PDFURL: process.env.REACT_APP_PDFURL || '/views/',
  APIKEY: process.env.REACT_APP_MAP_API_KEY,
  OnTimeCompanyId: process.env.OnTimeCompanyId,
  ACTIVATION_LINK: 4320,
  PAYMENT_LINK: 5,
  UPLOAD_IMAGE_SIZE: 5 * 1048576,
  MANAGER_ROLE: 3,
  ADMIN_ROLE: 2,
  SUPER_ADMIN_ROLE: 1,
  SUB_ADMIN_ROLE: 0,
  REGULAR_USER_ROLE: 4,
  CUSTOMER_USER_ROLE: 5,
  TERMINALID: 2994035,
  PAYTRACE_KEY: 'liberty.pem',
  TEST_PAYMENTPAGE: 'https://testpayments.anywherecommerce.com/merchant/paymentpage',
  TEST_SECURED_CARD: 'https://testpayments.anywherecommerce.com/merchant/securecardpage',
  SUBSCRIPTION_SECURED_CARD: 'https://testpayments.anywherecommerce.com/merchant/subscriptionpage/register',
  BIN_CHECKER_URL: 'https://api.bincodes.com/bin/',
  TSEP_MANIFEST_VALIDATION_URL: getTsepUrl(),
  BIN_API_KEY: 'f409f72efed25ba7b4c1b6d0d750e793', // pragma: allowlist secret
  PAYTRACE_AWS_PATH: 'https://paytrace-key-staging.s3.us-east-2.amazonaws.com/',
  RATE_LIMITER_MESSAGES: 'Refresh limit exceeded. Please wait for 1 minute before syncing the Database',
  COMPANY_ID_4840: '650b7f1660368040608cdec7',
  CUSTOMER_PLACE_HOLDER_LOCATIONS_IDS: ['655d4147b6af700d0fc1a3d7', '66ce433307ba633d825b6293'],
};
