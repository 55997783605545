import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;
  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

renderInput.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
};

function renderSuggestion(suggestionProps) {
  const { locationData, itemProps, selectedItem } = suggestionProps;
  const isSelected = (selectedItem || '').indexOf(locationData.locationName + '( ' + locationData.midType + ' )') > -1;
  return (
    <MenuItem
      {...itemProps}
      key={locationData.locationName}
      selected={true}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {locationData.locationName}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

function getSuggestions(value, data, focusStatus = false) {
  const inputValue = deburr(value.trim().toLowerCase());
  const isInputEmpty = inputValue.length === 0;
  const filteredData = data
    ? data.filter((suggestion) => suggestion.locationName.toLowerCase().startsWith(inputValue))
    : '';

  if (isInputEmpty) {
    return focusStatus ? data : [];
  } else {
    return filteredData.slice(0, 5);
  }
}

function DownshiftMultiple(props) {
  const { classes, isLocationExists, locationData, selectedLocations } = props;
  const [inputValue, setInputValue] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState([]);
  const [focusStatus, setFocusStatus] = React.useState(false);
  const inputRef = React.useRef(null);

  useEffect(() => {
    const init = isLocationExists && selectedLocations ? selectedLocations : [];
    setSelectedItem(init);
  }, [selectedLocations]);

  function handleKeyDown(event) {
    if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
    }
  }

  function handleFocusEvent(data) {
    setFocusStatus(data);
  }

  function handleInputChange(event) {
    setFocusStatus(false);
    setInputValue(event.target.value);
  }

  function handleChange(item) {
    if (item) {
      let newSelectedItem = [...selectedItem];

      // If "All Locations" is selected, set newSelectedItem to all locations except already selected ones and "All Locations"
      if (item.locationName === 'All Locations') {
        newSelectedItem = locationData.filter(
          (location) =>
            !selectedItem.some((selected) => {
              return selected._id_mid ? selected._id_mid === location._id_mid : selected._id === location._id;
            })
        );
      } else {
        // Add the selected item to newSelectedItem if not already selected
        const isItemSelected = selectedItem.some((s) => {
          return s._id_mid ? s._id_mid === item._id_mid : s._id === item._id;
        });
        if (!isItemSelected) {
          newSelectedItem = [...newSelectedItem, item];
        }
      }

      setInputValue('');
      if (props.addLocation) {
        props.addLocation(newSelectedItem.map((m) => m._id));
      }
      if (props.addLocation2) {
        props.addLocation2(newSelectedItem.map((m) => m));
      }
      setSelectedItem(newSelectedItem);
      setFocusStatus(false);
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  }
  const handleDelete = (item) => () => {
    const newSelectedItem = [...selectedItem];
    let indexofArray = newSelectedItem.filter((i) => {
      return i._id_mid ? i._id_mid === item._id_mid : i._id === item._id;
    });
    const indexOfArrayInFilter = newSelectedItem.findIndex((array) => {
      return (
        array._id_mid === indexofArray[0]._id_mid &&
        array._id === indexofArray[0]._id &&
        array.midType === indexofArray[0].midType
      );
    });
    newSelectedItem.splice(indexOfArrayInFilter, 1);
    if (props.addLocation) {
      props.addLocation(newSelectedItem.map((m) => m._id));
    }
    if (props.addLocation2) {
      props.addLocation2(newSelectedItem.map((m) => m));
    }
    setSelectedItem(newSelectedItem);
  };
  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={selectedItem.length > 0 ? selectedItem : []}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        _isOpen,
        inputValue: inputValue2,
        selectedItem,
        highlightedIndex,
      }) => {
        const { onChange, ...inputProps } = getInputProps({
          onKeyDown: handleKeyDown,
          placeholder: 'Select multiple locations',
          ref: inputRef,
        });

        return (
          <div className={classes.container}>
            <h4>Location</h4>
            {renderInput({
              fullWidth: true,
              classes,
              // label: 'Location',
              InputLabelProps: getLabelProps(),
              InputProps: {
                startAdornment: selectedItem.map((item) => (
                  <Chip
                    key={item._id}
                    tabIndex={-1}
                    label={item.locationName}
                    className={classes.chip}
                    onDelete={handleDelete(item)}
                  />
                )),
                onBlur: (_event) => {
                  setFocusStatus(false);
                },
                onChange: (event) => {
                  handleInputChange(event);
                  onChange(event);
                },
                onFocus: (event) => {
                  handleFocusEvent(true);
                  onChange(event);
                },
              },
              inputProps,
            })}
            {
              <Paper className={classes.paper} square>
                {' '}
                {getSuggestions(inputValue2, locationData, focusStatus).map((locationData, index) =>
                  renderSuggestion({
                    locationData,
                    index,
                    itemProps: getItemProps({ item: locationData }),
                    highlightedIndex,
                    selectedItem,
                  })
                )}
              </Paper>
            }
          </div>
        );
      }}
    </Downshift>
  );
}

DownshiftMultiple.propTypes = {
  classes: PropTypes.object.isRequired,
  isLocationExists: PropTypes.any,
  locationData: PropTypes.any,
  selectedLocations: PropTypes.any,
  addLocation: PropTypes.any,
  addLocation2: PropTypes.any,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 0,
    marginTop: 8,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    overflow: 'auto !important',
    maxHeight: '250px !important',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
    minHeight: '27px',
  },
  divider: {
    height: theme.spacing(2),
  },
}));

export default function IntegrationDownshift(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <DownshiftMultiple
        classes={classes}
        selectedLocations={props.selectedLocations}
        isLocationExists={props.isLocationExists}
        addLocation={props.addLocation}
        addLocation2={props.addLocation2}
        locationData={props.locationData}
      />
    </div>
  );
}

IntegrationDownshift.propTypes = {
  selectedLocations: PropTypes.any,
  isLocationExists: PropTypes.any,
  addLocation: PropTypes.func,
  addLocation2: PropTypes.func,
  locationData: PropTypes.any,
};
